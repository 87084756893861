@import "../../../../../scss/variables";
.basicOveride {
  .basicinfoWrap {
    background: $white-color;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    padding: 12px 25px 16px 25px;
    margin-bottom: 40px;
    .basicinfoBox {
      display: flex;
      justify-content: space-between;
      @media (max-width: 767px) {
        flex-direction: column;
        flex-wrap: wrap;
      }
      .basicinfoleft {
        &.width-100 {
          width: 100%;
        }
        &.sw {
          width: 85%;
        }
        width: 70%;
        text-align: left;
        @media (max-width: 767px) {
          width: 100%;
        }
        .title {
          font-size: $font-size-20;
          color: $cerulean-color;
          font-weight: 700;
          font-family: $font-lato;
          margin-bottom: 23px;
        }
        .listView {
          font-family: $font-lato;
          font-weight: 700;
          font-size: $font-size-16;
          line-height: 19px;
          color: $text-black-color;
          border-bottom: 1px solid $chinese-white;
          margin-bottom: 13px;
          padding-bottom: 10px;
          div[style] {
            width: auto !important;
            position: relative !important;
          }
          &.desNone {
            margin-bottom: 0px;
            padding-bottom: 0px;
            border: 0px;
            &.borderAdd {
              border-bottom: 1px solid $chinese-white;
              margin-bottom: 13px;
              padding-bottom: 10px;
            }
          }
          span {
            text-transform: capitalize;
            padding-left: 4px;
            font-family: $font-lato;
            font-weight: 400;
            font-size: $font-size-16;
            line-height: 19px;
            color: $text-black-color;
            &.focusedText {
              font-family: $font-lato;
              font-weight: 700;
              font-size: $font-size-16;
              line-height: 19px;
              color: $cerulean-color;
            }
          }
        }
        .datetime {
          &.dynmicFeild {
            flex-wrap: wrap;

            .commonColumnNew {
              .dynmicHeading{
                text-transform: capitalize;
              }
              width: 50%;
              display: flex;
              justify-content: flex-start;
              align-items: flex-start;
              border-bottom: 1px solid $chinese-white;
              color: #333;
              font-family: $font-lato;
              font-size: $font-size-16;
              font-weight: 700;
              line-height: 19px;
              margin-bottom: 13px;
              padding-bottom: 10px;
              span{
                font-weight: 500;
                font-size: $font-size-16;
                padding-left: 10px;
                color: $light-primary-text-color;
                p{
                  color: $light-primary-text-color;
                }
              }
              &.basicContainerNew {
                width: 100%;
                flex-wrap: wrap;
                span{
                  padding-left: 0px;
                }
                .w-100{
                  width: 100%;
                }
              }
            }
          }
          display: flex;
          width: 100%;
          .font-w400 {
            font-weight: 400;
          }
          .listView {
            width: 50%;
            @media (max-width: 767px) {
              width: 100%;
            }
          }
        }
      }
      .basicinfoRight {
        &.sw {
          width: 15%;
        }
        width: 30%;
        display: flex;
        justify-content: flex-end;
        @media (max-width: 767px) {
          width: 100%;
        }
        .basicInformationImgBox {
          border: 1px solid #999999;
          box-shadow: 0px 3px 90px rgba(0, 0, 0, 0.1);
          border-radius: 10px;
          width: 154px;
          height: 154px;
          .basicInformationImg {
            img {
              width: 131px;
              height: 122px;
            }
          }
          .basiimgname {
            text-align: center;
            font-size: $font-size-14;
            color: $shadow-grey;
            font-weight: 700;
            font-family: $font-lato;
          }
        }
      }
    }
  }
}
body {
  &.dark-mode {
    .basicOveride {
      .basicinfoWrap {
        background: $dark-secondary-background-color;
        .basicinfoBox {
          .basicinfoleft {
            .title {
              color: $dark-primary-color;
            }
            .listView {
              color: $dark-primary-text-color;

              span {
                color: $dark-primary-text-color;
                &.focusedText {
                  color: $dark-primary-color;
                }
              }
            }
            .datetime {
              &.dynmicFeild {
                .commonColumnNew {
                  color: $dark-primary-text-color;
                  span {
                    color: $dark-primary-text-color;
                    p {
                      color: $dark-primary-text-color;
                    }
                  }
                }
              }
            }
          }
          .basicinfoRight {
            .basicInformationImgBox {
              .basiimgname {
                color: $dark-primary-text-color;
              }
            }
          }
        }
      }
    }
  }
}
