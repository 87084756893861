@import "../../../scss//variables";

body {
  &.dark-mode {
    .overRideCardbox {
      .header {
        .content {
          h1 {
            color: $dark-primary-text-color;
          }
          p {
            color: $dark-primary-text-color;
          }
        }
      }
    }
  }
}

.overRideCardbox {
  width: 100%;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: space-between;
    width: 95%;
    .content {
      // display: flex;
      // flex-direction: column;
      // align-items: flex-start;
      margin-left: 18px;
      h1 {
        font-weight: 600;
        font-size: $font-size-22;
        color: $text-black-color;
      }
      p {
        font-weight: 600;
        font-size: $font-size-18;
        color: $shadow-grey;
        text-align: left;
      }
    }
  }
}
