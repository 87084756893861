@import "../../../../../scss/variables";

.swAddReport {
  .prevOpenItemContainer {
    background-color: $white-color;
    padding: 10px 20px;
    margin: 0px 0px;
    margin-bottom: 20px;
    border-radius: 5px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    .prvTitle {
      color: $cerulean-color;
      font-size: $font-size-16;
      font-weight: 600;
      font-family: $font-lato;
      margin-bottom: 10px;
    }
  }
  .prevBox {
    border: 1px solid $gainsboro;
    .prevBoxHeader {
      background-color: $gainsboro;
      max-height: 52px;
      padding: 17px 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .item {
        width: 33.33%;
        color: $text-black-color;
        font-size: $font-size-14;
        font-family: $font-lato;
        font-weight: 600;
        text-transform: uppercase;
      }
    }
    .prevBoxBody {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      .prevBoxBodyList {
        .prevBoxBodyListTop {
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-wrap: wrap;
          padding: 17px 20px;
          border: 1px solid $gainsboro;
          &:last-child {
            border-bottom: none;
          }
          .prevBoxBodyListHeader {
            border: 1px solid $gainsboro;
          }
          .bodyListItem {
            width: 33.33%;
            color: $text-black-color;
            font-size: $font-size-14;
            font-family: $font-lato;
            font-weight: 400;
            align-items: center;
            justify-content: flex-start;
            display: flex;
            flex-wrap: wrap;
            .itemDetailshow {
              margin-left: 5px;
              color: $text-black-color;
              font-size: $font-size-14;
              font-family: $font-lato;
              font-weight: 400;
              padding-right: 10px;
            }
            .comment {
              cursor: pointer;
            }
            .locationImg {
              img {
                width: 86px;
                height: 36px;
                object-fit: cover;
                margin-left: 5px;
              }
            }
            &.right {
              justify-content: flex-end;
              .checkcircleclosed {
                margin-left: 15px;
                border-radius: 100px;
                padding: 10px 8px 10px 8px;
                color: $white-color;
                font-size: $font-size-14;
                font-weight: 600;
                font-family: $font-lato;
                width: 120px;
                display: flex;
                align-items: center;
                justify-content: center;
                &.closed {
                  background-color: $green-color;
                }
                &.open {
                  background-color: $rosemadder;
                  cursor: pointer;
                }
              }
            }
          }
        }
        .commintingBox,
        .closeItemBox {
          width: 100%;
          margin-top: 15px;
          padding: 17px 20px;
        }
        .allCommentBox {
          width: 100%;
          margin-top: 15px;
          .commentBody {
            .commentBodyList {
              display: flex;
              justify-content: space-between;
              align-items: flex-start;
              margin-top: 10px;
              .date {
                color: $text-black-color;
                font-size: $font-size-14;
                font-family: $font-lato;
                font-weight: 700;
              }
              .commentBodyListItem {
                &.left {
                  width: 70%;
                  padding-right: 40px;
                }
                &.right {
                  width: 30%;
                }
              }
              .paragraph {
                color: $text-black-color;
                font-size: $font-size-14;
                font-family: $font-lato;
                font-weight: 400;
                span {
                  font-weight: 600;
                  color: $text-black-color;
                  font-size: $font-size-14;
                  font-family: $font-lato;
                }
              }
            }
          }
          .commentHeader {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .title {
              font-size: $font-size-14;
              font-weight: 700;
              font-family: $font-lato;
              color: $text-black-color;
              &.left {
                width: 70%;
                padding-right: 40px;
              }
              &.right {
                width: 30%;
              }
            }
          }
        }
      }
    }
  }
}
