@import "../../../scss/variables";

body {
  &.dark-mode {
    .overideProperty {
      .title {
        color: $dark-primary-text-color;
      }
    }
  }
}

.overideProperty {
  .propertyBox {
    border: 1px solid #dfdfdf;
    padding: 20px;
    max-width: 720px;
    width: 100%;
    border-radius: 5px;
  }
  .title {
    text-align: left;
    margin-bottom: 20px;
    color: #333333;
    font-size: 18px;
    font-family: Lato;
    font-style: normal;
    font-weight: 700;
  }
}
