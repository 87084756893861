@import "../../../scss/variables";

.viewOverideContact {
  .commonOveride {
    margin-top: 0px;
  }
}
.image {
  display: flex;
  gap: 20px;
}
.credentialName {
  color: $cerulean-color;
  font-weight: bold;
  margin-top: 8px;
  height: 40px;
  display: block;
  width: auto;
  padding-top: 10px;
  padding-left: 7px;
  padding-right: 7px;
  display: inline-block;
  border-radius: 12px;
  justify-content: center;
}

.errorMsg {
  color: #f21d1d;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
  margin-top: 3px;
  margin-right: 14px;
}

.credentialFieldStyle {
  border: 1px solid;
  color: #666666;
  font-family: Lato;
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  text-align: left;
  margin-top: 3px;
  height: 43px;
  display: block;
  width: auto;
  padding-top: 11px;
  padding-left: 15px;
  padding-right: 7px;
  border-radius: 15px;
  justify-content: center;
}

.credType {
  font-weight: bold;
  margin-left: 8px;
  color: $cerulean-color;
}
.clientswitch .MuiFormControlLabel-label {
  margin-left: 15px;
  font-family: $font-lato;
}

.clientswitch .MuiFormLabel-colorPrimary {
  font-family: $font-lato;
}
.addShadowBox {
  .clientswitch {
    .clientStatus {
      margin-left: -4px;
      font-weight: 600;
      padding-bottom: 6px,
    }
  }
}
