.downLoadTemplate{
    background-color: #34A853;
    color: #fff;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 212px;
    margin-bottom: 21px;
    border-radius: 41px;
    gap: 12px;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 10px;
}
.DownloadText{
    width: 100%;
    display: flex;
    justify-content: flex-end;
}
.downLoadTemplate:hover{
    color: #fff;
}
.btnMt{
    margin-top: 30px;
    display: flex;
    justify-content: end;
    .btnstyles{
        width: 200px;
    }
}