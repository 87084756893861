.back-button {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-family: "lato";
  text-decoration: none;
  padding-top: 20px;
  padding-bottom: 20px;
  .arrow-icon {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }
  .back-text {
    font-family: "lato";
    font-weight: 600;
    font-size: 20px;
    color: #666666;
  }
}
