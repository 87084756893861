@import "../../../scss/variables";

body {
  &.dark-mode {
    .overrideFaqPage {
      .icon {
        color: $dark-primary-color;
      }
      .faqMainContainer {
        .rightDiv {
          background-color: $dark-primary-background-color;
        }
      }

      .paginationText {
        color: $dark-primary-text-color;
      }
    }
  }
}

.overrideFaqPage {
  background: $white-color;
  .faqMainContainer {
    display: flex;
    justify-content: center;
    width: 100%;
    // height: 100vh;
    .sideBar {
      height: 100vh;
      background: $white-color;
    }
    .rightDiv {
      width: 100%;
    }
  }
  .faqcontainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 20px;
  }
  .header {
    font-family: $font-lato;
    font-size: $font-size-34;
    color: $text-black-color;
    width: 55%;
  }
  .searchBar {
    width: 45%;
    margin: 15px;
    font-family: $font-lato;
  }
  .cardsContainer {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: space-around;
    flex-wrap: wrap;
    margin: 10px 20px;
  }
  .textButton {
    font-size: $font-size-40;
    color: $shadow-grey;
    font-weight: 400;
  }
  .paginationText {
    font-family: $font-lato;
    font-weight: 400;
    font-size: $font-size-16;
    color: $text-black-color;
  }
  .secondaryHeader {
    font-family: $font-lato;
    font-size: $font-size-26;
    color: $text-black-color;
  }
  .tertiaryHeader {
    display: flex;
    flex-wrap: wrap;
    width: 50%;
    margin-top: 15px;
  }
  .icon {
    width: 60px;
    height: 60px;
    color: $cerulean-color;
  }
}
