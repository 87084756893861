@import "../../../../scss/variables";

.viewOverideContact {
  .editResourceTitle {
    font-size: $font-size-22;
    color: $text-black-color;
    font-weight: 700;
    font-family: $font-lato;
    margin-bottom: 50px;
  }
  .titleGuid {
    font-size: $font-size-20;
    color: $cerulean-color;
    font-weight: 700;
    font-family: $font-lato;
    margin-bottom: 15px;
  }
  .titlePre {
    font-size: $font-size-16;
    color: $text-black-color;
    font-weight: 700;
    font-family: $font-lato;
    margin-bottom: 20px;
  }
  .viewBoxWrap {
    .viewBox {
      display: flex;
      border-bottom: 1px solid $chinese-white;
      padding-bottom: 10px;
      margin-bottom: 10px;

      .viewBoxItem {
        font-size: $font-size-16;
        color: $text-black-color;
        font-weight: 700;
        font-family: $font-lato;
        .downloadImg {
          cursor: pointer;
        }
        &.version {
          color: $shadow-grey;
          font-weight: 400;
          padding-left: 5px;
          width: 202px;
          &:last-child {
            color: $shadow-grey;
          }
        }
        &:last-child {
          color: $cerulean-color;
          font-weight: 400;
          padding-left: 10px;
        }
      }
      .viewBoxItem1 {
        font-size: $font-size-16;
        color: $cerulean-color;
        font-weight: 400;
        font-family: $font-lato;
      }
    }
  }
  .versionBtn {
    .overideBtn {
      .addIconBtnsblue {
        color: $white-color;
      }
      .outlinedButton {
        background-color: $cerulean-color;
        color: $white-color;
        &.Mui-disabled {
          color: rgba(0, 0, 0, 0.26);
          box-shadow: none;
          background-color: rgba(0, 0, 0, 0.12);
          border: none;
        }
      }
    }
  }
  .font-w4001 {
    display: flex;
  }
}

body {
  &.dark-mode {
    .editResourceTitle {
      color: $white-color;
    }
    .titlePre {
      color: $white-color;
    }
    .viewBoxWrap {
      .viewBox {
        .viewBoxItem {
          color: $white-color;
          &:last-child {
            color: $dark-primary-color;
            font-weight: 400;
            padding-left: 10px;
          }
        }
      }
    }

    .viewBoxWrap {
      .viewBox {
        .viewBoxItem.version {
          color: $white-color;
        }
      }
    }
    .viewOverideContact {
      .viewBoxWrap {
        .viewBox {
          .viewBoxItem1 {
            color: $dark-primary-color;
          }
        }
      }
    }
    .viewBoxWrap {
      .viewBox {
        .viewBoxItem.downloadImg {
          color: $dark-primary-color;
          svg {
            path {
              fill: $dark-secondary-color;
            }
          }
        }
      }
    }
    .titleGuid {
      color: $dark-primary-color;
    }
  }
}
