@import "../../../../scss/variables";

body {
  &.dark-mode {
    .overideCreateTicket {
      .formContainer {
        .title {
          color: $dark-primary-text-color;
        }
        .ticketBoxContainer {
          .ticketBox {
            background-color: $dark-primary-background-color;
          }
          .categoryDropdownContainer {
            .tooltip {
              background: $dark-secondary-background-color;
              box-shadow: 0px 4px 100px rgba(0, 0, 0, 0.07);
              
              .tooltipHeader {
                color: $dark-primary-text-color;
              }
              .tooltipHeaderMain {
                color: $dark-primary-text-color;
              }
            }
          }
        }
      }
    }
  }
}

.overideCreateTicket {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  .formContainer {
    display: flex;
    width: 95%;
    flex-direction: column;
    font-family: $font-lato;

    .title {
      text-align: left;
      font-weight: 700;
      font-size: $font-size-22;
      color: $text-black-color;
    }
    .ticketBoxContainer {
      display: flex;
      width: 100%;
      justify-content: "center";
      // align-items: center;

      .ticketBox {
        padding: 20px;
        background: $white-color;
        box-shadow: 0px 4px 100px rgba(0, 0, 0, 0.07);
        border-radius: 8px;
        margin: 20px 20px 20px 0;
        min-width: 720px;
        .submitBtnDiv {
          width: 100%;
          display: flex;
          justify-content: right;
          align-items: left;
          .errorSubBtn {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
          }
          .errorSpan {
            color: $red-color;
            font-family: $font-lato;
          }
        }
      }
      .categoryDropdownContainer {
        display: flex;
        flex-direction: column;
        .tooltip {
          display: flex;
          justify-content: space-around;
          flex-direction: column;
          text-align: left;
          background: $white-color;
          box-shadow: 0px 4px 100px rgba(0, 0, 0, 0.07);
          padding: 10px;
          align-items: flex-start;
          margin-top: 20px;
          line-height: 30px;
          .tooltipHeader {
            font-weight: 600;
            font-size: $font-size-14;
            color: $shadow-grey;
            margin-top: 10px;
          }
          .tooltipHeaderMain {
            font-weight: 600;
            font-size: $font-size-16;
            color: $text-black-color;
          }
        }
      }
    }
  }
}
