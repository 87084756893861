@import "../../scss/variables";

body {
  &.dark-mode {
    .nameBoxpara {
      color: $dark-primary-text-color;
    }
  }
}

.nameBox {
  display: flex;
  align-items: center;
  .nameBoxpara {
    font-family: $font-lato;
    font-weight: 600;
    font-size: $font-size-16;
    color: $text-black-color;
  }
}
