@import "../../scss/variables";

.footer {
  position: relative;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  overflow: hidden;
  z-index: 1;
  background-color: $white-color;

  @media screen and (max-width: 767px) {
    visibility: hidden;
  }

  &.fixed {
    position: fixed;
    display: block;
  }

  &-contents {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;

    &-left {
      display: flex;
      justify-content: flex-start;
      width: 100%;
      align-items: center;

      h1 {
        font-family: $font-lato;
        font-style: normal;
        font-weight: 400;
        font-size: $font-size-14;
        line-height: 17px;
        color: $shadow-grey;
      }

      &-icons {
        margin: 0 50px;

        img {
          margin: 0 5px;
          cursor: pointer;
        }
      }
    }

    &-right {
      display: flex;
      justify-content: flex-end;
      .toggle-switch {
        font-family: $font-lato;
        font-style: normal;
        font-weight: 400;
        font-size: $font-size-16;
        line-height: 19px;
        display: flex;
        align-items: center;
        background-color: $white-color;
      }

      .switch input {
        display: none;
      }

      .switch {
        display: inline-block;
        width: 50px;
        height: 27px;
        margin: 0 16px;
        position: relative;
      }

      .slider {
        position: absolute;
        top: 0;
        bottom: 0;
        left: -4px;
        right: 0;
        border-radius: 30px;
        cursor: pointer;
        border: 4px solid transparent;
        overflow: hidden;
        transition: 0.4s;
        border: 3px solid $black-color;
      }

      .slider:before {
        position: absolute;
        content: "";
        width: 17px;
        height: 17px;
        background: $black-color;
        border-radius: 30px;
        transform: rotate(180deg);
        transition: 0.4s;
        left: 2px;
        top: 2px;
      }

      input:checked + .slider:before {
        transform: translateX(25px);
        background-color: $white-color;
      }

      input:checked + .slider {
        border: 3px solid $white-color;
      }
    }
  }
  &.dark-footer {
    background-color: $dark-tertiary-background-color;
    h1 {
      color: $light-primary-background-color;
    }
  }
}
