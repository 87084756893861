@import "../../../scss/variables";

body {
  &.dark-mode {
    .editCaseDisbled {
      .overideTimePicker{
        .MuiInputBase-colorPrimary.Mui-disabled {
          input{
            background-color: $dark-secondary-background-color;
          }
        }
      }
    }
    .caseSummaryModal {
      .modalContainer {
        .modalBox {
          .modalBody {
            .modalBodyList {
              .modalBodyHeader{
                .modalBodyVersion{
                  color: $dark-primary-color;
                }
                .modalBodyDate{
                  color: $light-primary-background-color;
                }
              }
              .modalBodyBody {
                .caseBodyTitle {
                  color: $light-primary-background-color;
                }
                .caseBodyDescription {
                  p{
                    color: $light-primary-background-color;
                  }
                }
              }
            }
          }
          .modalHeader {
            .modalHeaderClose {
              color: $light-primary-background-color;
            }
            .modalHeaderTitle {
              color: $light-primary-background-color;
            }
          }
        }
      }
    }
    .viewCasess {
      .blueCardBody {
        .defaultCard {
          background-color: $dark-primary-text-color;
          color: $dark-primary-color;
        }
      }
    }
    .jodit-workplace {
      strong {
        color: $dark-primary-text-color;
      }
    }
    .caseDiv {
      .detaildata {
        .caseofsummryBox {
          .caseofsummryFlex {
            .caseofsummryText {
              background-color: $light-primary-background-color;
              color: $dark-primary-color;
            }
          }
        }
      }
    }
    .setting_button_ui {
      background: $dark-secondary-background-color;
      ul {
        li {
          span {
            color: $dark-primary-text-color;
          }
          svg {
            color: $dark-secondary-color;
          }
        }
      }
      .download_button {
        span {
          color: $dark-primary-text-color;
        }
      }
    }
    .dropdownList_ui {
      .deleteIcon {
        svg {
          width: 25px;
          height: 20px;
          path {
            fill: $dark-secondary-color;
          }
        }
      }
      .closeIncident {
        svg {
          width: 25px;
          height: 20px;
          path {
            fill: $dark-secondary-color;
          }
        }
      }
      .cloneIcon {
        svg {
          path {
            fill: $dark-secondary-color;
          }
        }
      }
    }
    .caseDiv {
      background-color: $dark-secondary-background-color;
      .caseidinc {
        color: $dark-primary-color;
      }
      .editBlueCase {
        color: $dark-primary-color;
        svg {
          fill: $dark-primary-color;
          path {
            fill: $dark-primary-color;
          }
        }
      }

      .detaildata {
        .content_grid_ui {
          div {
            color: $dark-primary-text-color;
            .location {
              .locationvalue {
                color: $dark-primary-text-color;
              }
            }
          }
          .preparedvalue {
            color: $dark-primary-text-color;
          }
        }
      }
      .caseofsummry {
        color: $dark-primary-text-color;
      }
      .caseofsummryvalue {
        p {
          color: $dark-primary-text-color;
        }
      }
    }
  }
}

.caseDiv {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  // width: 530px;
  background: $white-color;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  padding: 24px;
  justify-content: space-around;
  margin: 0px;
  .caseidinc {
    color: $cerulean-color;
    font-size: $font-size-20;
    font-weight: 700;
  }
  .detaildata {
    .content_grid_ui {
      display: flex;
      flex-wrap: wrap;
      margin: 0px -10px;
      div {
        display: inline-block;
        max-width: 50%;
        flex: 0 0 50%;
        flex-wrap: wrap;
        text-align: left;
        padding: 0px 5px;
        margin-top: 7px;
        .location {
          display: flex;
          max-width: 100%;
          .locationTitle {
            max-width: 100px;
          }
          .locationvalue {
            color: #333333;
            font-weight: 400;
            flex-wrap: wrap;
            // text-align: le;
            max-width: 80%;
          }
        }
        div:nth-child(1) {
          font-weight: 700;
        }
      }
      .full_width {
        width: 100%;
        max-width: 100%;
        flex: 100%;
      }
    }
    .summer_of_case {
      text-align: left;
      margin-top: 14px;
      div:nth-child(1) {
        font-weight: 700;
      }
    }
  }
}

.viewinctitle {
  margin-top: 10px;
  position: absolute;
  width: 300px;
  height: 37px;
  left: 235px;
  top: 360px;

  font-family: $font-lato;
  font-style: normal;
  font-weight: 700;
  font-size: $font-size-24;
  line-height: 29px;
  color: $black-color;
}
.container {
  padding-left: 50px;
}
.App {
  .dashboard {
    overflow-y: hidden;
  }
}

.dropdownList_ui {
  .deleteIcon {
    svg {
      width: 25px;
      height: 20px;
    }
  }
  .closeIncident {
    svg {
      width: 25px;
      height: 20px;
    }
  }
  .cloneIcon {
    svg {
      width: 25px;
      height: 20px;
    }
  }
}
// setting style
.setting_button_ui {
  padding: 10px 15px;
  width: 250px;
  .result_perpage_setting {
    max-height: 120px;
    overflow: auto;
    margin-bottom: 5px;
    display: none;
  }
  .MuiFormLabel-root {
    text-transform: capitalize;
    color: $black-color;
  }
  .MuiFormGroup-root.css-dmmspl-MuiFormGroup-root {
    padding: 5px 5px 15px 0px;
    label {
      padding: 2px 0px;
      margin: 0px;
      span {
        padding: 0px;
        color: $black-color;
        text-decoration: none;
        svg {
          color: $cerulean-color;
        }
      }
      span.MuiTypography-body1 {
        padding: 0px 10px;
      }
    }
  }
  .download_button {
    display: flex;
    span {
      padding: 0px 5px;
      color: $black-color;
      text-decoration: none;
    }
  }
  .dropdownList_ui {
    padding: 0px;
    margin: 0px;
  }
  ul {
    padding: 0px;
    margin: 0px;
    li {
      padding: 7px 0px;
      cursor: pointer;
      // line-height: 40px;
      align-items: center;
      display: flex;
      justify-content: flex-start;
      .deleteImg {
        width: 25px;
        height: 20px;
      }
      .closeIncident {
        width: 20px;
        height: 20px;
        margin: 0px 2px;
      }
      svg {
        width: 25px;
        height: 25px;
        position: relative;
        color: $cerulean-color;
      }
      span {
        display: inline-block;
        padding-left: 5px;
        color: $black-color;
        text-decoration: none;
      }
    }
    li:first-child {
      padding-top: 0px;
    }
    li:last-child {
      padding-bottom: 0px;
    }
    li:hover > span {
      color: $cerulean-color;
    }
  }
}

.setting_button_ui:hover {
  cursor: pointer;
}

// alert box
.alertBox_bottom {
  width: calc(100% - 320px);
  background-color: $cerulean-color;
  padding: 15px 40px;
  position: fixed;
  bottom: 0px;
  right: 0px;
  z-index: 9;
  .row_alert_box {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .report_slected_box {
      span {
        color: $white-color;
        display: inline-block;
        // font-weight: 600;
        padding-left: 15px;
        text-decoration: none;
        cursor: auto;
        svg {
          color: $white-color;
          text-decoration: none;
        }
      }
      span:first-child {
        position: relative;
        top: 5px;
        padding-left: 0px;
      }
    }
  }
}
.caseDiv {
  .detaildata {
    .caseofsummryBox {
      margin-top: 20px;
      .caseofsummryFlex {
        display: flex;
        padding-left: 10px;
        font-weight: 500;
        margin-top: 10px;
        flex-wrap: wrap;
        .caseofsummryText {
          background: $cerulean-color;
          border: 1px solid $fossil-grey;
          border-radius: 100px;
          padding: 11px 14px;
          margin-right: 10px;
          margin-bottom: 10px;
          color: $white-color;
          font-weight: 600;
        }
      }
    }
  }
}
.addCaseListBtn {
  display: flex;
}

.downloadresourse {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.editBlueCase {
  cursor: pointer;
  margin-left: 10px;
}

.btncaseofsummry {
  margin-top: 20px;
  margin-bottom: 10px;
  .overideBtn {
    .outlinedButton {
      max-width: 210px;
      padding: 10px 5px;
    }
  }
}
.caseSummaryModal {
  .modalContainer {
    .modalBox {
      padding: 40px;
      .modalHeader {
        display: flex;
        justify-content: space-between;
        margin-bottom: 40px;
        .modalHeaderTitle {
          color: $text-black-color;
          font-weight: 700;
          font-family: $font-lato;
          font-size: $font-size-16;
        }
        .modalHeaderClose {
          font-size: $font-size-16;
          color: $shadow-grey;
          font-family: $font-lato;
          font-weight: 600;
          cursor: pointer;
        }
      }
      .modalBody {
        .modalBodyList {
          margin-bottom: 35px;
          .modalBodyHeader {
            display: flex;
            justify-content: flex-start;

            .modalBodyVersion {
              width: 150px;
              color: $cerulean-color;
              font-weight: 700;
              font-family: $font-lato;
              font-size: $font-size-16;
            }
            .modalBodyDate {
              color: $shadow-grey;
              font-weight: 400;
              font-family: $font-lato;
              font-size: $font-size-16;
            }
          }
          .modalBodyBody {
            margin-top: 12px;
            color: $text-black-color;
            font-family: $font-lato;
            font-size: $font-size-16;
            .caseBodyTitle {
              font-weight: 400;
            }
            .caseBodyDescription {
              font-weight: 500;
            }
          }
        }
      }
    }
  }
}

.disabledBtnLi {
  opacity: 0.5;
  pointer-events: none;
}
