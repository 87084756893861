@import "../../../scss/variables";

.observation-title {
  font-family: $font-lato;
  font-weight: 700;
  font-size: $font-size-24;
  color: $text-black-color;
  display: flex;
  margin-bottom: 20px;
  margin-left: 20px;
}


