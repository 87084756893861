@import "../../../scss/variables";
.overTableCard {
  height: 1000px;
  width: 100%;

  @media (max-width: 860px) {
    height: 1500px;
  }

  @media only screen and (max-width: 576px) {
    height: 2750px;
    width: 100%;
  }

  .css-de9k3v-MuiDataGrid-selectedRowCount {
    @media (min-width: 600px) {
      visibility: hidden;
      width: auto;
      height: auto;
    }
  }
  .MuiDataGrid-main {
    margin-top: -60px;
  }
  .tableCard {
    border: none;
    .MuiDataGrid-columnHeaders {
      display: none;
    }
    .MuiDataGrid-virtualScrollerRenderZone {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      flex-wrap: wrap;

      .customRowCard {
        width: 33%;
        @media (max-width: 860px) {
          width: 50%;
        }
        @media (max-width: 468px) {
          width: 100%;
        }

        @media only screen and (min-width: 1550px) and (max-width: 1824px) {
          width: 25%;
        }

        margin-top: 20px;
        .customCellTable {
          overflow: inherit !important;
          white-space: normal !important;
          border: none;
          width: 100%;
          outline: none;
          &:focus {
            border: none;
            box-shadow: none;
            outline: none;
          }
        }

        &:hover {
          background-color: transparent;
        }
      }
    }
  }
}
