@import "../../../scss/variables";

.customTableWrapper {
  .tableContainer {
    border: 1px solid $gainsboro;
    overflow: auto;
    width: 100%;
    max-height: 100vh;
    &.borderNone {
      border: none;
    }
    &.autoHeight {
      height: auto;
    }
    table {
      width: 100%;
      thead {
        width: 100%;
        position: sticky;
        top: 0;
        background-color: #fff;
        z-index: 1;
        .column {
          background-color: $Bright-Gray;
          .fixedWidth {
            max-width: 170px;
            width: 170px;
          }
          .inc_report-id {
            min-width: 80px;
            max-width: 80px;
            width: 80px;
          }
          .inc_case-id {
            min-width: 80px;
            max-width: 80px;
            width: 80px;
          }
          .inc_dateOfEntry {
            min-width: 129px;
            width: 129px;
          }
          .inc_blank {
            min-width: 10px;
            width: 10px;
          }

          &.bgNone {
            background-color: $white-color;
            .corrective {
              color: $red-color;
              padding: 10px 0px;
            }
            .kudos {
              color: $green-color;
              padding: 10px 0px;
            }
          }
          .kudosBg {
            background-color: rgba(52, 168, 83, 0.15);
          }
          .currectiveBg {
            background: rgba(255, 0, 0, 0.15);
          }
          th {
            flex: 1;
            text-align: left;
            padding: 10px 20px;
            font-size: $font-size-16;
            color: $text-black-color;
            font-weight: 600;
            font-family: $font-lato;
            min-width: 110px;
            width: 110px;
            cursor: pointer;
            &.minwithTreading {
              max-width: 170px;
              width: 170px;
            }
            span {
              font-size: $font-size-16;
              color: $text-black-color;
              font-weight: 600;
              font-family: $font-lato;
              display: flex;
              align-items: center;
              svg {
                width: 20px;
                margin-left: 5px;
              }
            }
          }
        }
      }
      .row {
        width: 100%;
        border-bottom: 1px solid $gainsboro;
        &.customRowColor {
          .kudosBg {
            background-color: rgba(52, 168, 83, 0.15);
          }
          .currectiveBg {
            background: rgba(255, 0, 0, 0.15);
          }
        }
        td {
          flex: 1;
          text-align: left;
          padding: 10px 20px;
          font-size: $font-size-16;
          color: $text-black-color;
          font-weight: 500;
          font-family: $font-lato;
          &.inc_row_reportId {
            min-width: 80px;
            max-width: 80px;
            width: 80px;
          }
          &.inc_row_caseid {
            min-width: 80px;
            max-width: 80px;
            width: 80px;
          }
          &.inc_row_blank {
            min-width: 5px;
            width: 5px;
          }
          &.inc_row_dateOfEntry {
            min-width: 129px;
            width: 129px;
          }
          .link {
            color: $light-primary-color;
            font-size: $font-size-16;
            font-weight: 500;
            font-family: $font-lato;
            border-bottom: 1px solid $light-primary-color;
            padding-bottom: 3px;
            cursor: pointer;
          }
          .persantage {
            color: $dark-secondary-background-color;
            &.plus {
              color: $green-color;
            }
            &.minus {
              color: $red-color;
            }
          }
        }
      }
    }
  }
}

body {
  &.dark-mode {
    .tableContainer {
      table {
        thead {
          background-color: $dark-primary-background-color;
          .column {
            background-color: $dark-primary-background-color;
            border-bottom: 1px solid $gainsboro;
            &.bgNone {
              background-color: $dark-primary-background-color;
              .corrective {
                color: $red-color;
              }
              .kudos {
                color: $green-color;
              }
            }
            th {
              color: $dark-primary-text-color;
              span {
                color: $dark-primary-text-color;
              }
            }
          }
        }
        .row {
          td {
            color: $dark-primary-text-color;
            .link {
              color: $saphire-blue-color;
              border-bottom: 1px solid $saphire-blue-color;
            }
          }
        }
      }
    }
  }
}
