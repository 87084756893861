@import "../../../scss/variables";

body {
  &.dark-mode {
    .noRowBox{
      .noRowTitle{
        color: $dark-primary-text-color;
      }
    }
  }}


.noRowBox {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  .noRowImg {
    img {
      max-width: 300px;
      max-height: 220px;
    }
  }
  .noRowTitle {
    font-size: $font-size-24;
    color: $text-black-color;
    font-weight: 700;
    font-family: $font-lato;
  }
}
