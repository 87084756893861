@import "../../../scss/variables";

body {
  &.dark-mode {
    .signatureBtn {
      border: 1px solid $light-primary-background-color;
      color: $light-primary-background-color;
    }
    .attendeeList {
      .col.group,
      .col.attendee,
      .col.proof,
      .col.cancel {
        color: $light-primary-background-color;
      }
      .heading {
        background-color: $dark-tertiary-background-color;
      }
    }
    .selectUserStyle {
      .headingstyle {
        color: $light-primary-background-color;
      }
    }
    .msg {
      .defaultMsg {
        color: $dark-primary-text-color;
      }
    }
    .signatureImg {
      .authorizeSign {
        color: $dark-primary-text-color;
      }
    }
    .headingStyleConatiner {
      .headingstyles {
        color: $dark-primary-text-color;
      }
    }
    .typeTxtWrapper {
      .typeTxt {
        color: $dark-primary-text-color;
      }
    }
    .jhAccordianParent {
      .jhAccordianChild {
        background-color: $dark-grid-hover-color;
      }
    }
    .jhAccordianParent {
      .accordinDetails {
        background-color: $dark-primary-background-color;
        .hazardContainer {
          background-color: $dark-grid-hover-color;
          .hazardMainContainer {
            .hazardName {
              color: $dark-primary-text-color;
            }
            .hazardNameValue {
              color: $dark-primary-text-color;
            }
          }
          .controleName {
            .controleStyle {
              color: $dark-primary-text-color;
            }
          }
          .controleMainDiv {
            ul {
              li {
                color: $dark-primary-text-color;
                strong {
                  color: $dark-primary-text-color;
                }
              }
            }
            p {
              color: $dark-primary-text-color;
            }
          }
        }
      }
    }
  }
}

.typeTxtWrapper {
  display: flex;
  align-items: center;
  position: relative;
  justify-content: space-between;
  margin-bottom: 15px;
  .typeTxt {
    color: #666;
    font-family: "Lato";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
  }
  .typeTxtCheck {
    .tickbox {
      top: -4px;
    }
  }
}

.jhAccordianParent {
  border-radius: 4px;
  border: 1px solid #1b436e;
  background: #fff;
  margin-bottom: 10px;
  .jhAccordian {
    background-color: #1b436e;
  }
  .expandIconjha {
    color: #fff;
    font-size: 30px;
  }
  .cateTitlejha {
    .accoTitle {
      color: #fff;
      font-family: "Lato";
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
    }
  }
  .jhAccordianChild {
    border-radius: 5px;
    border: 1px solid #dfdfdf;
    background: #fff;
    margin-bottom: 10px;
  }
  .jhsubCatButton {
    margin-bottom: 25px;
  }
  .accordinDetails {
    border-radius: 5px;
    background: #eee;
    .hazardContainer {
      border-radius: 5px;
      border: 1px solid #dfdfdf;
      background: #fff;
      padding: 20px;
      margin-top: 15px;
      .hazardMainContainer {
        display: flex;
      }
      .hazardNameValue {
        color: #666;
        font-family: "Lato";
        font-size: 16px;
        font-weight: 500;
        padding-top: 3px;
        padding-bottom: 12px;
        margin-left: 5px;
      }
      .hazardName {
        color: #666;
        font-family: "Lato";
        font-size: 16px;
        font-weight: 600;
        padding-top: 3px;
        padding-bottom: 12px;
        font-weight: bold;
      }
      .controleName {
        display: flex;
        .controleMainDiv {
          font-weight: 500;
          margin-left: 5px;
          margin-top: -5px;
          -ms-overflow-style: none;
          &::-webkit-scrollbar {
            width: 0em;
          }

          &::-webkit-scrollbar-track {
            box-shadow: none;
          }

          &::-webkit-scrollbar-thumb {
            background: none;
          }
          ul li strong em h1 h2 h3 h4 h5 blockquote pre {
            // word-wrap: break-word;
            white-space: balance;
            // text-overflow: ellipsis;
          }
          p {
            white-space: balance;
            margin: 0;
          }
          ul {
            text-align: left;
            li {
              margin-bottom: 25px;
              font-size: $font-size-18;
              color: $text-black-color;
              font-weight: 500;
              padding-left: 20px;
              position: relative;
              white-space: balance;

              &::after {
                position: absolute;
                content: "";
                top: 12px;
                left: 0;
                background-color: $text-black-color;
                width: 5px;
                height: 5px;
                border-radius: 10px;
                white-space: balance;
              }
            }
          }
          color: $text-black-color;
          font-weight: 400;
          font-family: $font-lato;
          text-align: left;
          margin-bottom: 15px;
          line-height: 30px;
          white-space: balance;

          strong {
            font-weight: bold;
            color: #333333;
            white-space: balance;
          }
          em {
            font-style: italic;
            white-space: balance;
          }
          ul {
            li {
              margin-bottom: 1px;
              white-space: balance;

              // list-style: inside;
            }
          }
          h1 {
            font-size: 2em;
            font-weight: bold;
            white-space: balance;
          }
          h2 {
            display: block;
            font-size: 1.5em;
            margin-block-start: 0.83em;
            margin-block-end: 0.83em;
            margin-inline-start: 0px;
            margin-inline-end: 0px;
            font-weight: bold;
            white-space: balance;
          }
          h3 {
            display: block;
            font-size: 1.17em;
            margin-block-start: 1em;
            margin-block-end: 1em;
            margin-inline-start: 0px;
            margin-inline-end: 0px;
            font-weight: bold;
            white-space: balance;
          }
          h4 {
            display: block;
            margin-block-start: 1.33em;
            margin-block-end: 1.33em;
            margin-inline-start: 0px;
            margin-inline-end: 0px;
            font-weight: bold;
            white-space: balance;
          }
          blockquote {
            display: block;
            margin-block-start: 1em;
            margin-block-end: 1em;
            margin-inline-start: 40px;
            margin-inline-end: 40px;
            white-space: balance;
          }
          ol {
            display: block;
            list-style-type: decimal;
            margin-block-start: 1em;
            margin-block-end: 1em;
            margin-inline-start: 0px;
            margin-inline-end: 0px;
            padding-inline-start: 40px;
            white-space: balance;
          }
          pre {
            display: block;
            font-family: monospace;
            white-space: pre;
            margin: 1em 0px;
            white-space: balance;
          }
        }
        .controleStyle {
          font-weight: bold;
          min-width: max-content;
        }
        color: #666;
        font-family: "Lato";
        font-size: 16px;
        font-weight: 500;
        padding-top: 3px;
        padding-bottom: 7px;
      }
    }
  }
}
.msg {
  .defaultMsg {
    color: #000;
    font-family: "Lato";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
  }
}

.topHeaderProfile {
  .profileImg {
    max-width: 180px;
    width: 100%;
    height: 180px;
    position: relative;

    .borderProfileImg {
      border-radius: 100%;
      border: 4px solid $cerulean-color;
      width: 100%;
      height: 100%;
    }

    .editProfileicon {
      width: 40px;
      height: 40px;
      border-radius: 100px;
      background-color: $cerulean-color;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      bottom: 60px;
      right: -20px;
    }
  }

  .signatureImg {
    img {
      max-width: 220px;
      max-height: 180px;
      height: auto;
      position: relative;
      cursor: pointer;
    }

    .borderSignatureImg {
      border-radius: 0%;
      border: 4px solid #ffffff;
      width: 100%;
      height: 100%;
    }
    .signatureText {
      font-weight: 600;
    }
  }

  .btnProfleBox {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;

    .btnProfile {
      min-width: 145px;
      height: 44px;
      background-color: $cerulean-color;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 25px;
      cursor: pointer;
      span {
        color: $white-color;
        font-size: $font-size-16;
        font-weight: 700;
        font-family: $font-lato;

        img {
          margin-right: 5px;
        }
      }
    }
  }
}
.signatureBtn {
  border: 1px solid $cerulean-color;
  border-radius: 100px;
  color: $cerulean-color;
  width: 180px;
  text-transform: capitalize;
  display: block;
  text-align: center;
  padding: 8px 10px;
  font-family: $font-lato;
  cursor: pointer;
  &.alignCenter {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.headingStyleConatiner {
  .headingstyles {
    color: #333;
    font-family: "Lato";
    font-size: 24px;
    font-weight: 700;
  }
}

.selectUserStyle {
  .headingstyle {
    color: #666;
    font-family: $font-lato;
    font-size: 16px;
    font-weight: 600;
  }
}

.jhaReport {
  .MuiTypography-root {
    font-family: "lato";
  }
}
