@import "../../../scss/variables";

.swAddReport {
  &.saftyReport {
    .cateTitleWInsRight {
      .skipCat {
        background-color: #ffffff1a;
        border: 1px solid;
        border-radius: 10px;
        color: $white-color;
        cursor: pointer;
        font-family: Lato;
        font-size: 1em;
        margin-right: 30px;
        outline: none;
        padding: 3px 15px;
      }
    }
    .swAccordianChild {
      .swChildAccordian {
        .subCatButton {
          .subCatButtonBox {
            .inspectPersantage {
              color: $white-color;
            }
          }
        }
        .MuiAccordionSummary-expandIconWrapper {
          svg {
            color: $white-color;
          }
        }
        background-color: $light-primary-color;
        .accoTitle {
          color: $white-color;
        }
      }
    }
    .swAccordianParent {
      .swAccordian {
        background-color: $dark-secondary-background-color;
      }
    }
  }
}
.typeTxt {
  .checkImage {
    cursor: pointer;
  }
}