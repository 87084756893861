@import "../../../scss/variables";
.overideImage {
  .thumbContainer {
    display: flex;
    justify-content: flex-start;
    margin: 5px;
    position: relative;
    flex-wrap: wrap;
  }
  imgbox: {
    width: 100px;
    margin-right: 0px;
    position: relative;
  }
  .img {
    width: 82px;
    height: 55px;
    border-radius: 10px;
    margin-top: 10px;
  }
  .fileName {
    color: "#1b436e";
  }
  .fileNameFixed {
    padding: 20px 10px;
  }
  .closeBtn {
    position: absolute;
    top: 1px;
    right: -10px;
    justify-content: flex-end;
    padding: 0px;
    margin: 0px;
    z-index: 9;
    &:hover {
      background-color: transparent;
    }
  }
  .closeIcon {
    color: #000000;
  }
}
.imgBox {
  position: relative;
  margin-right: 10px;
  .dropImg {
    width: 100px;
    height: 60px;
    object-fit: fill;
  }
  .removeIcon {
    position: absolute;
    right: 0px;
    top: -10px;
    cursor: pointer;
    background-color: $cerulean-color;
    color: $white-color;
    width: 25px;
    height: 25px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: $font-size-14;
    font-weight: 600;
  }
}
.removeIcon {
  position: absolute;
  right: 10px;
  cursor: pointer;
}
