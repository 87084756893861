@import "../../../scss/variables";

.overrideViewReports {
  .titleDownloadHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
    .viewReports {
      font-size: $font-size-24;
      font-weight: 700;
      font-family: $font-lato;
      color: $light-primary-text-color;
    }
    .downloadpdf.sw.marginRemove {
      margin-top: 0px;
    }
  }
  .downloadcsv {
    background-color: $green-color;
    text-align: left;
    border-radius: 20px;
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 36px;
    color: $light-primary-background-color;
    font-size: 14px;
    font-weight: 400;
  }
  .downloadcsv .downloadIcon {
    color: $light-primary-background-color;
  }

  .viewReportHeaderContainerBottom {
    margin: 50px 0px;
    &.box-shadow {
      box-shadow: 0px 4px 4px 0px #00000040;
      border: 1px solid $chinese-white;
      padding: 20px 20px 10px 20px;
      border-radius: 15px;
      margin: 50px 0px 25px 0px;
      &.mt-10 {
        margin-top: 0px;
      }
    }
  }
  .viewReportHeaderContainerTop,
  .viewReportHeaderContainerBottom {
    .vrhctListBox {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid $chinese-white;
      margin-bottom: 5px;
      padding: 5px 0px;
      &.border-none {
        border: none;
      }
      .vrhctList {
        width: 50%;
        font-size: $font-size-16;
        font-weight: 700;
        font-family: $font-lato;
        color: $light-primary-text-color;
        &.w-100 {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .editIcon {
            cursor: pointer;
            svg {
              path {
                fill: $light-primary-text-color;
              }
            }
          }
        }
        span {
          font-size: $font-size-16;
          font-weight: 700;
          font-family: $font-lato;
          color: $light-primary-color;
          padding-left: 5px;
        }
        &.titleReport {
          font-size: $font-size-20;
          font-weight: 700;
          font-family: $font-lato;
          color: $light-primary-text-color;
        }
      }
    }
  }
}

.overrideViewReports {
  &.pdfStyles {
    .titleDownloadHeader {
      margin-bottom: 10px;
    }
    .viewReportHeaderContainerBottom {
      margin: 10px 0px;
    }
    .oshaOtherDetailContainer {
      .oshaOtherList {
        margin-bottom: 3px;
        .oshaOtherListBottom {
          .oshaOtherListBottomBox {
            .oshaOtherListBottomPara {
              margin-bottom: 3px;
            }
          }
        }
      }
    }
  }
}

body .buttonSubmititaOsha {
  display: flex;
  align-items: center;
  .overideBtn {
    .containedButton {
      margin-top: 3px;
      max-width: 200px;
      background-color: $green-color;
      color: $white-color;
      &.Mui-disabled {
        background-color: $border-grey;
        color: $white-color;
      }
    }
  }
  .disbledInfo {
    margin-left: 20px;
    .disbledInfoImg {
      cursor: pointer;
    }
  }
}

.disbledInfoContent {
  .MuiPaper-rounded {
    max-width: 215px;
    border-radius: 20px;
    background-color: $spanish-gray;
    p {
      font-size: $font-size-12;
      text-align: center;
      color: $white-color;
      font-family: $font-lato;
      padding: 10px;
    }
  }
}

body {
  .oshaa_button {
    .overideBtn {
      .containedButton {
        max-width: 250px;
      }
    }
  }
}
body {
  &.dark-mode {
    .titleDownloadHeader {
      .viewReports {
        color: $dark-primary-text-color;
      }
    }
    .viewReportHeaderContainerTop,
    .viewReportHeaderContainerBottom {
      .vrhctListBox {
        .vrhctList {
          color: $dark-primary-text-color;
          span {
            color: $dark-primary-color;
          }
        }
      }
    }
    .oshaOtherDetailContainer {
      .oshaOtherList {
        .oshaOtherListTitle {
          background-color: $dark-primary-color;
        }
      }
    }
    .oshaOtherDetailContainer {
      .oshaOtherList {
        .oshaOtherListBottom {
          background-color: $dark-secondary-background-color;
        }
        .oshaOtherListBottomBox {
          background-color: $dark-secondary-background-color;
        }
      }
    }
  }
}
