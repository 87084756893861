@import "../../../scss//variables";

body {
  &.dark-mode {
    .overideLables {
      .formLabel {
        color: $dark-primary-text-color;
      }
    }
  }
}

.overideLables {
  .formLabel {
    font-family: $font-lato;
    font-style: normal;
    font-weight: 600;
    font-size: $font-size-16;
    line-height: 19px;
    text-align: left;
    margin-bottom: 10px;
    color: $shadow-grey;
  }
  .required {
    color: $red-color;
    text-decoration: none;
  }
}
.editBtn {
  cursor: pointer;
}
