@import "../../../../scss/variables";

body {
  &.dark-mode {
    .toolBoxOverRide {
      .inspectionOverRide {
        .CategoriesBox {
          background: $dark-tertiary-background-color;
        }
      }
    }
  }
}

.grid_main_sec1 {
  width: 100%;
}

.filter_btn_Attendee {
  margin-top: 52px;
}
.footerVerrify {
  width: 100%;
  background: #1b436e;
  // padding-left: 4rem;
  width: 1099px;
  height: 58px;
  display: flex;
  border: 1px solid red;
}
.footerVerrify p {
  font-style: normal;
  color: #ffffff;
}
.userselected {
  flex-direction: row-reverse;
  margin-top: 18px;
  margin-left: 20px;
}
.verrifybtn {
  flex-direction: row;
  margin-top: 10px;
  margin-left: 660px;
  border: 1px solid orange;
}

.toolBoxOverRide {
  &.inspectionOverRide {
    .CategoriesBox {
      margin-top: 0px;
      .CheckboxIcon {
        width: 20px;
      }
      .catListItem {
        width: 80%;
      }
      .closeIcon {
        font-size: $text-black-color;
        font-size: $font-size-18;
        font-weight: 500;
      }
    }
  }
}
.verrifyattendee {
  .MuiDataGrid-columnHeaderTitleContainerContent {
    padding-right: 4rem;
  }
}
// .MuiDataGrid-columnHeaderTitleContainerContent {
//   padding-right: 4rem;
// }
.diologattendee {
  .MuiDataGrid-columnHeaderTitleContainerContent {
    padding-right: 1rem;
  }
}
.assigned-to--cell-Group {
  cursor: pointer;
}
