@import "../../../scss/variables";

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  z-index: 9999;
  padding: 0px 70px;
  .mainImgBox {
    max-width: 1024px;
    width: 100%;
    margin: auto;
    .mainImg {
      display: block;
      max-width: 100%;
      margin: 60px auto;
      max-height: 80vh;
      height: 100%;
    }
  }
}
.overlay > span {
  position: absolute;
  top: 40px;
  right: 20px;
  font-size: 30px;
  color: $white-color;
  z-index: 999;
  cursor: pointer;
  img {
    cursor: pointer;
  }
}
.overlay-arrows_left {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 50%;
  width: 50px;
  height: 50px;
  left: 50px;
  z-index: 9999;
  img {
    cursor: pointer;
  }
}

.overlay-arrows_right {
  display: flex;
  justify-content: center;
  position: absolute;
  right: 50px;
  width: 50px;
  height: 50px;
  top: 50%;
  z-index: 9999;
  img {
    cursor: pointer;
  }
}
