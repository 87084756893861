@import "../../../../scss/variables";

body {
  &.dark-mode {
    .confBox {
      .confTitile {
        color: $dark-primary-text-color;
      }
      .confSubTitle {
        color: $white-color;
      }
    }
    .modalContainer {
      .modalBox {
        background-color: $dark-primary-background-color;
      }
      .modalTitle {
        color: $dark-primary-text-color;
      }
    }
    .inspectionOverRide {
      .CategoriesBox {
        .title {
          background-color: $dark-secondary-background-color;
          color: $dark-primary-text-color;
        }
      }
    }
    .inspectionOverRide {
      .CategoriesBox {
        .mainQuestion {
          .catListBox {
            color: $dark-primary-text-color;
          }
        }
      }
    }
  }
}

.inspectionOverRide {
  .CategoriesBox {
    margin-top: 25px;
    .title {
      background-color: $sky-grey-color;
      padding: 10px 25px;
      font-size: $font-size-16;
      font-weight: 600;
      font-family: $font-lato;
      &.columnBox {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .question {
          width: 70%;
        }
        .responsetype {
          width: 20%;
        }
        .status {
          width: 10%;
        }
      }
    }

    .catListBox {
      // border: 1px solid $gainsboro;
      // padding: 15px 25px;
      font-size: $font-size-16;
      font-weight: 400;
      font-family: $font-lato;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .question {
        width: 70%;
      }
      .responsetype {
        width: 20%;
      }
      .status {
        width: 10%;
      }
      .isActiveBox {
        flex-direction: row;
        align-items: center;
      }
    }
    .setQuestion {
      .catListBox {
        // border: 1px solid $gainsboro;
        // padding: 15px 25px;
        font-size: $font-size-16;
        font-weight: 400;
        font-family: $font-lato;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .question {
          width: 70%;
        }
        .responsetype {
          width: 20%;
        }
        .status {
          width: 10%;
        }
        .isActiveBox {
          flex-direction: row;
          align-items: center;
        }
        .catListItem {
          width: 80%;
        }
        .editIcon {
          cursor: pointer;
          width: 10%;
        }
        .closeIcon {
          cursor: pointer;
          width: 10%;
          svg {
            width: 20px;
          }
        }
      }
    }
    .addQuestionTitle {
      font-size: $font-size-16;
      color: $cerulean-color;
      font-weight: 600;
      font-family: $font-lato;
      margin: 50px 0px;
      cursor: pointer;
    }
    .questionBox {
      &.questConf {
        .catListBox {
          .catListItem {
            width: 80%;
          }
          .editIcon {
            width: 10%;
            cursor: pointer;
          }
          .closeIcon {
            width: 10%;
            cursor: pointer;
            svg {
              width: 20px;
            }
          }
        }
      }
      .questionList {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        .questionTitle {
          border: 1px solid $light-silver;
          padding: 12px;
          font-size: $font-size-16;
          font-weight: 600;
          color: $text-black-color;
          font-family: $font-lato;
          width: 100%;
          height: 44px;
        }
        .questionClose {
          background-color: $light-silver;
          width: 44px;
          height: 44px;
          color: $shadow-grey;
          font-size: $font-size-18;
          font-weight: 500;
          font-family: $font-lato;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }
      }
    }
  }
  .insTitle {
    margin: 0px 0px 30px 0px;
    font-size: $font-size-24;
    color: $text-black-color;
    font-weight: 700;
    font-family: $font-lato;
  }
  .QuestionTitle {
    margin: 0px 0px 10px 0px;
    font-size: $font-size-24;
    color: $text-black-color;
    font-weight: 700;
    font-family: $font-lato;
  }
  .addQuestionBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .addQuestionInput {
      width: 100%;
      .overideInputField {
        width: 100%;
        .input_field_box {
          width: 100%;
          .formControlRoot {
            border-radius: 0px;
          }
        }
      }
    }
    .addQuestionPlus {
      background-color: $light-silver;
      width: 44px;
      height: 46px;
      color: $shadow-grey;
      font-size: $font-size-24;
      font-weight: 500;
      font-family: $font-lato;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }
}
.errorMsg {
  font-size: $font-size-12;
  color: $red-color;
  margin-top: 2px;
}

.insBackBtn {
  .backBtn {
    .backImg {
      filter: brightness(0.5);
      width: 8px;
    }
    .backTitle {
      font-size: $font-size-16;
      color: $shadow-grey;
      padding-left: 10px;
    }
  }
}
.confBox {
  align-items: center;
  .confTitile {
    font-size: $font-size-24;
    font-weight: 700;
    color: $text-black-color;
    font-family: $font-lato;
  }
  .confSubTitle {
    font-size: $font-size-20;
    font-weight: 700;
    color: $text-black-color;
    font-family: $font-lato;
  }
}
.modalContainer {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .modalBox {
    background-color: $white-color;
    padding: 20px;
    border-radius: 8px;
    max-width: 624px;
    width: 100%;
    .modalTitle {
      text-align: center;
      font-size: $font-size-20;
      font-weight: bold;
      font-family: $font-lato;
    }
    .modalInputBox {
      &.p-0 {
        padding: 0px;
      }
      padding: 75px 0px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .errorMsg {
        width: 100%;
        max-width: 400px;
      }
      .overideInputField {
        max-width: 400px;
        width: 100%;
      }
    }
    .modalFooterBox {
      justify-content: flex-end;
    }
  }
}

.inspectionOverRide {
  .CategoriesBox {
    .mainQuestion {
      .catListBox {
        border: 1px solid $gainsboro;
        padding: 15px 25px;
        &.small {
          padding: 5px 25px;
        }
      }
    }
  }
}
.inspectionOverRide {
  &.stromwater {
    .insTyBox {
      .insTyList {
        margin-bottom: 30px;
      }
    }
  }
}
