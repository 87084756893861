@import "../../scss/variables";

.onboarding {
  max-width: 100vw;
  min-height: 100vh;

  overflow-x: hidden;

  &-container {
    @media screen and (min-width: 768px) {
      margin: 20px;
      margin-top: 50px;
      display: flex;
      padding-top: 5%;
      margin-top: 0px;
      justify-content: space-between;
    }
  }
  .form-wrapper {
    margin-left: 0px;
    margin-right: 15px;
    max-width: 356px;
    width: 100%;
  }
  &-left {
    width: 100%;
    flex-direction: column;
    padding: 0px 30px;
    margin-bottom: 60px;
    .logos {
      margin-bottom: 15px;
    }
    .onboarding-form {
      display: flex;
      width: 100%;
      .form-wrapper {
        margin-left: 0px;
        margin-right: 15px;
        max-width: 356px;
        width: 100%;
      }
    }
    .onboarding-button {
      .button {
        max-width: 161px;
        width: 100%;
      }
    }
    .onboarding-left-industry-wrap {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 15px;
      margin-top: 24px;
      .card {
        max-width: 247px;
        width: 100%;
        position: relative;
        display: flex;
        margin: 0px 5px 12px 5px;
        flex-wrap: wrap;
        .card-div {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          width: 100%;
          position: relative;
          margin: 0px;
          padding: 0px 15px;
          .cardImg {
            width: 70px;
            height: 70px;
            background-color: #f5f5f5;
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 10px;
            img {
              width: 100%;
              height: auto;
            }
          }
          h1 {
            max-width: 55%;
            margin: 0px;
            margin-left: 15px;
          }
        }
      }
    }
    @media screen and (min-width: 768px) {
      //   max-width: 50%;
      display: flex;
      flex-direction: column;
      padding: 0px 30px;
      margin-bottom: 60px;
    }
    .logo {
      width: 170px;
      max-width: 100%;
    }
    .logoDark {
      display: none;
      text-align: left;
    }
    .logoLight {
      display: block;
      text-align: left;
    }

    h1 {
      font-style: normal;
      font-weight: 600;
      font-size: $font-size-32;
      line-height: 38px;
      color: $text-black-color;
      text-align: left;
    }

    &-industry {
      font-style: normal;
      font-weight: 600;
      font-size: $font-size-16;
      line-height: 18px;
      color: $shadow-grey;
      margin: 64px 0px 0px 0px;

      &-wrap {
        display: flex;
        flex-wrap: wrap;

        @media screen and (max-width: 1000px) {
          grid-template-columns: repeat(2, 1fr);
        }
      }
    }
  }
}

.onboarding-button {
  @media screen and (min-width: 768px) {
    display: flex;
  }
}
.card {
  border: 3px solid #dfdfdf;
  border-radius: 9px;
}
.card:hover {
  border-color: #1976d2;
}
.card-selected {
  border-color: #15cf5f;
}

.card h1 {
  font-weight: 600;
  font-size: 20px;
  line-height: 18px;
}
#compny-area:hover {
  border: 3px solid;
  border-color: #1976d2;
}
#job-area:hover {
  border: 3px solid;
  border-color: #1976d2;
}
