.newsList {
  width: 100%;
  background: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 8px;
  //height: 150px;
  box-sizing: border-box;
  //width: 959px;
  //height: 260px;
  min-height: 260px;
  padding: 20px;
  .news {
    display: flex;
    width: 100%;
    flex-wrap: wrap;

    .newscoverimg img {
      border-radius: 10px;
      width: 101px;
      height: 68px;
      object-fit: cover;
    }
  }
  .settingicons {
    text-align: right;
    cursor: pointer;
    padding-right: 24px;
  }
}
.newsList {
  display: flex;
  // align-items: center;
  justify-content: center;

  .noimgg {
    display: flex;
  }
}
.nonews {
  align-items: center;
  justify-content: center;
}
.newsdesc {
  cursor: pointer;
}
