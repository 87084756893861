@import "../../../src/scss/variables";
body {
  &.dark-mode {
    .emailExportOverride {
      .headingOfSubtitle {
        color: #666;
        font-family: "Lato";
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        color: #fff;
      }
    }
  }
}
.emailExportOverride {
  .headingOfSubtitle {
    color: #666;
    font-family: "Lato";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
  }
  .datePicker_row_emailExport {
    display: flex;
    justify-content: center;
    gap: 15px;
  }
  .checkboxEmailExport {
    .filterListCheckbox {
      margin-right: 8px;
    }
    .css-dmmspl-MuiFormGroup-root {
      display: inline;
    }
    .css-j204z7-MuiFormControlLabel-root {
      padding-right: 35px;
    }
  }
  .ExportTomailConainer {
    display: flex;
    align-items: center;
    cursor: pointer;
    background-color: #4caf50;
    border: none;
    padding: 9px 14px;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    border-radius: 24px;
    .ExportTomailText {
      color: #fff;
      text-align: center;
      font-family: "Lato";
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
    }
  }
  .ButtonandExportMailContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    .mailExportButtonStyle {
      .mailExportButtonStyles {
        color: $dark-primary-text-color;
        text-align: center;
        font-family: "Lato";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        text-transform: none;
      }
    }
  }
  .SelectAllConainerStyle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 50px;
  }
  .selectAllCheckBoxStyles {
    margin-bottom: 10px;
  }
  .downloadExportImage {
    transform: rotate(270deg);
    margin-right: 6px;
  }
}

.checkboxEmailExport {
  &.exportCheckbox {
    .check {
      .closeItem {
        position: relative;
        top: 4px;
      }
    }
  }
}
