@import "../../../../scss/variables";

body {
  &.dark-mode {
    .headerWrapper {
      .headerTitle {
        color: $dark-primary-text-color;
      }
      .headerBox {
        .headerLeft {
          ul {
            li {
              color: $dark-primary-text-color;
              &.active {
                background-color: $dark-primary-color;
                color: $dark-primary-text-color;
              }
            }
          }
        }
      }
    }
  }
}

.headerWrapper {
  .headerTitle {
    color: $text-black-color;
    font-size: $font-size-22;
    font-weight: 700;
    font-family: $font-lato;
    text-align: left;
    margin-bottom: 40px;
  }
  .headerBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .headerLeft {
      ul {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        li {
          color: $shadow-grey;
          font-size: $font-size-14;
          font-weight: 600;
          font-family: $font-lato;
          padding: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 98px;
          height: 40px;
          border: 1px solid $gainsboro;
          cursor: pointer;
          &.active {
            background-color: $cerulean-color;
            border: 1px solid $cerulean-color;
            color: $white-color;
          }
        }
      }
    }
  }
}
.swdashboard-header {
  .headerWrapper {
    .headerBox {
      // flex-direction: row-reverse;
      .headerLeft {
        ul {
          justify-content: flex-start;
          li {
            width: 125px;

            cursor: pointer;
            &.active {
              background-color: $cerulean-color;
              border: 1px solid $cerulean-color;
              color: $white-color;
            }
          }
        }
      }
    }
  }
}
