.errorMsg {
  color: #f21d1d;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
  margin-top: 3px;
  margin-right: 14px;
}
@media only screen and (min-width: 862px) and (max-width: 1210px) {
  .addnewrolecls {
    display: flex;
    justify-content: flex-end;
    margin-top: 1px;
  }
}
