@import "../../../../scss/variables";

body {
  &.dark-mode {
    .toolboxList {
      .tabcustom.Mui-selected {
        color: $dark-primary-color;
      }
      .tabcustom {
        color: $light-primary-background-color;
      }
    }
  }
}

.toolboxList {
  margin-top: 50px;
  .selfassign {
    max-width: 150px;
    float: right;
  }
  .rightFilter {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .searchfilter {
      border: 1px solid $fossil-grey;
      background-color: $white-color;
      border-radius: 15px;
      max-width: 204px;
      width: 100%;
      height: 100%;
      max-height: 44px;
      label {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 100%;
        span {
          display: flex;
          padding-left: 10px;
        }
      }
      .searchfilterInput {
        height: 100%;
        input {
          border: none;
          height: 100%;
          width: 100%;
          border-radius: 15px;
          font-family: $font-lato;
          font-size: $font-size-16;
          color: $manatee-color;
          //font-weight: 600;
          &::placeholder {
            color: $manatee-color;
          }
          &:focus {
            outline: none;
          }
        }
      }
    }
    .filter_btn {
      max-width: 130px;
      width: 100%;
      height: 100%;
      max-height: 44px;
      margin-left: 20px;
      z-index: 10;
      .btn {
        border: 1px solid #dde2e5;
        background-color: #fff;
        border-radius: 15px;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $shadow-grey;
        font-size: $font-size-16;
        font-weight: 600;
        font-family: $font-lato;
        cursor: pointer;
        .filterIcons {
          color: $shadow-grey;
          margin-left: 10px;
          font-weight: 700;
          font-size: $font-size-26;
        }
      }
    }
  }
  .tabcustom {
    padding: 0px 0px 10px 0px;
    font-size: $font-size-16;
    font-family: $font-lato;
    color: $spanish-gray;
    margin-right: 37px;
    font-weight: 700;
    text-transform: capitalize;
    &.Mui-selected {
      color: $cerulean-color;
    }
    .totalV {
      background-color: $giants-orange;
      width: 22px;
      height: 22px;
      border-radius: 100%;
      color: $white-color;
      font-family: $font-lato;
      font-size: $font-size-12;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 1;
    }
  }
  .MuiTabs-indicator {
    bottom: 24px;
    background-color: $cerulean-color;
  }
  &.noIn {
    .MuiTabs-indicator {
      bottom: 16px;
    }
  }

  .tabPanel {
    width: 100%;
    margin-top: 20px;
  }
  .MuiDataGrid-columnHeaderTitle {
    font-family: $font-lato;
    font-weight: 600;
    color: $text-black-color;
  }

  .customrowTable {
    height: 4rem;
  }
  .ToolBoxTitle-assigned-to--cell {
    cursor: pointer;
  }
}

.filter_of_caselisttoolBox {
  display: flex;
  //flex-wrap: wrap;
  width: 100%;
  // max-width: 700px;
  margin-bottom: 20px;
  //margin-left: 90px;
  gap: 10px;
  justify-content: flex-end;
  .filterLableText {
    width: 100%;
  }

  .searchcaseid {
    position: relative;
    max-width: 50%;
    flex: 50%;

    label {
      span {
        position: absolute;
        top: 10px;
        left: 15px;

        svg {
          font-size: 30px;
        }
      }

      div {
        input {
          width: 100%;
          min-height: 45px;
          padding: 15px 15px 15px 50px;
          font-size: $font-size-16;
          border: 1px solid $Stroke-Fossil-Grey;
          border-radius: 15px;
          outline: none;
        }

        input::placeholder {
          color: #9a9fa8;
        }

        input:active,
        input:focus {
          color: #828996;
        }
      }
    }
  }

  .filter_btn {
    button {
      width: 100%;
      padding: 5px 20px;
      line-height: 28px;
      font-size: $font-size-16;
      font-family: $font-lato;
      border: 1px solid $Stroke-Fossil-Grey;
      border-radius: 15px;
      outline: none;
      color: $shadow-grey;
      background-color: transparent;
      //  margin-left: 10px;
      font-weight: 600;
      cursor: pointer;

      svg#icn {
        font-size: $font-size-24;
        position: relative;
        top: 8px;
        right: 5px;
        color: $manatee-color;
      }
    }
  }
}
