@import "../../../../scss/variables";

body {
  &.dark-mode {
    .commonOveride {
      .titleDetail {
        color: $dark-primary-text-color;
      }
      .commoninfoWrap {
        background: $dark-secondary-background-color;
        .commoninfoBox {
          .commoninfoleft {
            .title {
              color: $dark-primary-color;
            }
            .columns {
              .listView {
                color: $dark-primary-text-color;
              }
              .focusedText {
                color: $dark-primary-text-color;
              }
              .font-w400 {
                color: $dark-primary-text-color;
              }
            }
          }
        }
      }
    }
  }
}

.viewOverideContact {
  .commonOveride {
    margin-top: 0px;
  }
}
.filterbtn:hover {
  cursor: pointer;
}
.filterbtn {
  margin-left: 10px;
}
.contactswitch .MuiFormControlLabel-label {
  margin-left: 15px;
  font-family: $font-lato;
}

.contactswitch .MuiFormLabel-colorPrimary {
  font-family: $font-lato;
}
.addcontactbtn {
  display: flex;
  justify-content: flex-end;
}
