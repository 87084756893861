.not-allowed {
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-color: black;

  &-middle {
    display: flex;
    flex: 1 1 auto;
    padding: 20px 0;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  &-message1 {
    color: #fff;
    font-size: 33px;
    margin-top: 30px;
    font-weight: bold;
    line-height: 41px;
    text-align: center;
  }

  &-message2 {
    color: #fff;
    font-size: 14px;
    margin-top: 5px;
    line-height: 17px;
    text-align: center;
  }
}
