@import "../../../../scss/variables";
body {
  &.dark-mode {
    .modalUsrContainer {
      .validationPopup {
        background-color: $black-color;
      }
    }
  }
}

.modalUsrContainer {
  .validationPopup {
    border: 2px rgb(238, 238, 238);
    padding: 7px;
    margin-top: -101px;
    margin-right: -302px;
    position: absolute;
    margin-left: 234px;
    background-color: #fff;
    z-index: 9;
  }
}
