.successScreenBody {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .heading {
    color: #333333;
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
    margin: 30px 0px 45px 0px;
  }
  .report {
    color: #666666;
    font-size: 14px;
    font-weight: 700;
  }
  .CNU {
    color: #000000;
    font-size: 16px;
    font-weight: 700;
  }
  .generated {
    color: #666666;
    font-size: 14px;
    font-weight: 700;
  }
  .redirect {
    color: #999999;
    font-size: 14px;
    font-weight: 400;
    margin-top: 26px;
    span {
      color: #333333;
      font-size: 14px;
    }
  }
}
