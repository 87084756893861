@import "../../../../scss/variables";

.container_wrapper {
  text-align: left;
  .heading {
    font-family: $font-lato;
    font-weight: 700;
    font-size: $font-size-24;
    color: $text-black-color;
  }
  .buttonsGrid {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .cancelSaveDiv {
      display: flex;
      align-items: center;
      justify-content: right;
      gap: 50px;
    }
  }
}
