@import "../../../scss/variables";

body {
  &.dark-mode {
    .overideAction {
      .formLabelStyle {
        color: $dark-primary-text-color;
      }
      .btnIconColor {
        color: $dark-primary-text-color;
      }
      .btnColor {
        color: $dark-primary-text-color;
      }
      .cancelBtn {
        border: 1px solid $dark-primary-color;
        color: $dark-primary-color;
      }
    }
  }
}
.overideAction {
  margin-top: 20px;
  .formLabelStyle {
    font-family: $font-lato;
    font-weight: 600;
    font-size: 16px;
    color: #666666;
    margin-bottom: 12px;
  }
  .actionContainer {
    border-radius: 15px;
    border: 1px solid $fossil-grey;
    padding: 10px;
  }
  .actionError {
    color: #f21d1d !important;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 0.75rem;
    margin-top: 11px;
  }
  .btnBody {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }
  .btnColor {
    color: $text-black-color;
    text-transform: capitalize;
  }
  .btnIconColor {
    color: $cerulean-color;
  }
  .saveBtn {
    color: $white-color;
    background-color: $cerulean-color;
    margin-right: 15px;
    border-radius: 20px;
    padding: 4px 27px;
    text-transform: capitalize;
  }
  .cancelBtn {
    border: 1px solid $cerulean-color;
    border-radius: 100px;
    color: $cerulean-color;
    width: 200px;
    text-transform: capitalize;
  }
  .nameBody {
    text-align: left;
    background: #f5f5f5;
    padding: 10px;
    margin-top: 10px;
  }
  .nameTxt {
    font-family: $font-lato;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    color: $black-color;
    display: flex;
    align-items: center;
    span {
      padding-left: 5px;
    }
  }
  .name {
    color: $cerulean-color;
    text-decoration: none;
  }
  .imageNameBody {
    padding: 10px 0px 0px 0px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .removeAction {
      margin-left: 10px;
      background-color: $cerulean-color;
      width: 25px;
      height: 25px;
      color: $white-color;
      font-size: $font-size-14;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 60px;
      font-weight: 600;
      cursor: pointer;
    }
  }
  .selectHidden {
    border: none;
    position: absolute;
  }

  .addAssigSearch {
    max-width: 200px;
    width: 100%;
  }
  .overideTextArea {
    .textArea {
      border: none;
      padding: 0px;
      border-radius: 0px;
    }
  }
}
