@import "../../../../scss/variables";
.overEquiment {
  // .container_wrapper {
  //   text-align: left;
  .heading {
    font-family: $font-lato;
    font-weight: 700;
    font-size: $font-size-24;
    color: $text-black-color;
  }
  .buttonGrid {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // flex-direction: column;

    .cancelBtn {
      display: flex;
      align-items: center;
      justify-content: right;
      gap: 50px;
    }
  }
}
// }
.divisionWithFilter {
  margin-top: 5px;
}
