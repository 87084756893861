.mapContainer {
  position: relative;
  .autocompleteStyle {
    position: absolute;
    top: 16px;
    z-index: 1;
    left: 229px;
    input {
      width: 260px;
      height: 31px;
      border-radius: 7px;
      font-size: 15px;
      padding: 8px;
    }
  }
}
.infoWindow {
  .infoWindowCard {
    width: 200px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    background-color: #fff;
    padding: 13px;
    margin-left: -100px;
    margin-top: -109px;
    .infoWindowContent {
      font-size: 16px;
    }
  }
  .infoWindowCloseButton {
    font-size: 30px;
    cursor: pointer;
  }
}
