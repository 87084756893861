@import "../../../scss/variables";

.filterCheckbox {
  margin-right: 10px;
}
.css-3bmhjh-MuiPaper-root-MuiPopover-paper:hover {
  cursor: pointer;
}
.css-3bmhjh-MuiPaper-root-MuiPopover-paper:hover {
  cursor: pointer;
}

.checkboxStyleOverrides {
  .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked {
    color: #1b436e;
  }
}
.checkboxStyleOverrides {
  .css-zun73v.Mui-checked {
    color: #1b436e;
  }
}
.filterlist_of_cases {
  .overideLables {
    .formLabel {
      color: #666666;
      font-size: 16px;
      font-weight: 600;
    }
  }
}

.NotificationMainconatiner {
  position: relative;
  .NotificationHeading {
    font-size: $font-size-16;
    font-family: $font-lato;
    font-weight: 700;
    padding-bottom: 15px;
    color: #666666;
    align-items: center;
    padding-left: 6px;
  }
  .notificationConatiner {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 17px;
    border-bottom: 3px solid #f2f2f2;
    padding-bottom: 15px;
    padding-top: 18px;
    .bellicon {
      .notificationIcon {
        font-size: 38px;
        color: $white-color;
        background: $cerulean-color;
        border-radius: 50%;
        padding: 8px;
      }
    }
    .notificationTextContainer {
      .NotificationTitleContainer {
        .notificationTitle {
          color: $cerulean-color;
          font-size: $font-size-16;
          font-family: $font-lato;
          font-weight: 700;
          padding-bottom: 5px;
        }
        .notificationSubTitle {
          color: #828282;
          font-size: $font-size-14;
          font-family: $font-lato;
          font-weight: 400;
          padding-bottom: 5px;
        }
      }
    }
  }
  .notificationDateTimeUnread {
    display: flex;
    align-items: center;
    gap: 20px;
    .notificationDateTime {
      font-family: $font-lato;
      font-size: $font-size-12;
      font-weight: 400;
      white-space: nowrap;
      flex: 0 0 auto;
    }
  }
}
