@import "../../../../scss/variables";

body {
  &.dark-mode {
    .inspectionOverRide {
      &.inspectionAcknowledgement {
        .inspectionAcknowledgementHeading {
          color: $dark-primary-text-color;
        }
        .acknowledgmentBox {
          .title {
            color: $white-color;
          }
          .acknowledgmentListBox {
            .acknowledgmentList {
              background-color: $black-color;
              .acknowledgmentListTitle {
                p {
                  color: $white-color;
                }
              }
              .acknowledgmentActions {
                .acknowledgmentAction {
                  svg {
                    color: $white-color;
                  }
                }
              }
            }
          }
        }
        .contactswitch {
          .MuiFormLabel-colorPrimary {
            color: $dark-primary-text-color;
          }
        }
      }
    }
  }
}

.inspectionOverRide {
  &.inspectionAcknowledgement {
    .inspectionAcknowledgementHeading {
      font-size: $font-size-24;
      font-weight: 700;
      font-family: $font-lato;
      color: $text-black-color;
      margin-bottom: 50px;
    }
    .contactswitch {
      .MuiFormLabel-colorPrimary {
        color: $shadow-grey;
        font-size: $font-size-16;
        font-weight: 600;
        font-family: $font-lato;
        margin-bottom: 10px;
      }
    }
    .MuiFormControlLabel-labelPlacementEnd {
      .MuiSwitch-sizeMedium {
        margin-left: 7px;
      }
    }
    .insBackBtn {
      .backBtn {
        margin-top: 10px;
      }
    }
    .acknowledgmentBox {
      .title {
        font-size: $font-size-18;
        font-weight: 600;
        color: $light-primary-text-color;
      }
      .acknowledgmentListBox {
        .acknowledgmentList {
          width: 100%;
          background: $light-primary-background-color;
          border: 1px solid $fog-grey;
          border-radius: 8px;
          box-sizing: border-box;
          padding: 20px;
          .acknowledgmentListTitle {
            font-size: $font-size-16;
            font-weight: 600;
            color: $light-primary-text-color;
            margin-bottom: 5px;
            p {
              margin-top: 5px;
              font-size: $font-size-14;
            }
          }
          .acknowledgmentActions {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            .acknowledgmentAction {
              margin-left: 10px;
              cursor: pointer;
              .MuiFormControlLabel-label {
                margin-left: 5px;
              }
              svg {
                color: $light-primary-color;
              }
            }
          }
        }
      }
    }
  }
}
