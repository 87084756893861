@import "../../scss/variables";
.overideAccessDeniedPage {
  display: flex;
  flex-direction: column;
  font-family: $font-lato;
  .imageDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      height: 350px;
      width: 500;
    }
  }
  .textDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    h1 {
      font-size: $font-size-22;
      font-weight: 500;
      color: $text-black-color;
    }
    h2 {
      font-size: $font-size-16;
      font-weight: 700;
      color: #1b436e;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
