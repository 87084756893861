@import "../../../../scss/variables";

body {
  &.dark-mode {
    .viewImageBox {
      .viewList {
        &.small {
          .listItem {
            .imageName {
              color: $dark-primary-color;
            }
          }
        }
        &.large {
          .listItem {
            .imageName {
              color: $dark-primary-color;
            }
          }
        }
      }
    }
  }
}

.viewImageBox {
  margin-top: 17px;

  .viewList {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;
    &.large {
      .listItem {
        margin-bottom: 10px;
        img {
          width: 190px;
          height: 130px;
          margin-right: 30px;
        }
        .imageName {
          font-size: $font-size-14;
          color: $cerulean-color;
          font-weight: 700;
          font-family: $font-lato;
          word-break: break-word;
        }
      }
    }
    &.small {
      .listItem {
        width: 82px;
        margin-right: 15px;
        img {
          width: 100%;
          height: 55px;
        }
        .imageName {
          font-size: $font-size-14;
          color: $cerulean-color;
          font-weight: 700;
          font-family: $font-lato;
          word-break: break-word;
        }
      }
    }
  }
}
