@import "../../../../scss/variables";

.bodyPartWrapper {
  display: flex;
  justify-content: space-between;
  .bodyPartLeft {
    width: 75%;
  }
  .bodyPartRight {
    width: 25%;
  }
}
.bodyInjured {
  padding-top: 100px;
  @media (margin-top: 767px) {
    padding-top: 40px;
  }
  .unselect {
    fill: $chinese-silver;
  }
  .selected {
    fill: $apple-red;
  }
}
