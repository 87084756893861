@import "../../scss/variables";

.colorGreen {
  font-family: $font-lato;
  font-style: normal;
  font-weight: "400";
  font-size: $font-size-12;
  line-height: 14px;
  display: flex;
  align-items: center;
  letter-spacing: 0.1px;
  color: $green-color;
  margin-top: 7px;
}

.inVoledParty {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-left: 0px;
  .overideLables {
    .formLabel {
      margin-bottom: 0px;
      margin-right: 20px;
    }
  }
}
.assetName {
  height: 44px;
  background: $white-color;
  color: $cerulean-color;
  border: 1px solid $cerulean-color;
  border-radius: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  padding: 5px 10px;
  width: fit-content;
  text-transform: capitalize;
}
