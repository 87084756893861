@import "../../../scss/variables";
.newCards {
  .blueCardBody {
    font-family: $font-lato;
    font-style: normal;
    font-weight: 600;
    font-size: $font-size-14;
    color: $text-black-color;
    display: flex;
    cursor: pointer;
    flex-wrap: wrap;

    .defaultCard {
      background: $white-color;
      border: 1px solid $border-grey;
      border-radius: 100px;
      padding: 11px 14px;
      margin-right: 10px;
      margin-bottom: 10px;
    }

    .selectedCard {
      background: $cerulean-color;
      border: 1px solid $border-grey;
      border-radius: 100px;
      padding: 11px 14px;
      margin-right: 10px;
      margin-bottom: 10px;
      color: $white-color;
    }
  }
}
.newCards {
  .staticDataCard {
    margin-bottom: 11px;
    background: transparent;
    color: #000;
    padding: 8px 20px;
    border-radius: 40px;
    display: flex;
    margin-right: 12px;
    align-items: center;
    justify-content: center;
    border: 2px solid $border-grey;
    .staticCardIcon {
      margin-right: 9px;
    }
  }
}

.newCards {
  .borderGreen {
    border: 3px solid #1b436e;
    .staticCardIcon {
      margin-right: 9px;
    }
  }
}
