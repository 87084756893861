/* StaticTable.css */
.rainfallTable {
  table {
    width: 100%;
    border-collapse: collapse;
    height: 190px;
  }

  th,
  td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }

  th {
    background: #e4f0f5;
    color: #333;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: left;
    vertical-align: left;
    padding: 10px;
  }

  td {
    //   /background-color: #f2f2f2;
    text-align: left;
    font-size: 14px;
    font-family: Lato;
    color: #333;
    font-style: normal;
    line-height: normal;
  }
  .header-rainfall {
    background: #e4f0f5;
    width: 618px;
    height: 38px;
    flex-shrink: 0;
  }
}

.LineChart {
  .lineChart {
    margin-left: -22px;
  }
}
