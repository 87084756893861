@import "../../../scss/variables";

.Observationsubmit {
  .overideBtn {
    text-align: end;
    .containedButton {
      max-width: 300px;
      width: 100%;
      font-family: $font-lato;
      font-weight: 600;
    }
  }
}
.obserButton {
  font-family: $font-lato;
  font-weight: 600;
}

.overidePersonal {
  .addShadowBox {
    .radioInline {
      width: 100%;
      display: flex;
      flex-direction: row;

      .overideLables {
        margin-top: 10px;
      }
      .MuiFormGroup-root {
        margin-left: 30px;
      }
    }

    .overideAction {
      .actionContainer {
        width: 148%;
      }
    }
  }
}

.overidePersonal {
  @media (max-width: 896px) {
    .addShadowBox {
      .overideAction {
        .actionContainer {
          width: 100%;
        }
      }
    }
  }
  @media (max-width: 768px) {
    .addShadowBox {
      .overideAction {
        .actionContainer {
          width: 100%;
        }
      }
    }
  }

  // @media (min-width: 360px) and (max-width: 740px) {
  //   .addShadowBox {
  //     .overideAction {
  //       .actionContainer {
  //         width: 105%;
  //         margin-left: -9px;
  //       }
  //     }
  //   }
  // }
  @media (max-width: 430px) {
    .addShadowBox {
      .overideAction {
        .btnColor {
          width: 300px;
          word-break: break-all;
        }
      }
    }
  }
}
