@import "../../../scss/variables";
body {
  &.dark-mode {
    .overideSearchAuto {
      .formControlRoot {
        background-color: $dark-form-all-input-color;
        color: $dark-secondary-color;
        .Mui-disabled {
          background-color: $dark-tertiary-background-color;
        }
      }
      .MuiAutocomplete-option {
        &:hover {
          background-color: $dark-primary-color;
        }
      }
      .MuiAutocomplete-noOptions {
        color: $dark-primary-text-color;
      }
      .MuiAutocomplete-loading {
        color: $dark-primary-text-color;
      }
    }
  }
  .overideSearchAuto {
    .MuiAutocomplete-popper {
      text-align: left;
    }
    .formControlRoot {
      background: $white-color;
      border-radius: 15px;
      font-family: $font-lato;
      font-style: normal;
      font-weight: 400;
      font-size: $font-size-14;
      color: $manatee-color;
      margin-bottom: 0px;
      min-height: 44px;

      .MuiOutlinedInput-root {
        padding: 3px;
        font-family: $font-lato;

        .MuiAutocomplete-input {
          text-transform: capitalize;
        }
      }
      .Mui-disabled {
        background-color: $fog-grey;
      }
      .muioutlinedinput-notchedoutline {
        border-radius: 15px;
        padding: 3px 15px;
      }
      .MuiInputBase-root {
        min-height: 44px;
        border-radius: 16px;
      }
    }
    .inputroot {
      border-radius: 16px;
      padding: 8px 15px;
    }
    .Mui-error {
      margin-left: 0px;
      .MuiOutlinedInput-notchedOutline {
        border-color: $border-grey !important;
      }
    }
  }
}
