@import "../../../scss/variables";

body {
  &.dark-mode {
    .backBtn {
      .backTitle {
        color: $dark-primary-text-color;
      }

      svg {
        path {
          stroke: $dark-primary-text-color;
        }
      }
    }
  }
}

.backBtn {
  margin: 30px 0px;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  width: max-content;
  .backImg {
    line-height: 1;
  }
  .backTitle {
    font-family: $font-lato;
    font-size: $font-size-20;
    color: $shadow-grey;
    font-weight: 700;
    padding-left: 5px;
  }
}

.sw {
  .insBackBtn {
    .backBtn {
      margin: 10px 0px;
    }
  }
}
.swprojectback {
  .backBtn {
    margin: 9px 0px;
  }
}
