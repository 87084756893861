@import "../../scss/variables";

.maincon {
  display: flex;
  justify-content: center;
  .qrcodeLanding {
    max-width: 700px;
    .cardListMain {
      display: flex;
      justify-content: center;
      .CardListBox {
        width: 100%;
      }
    }
  }
  .CardListContanerLanding {
    display: flex;
    justify-content: center;
    gap: 25px;
    .CardListBox {
      max-width: 250px;
      width: 100%;
      background-color: $white-color;
      box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.1);
      border-radius: 5px;
      margin: auto;
      padding: 25px 14px 5px 14px;
      cursor: pointer;
      //margin-bottom: 40px;
      min-height: 250px;
      .CardListImg {
        text-align: center;
        min-height: 170px;
        align-items: center;
        justify-content: center;
        display: flex;
      }
      .cardListTitle {
        font-size: $font-size-22;
        font-weight: 700;
        text-align: center;
        font-family: $font-lato;
        color: $text-black-color;
        line-height: 26px;
        margin-top: 5px;
      }
      .cardListDesc {
        margin-top: 24px;
        font-size: $font-size-14;
        font-weight: 400;
        text-align: center;
        font-family: $font-lato;
        color: $spanish-gray;
      }
    }
  }
  .desc_box {
    .row_uniq {
      margin: 16px;
    }
  }
}
