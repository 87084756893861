@import "../../../scss/variables";

body {
  &.dark-mode {
    .viewOpenItemConainer {
      .titleViewOpenItem {
        color: $dark-primary-text-color;
      }
    }
    .page-title {
      p {
        color: $dark-primary-text-color;
      }
    }
  }
}

.viewobservationreportbox {
  background: $white-color;
  box-shadow: 0px 4px 90px rgba(0, 0, 0, 0.1);
  width: 100%;
}

.page-title {
  font-family: $font-lato;
  font-weight: 700;
  font-size: $font-size-24;
  color: $text-black-color;
  display: flex;
  margin-bottom: 20px;
  margin-left: 20px;
}

.viewOpenItemConainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .DownloadButtonViewItem {
    .downloadpdf {
      background-color: $green-color;
      text-align: left;
      border-radius: 20px;
      width: 200px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 36px;
      color: $white-color;
      font-size: $font-size-14;
      font-weight: 400;
      font-family: $font-lato;
    }
    .downloadIcon {
      color: $white-color;
    }
  }
  .titleViewOpenItem {
    font-family: $font-lato;
    font-weight: 700;
    font-size: $font-size-24;
    color: $text-black-color;
  }
}
