@import "../../../src/scss/variables";

.overrideHeader1 {
  background: #eeeeee;
  justify-content: center;
  align-items: center;
  margin-bottom: 1px;
  padding: 8px;
  margin: 25px;
  border-radius: 5px;
  .seq_header_main {
    display: flex;
    justify-content: space-around;
    align-items: center;

    .header_info {
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        color: #333333;
        font-weight: 500;
        font-family: $font-lato;
      }
    }

    .logo_img img {
      width: 100%;
      height: 100%;
    }
  }
  .headerclient {
    color: #333333;
    font-weight: 500;
    font-family: $font-lato;
    height: 21px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
    gap: 5px;
  }
}

.login_message_qtcode_container {
  padding-left: 24px;
  padding-right: 24px;
}
.login_message_qrcode {
  text-align: center;
  font-size: 14px;
  color: #333333;
  background-color: #f9f9f9;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  margin: 20px 0;
}

.login-link_qrcode {
  color: $cerulean-color;
  text-decoration: none;
  font-weight: bold;
}

.login-link_qrcode:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .overrideHeader1 {
    margin: 10px;
    padding: 10px;

    .seq_header_main {
      flex-direction: column;
      gap: 10px;

      .header_info {
        flex-direction: column;
        align-items: flex-start;
      }
    }

    .headerclient {
      font-size: 14px;
      gap: 10px;
      .qrtitle {
        font-size: 14px;
        margin-bottom: 8px;
      }
    }
  }

  .login_message_qrcode {
    font-size: 12px;
    padding: 8px;
    margin: 15px 0;
  }

  .login_message_qtcode_container {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (max-width: 480px) {
  .overrideHeader1 {
    margin: 5px;
    padding: 4px;

    .headerclient {
      font-size: 12px;
      .qrtitle {
        font-size: 12px;
        margin-bottom: 6px;
      }
    }
  }

  .login_message_qrcode {
    font-size: 10px;
    padding: 6px;
    margin: 15px 0;
  }
  .login_message_qtcode_container {
    padding-left: 10px;
    padding-right: 10px;
  }
}
