.info {
  padding: 6px 8px;
  font-size: 14px;
  font-family: "lato";
  background: white;
  border-radius: 5px;
}

.legend {
  text-align: left;
  line-height: 18px;
  color: #555;
}

.bullet {
  font-size: 25px;
  margin-right: 5px;
}
