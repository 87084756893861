@import "../../../../scss/variables";

body {
  &.dark-mode {
    .lessonsList {
      .rightFilter {
        .searchfilter {
          background-color: $dark-form-all-input-color;
          color: $dark-primary-text-color;
          .searchfilterInput input {
            background-color: $dark-form-all-input-color;
            color: $dark-primary-text-color;
          }
        }
      }
    }
    .assignDivtitle {
      .assigntitle {
        .lessonnme {
          color: $dark-primary-text-color;
        }
        .lessonnmeT {
          color: $dark-primary-text-color;
        }
      }
    }
  }
}

.lessonsList {
  margin-top: 50px;
  .rightFilter {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    @media only screen and (max-width: 576px) {
      justify-content: flex-start;
    }
    .searchfilter {
      border: 1px solid $fossil-grey;
      background-color: $white-color;
      border-radius: 15px;
      max-width: 204px;
      width: 100%;
      height: 100%;
      max-height: 44px;
      label {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 100%;
        span {
          display: flex;
          padding-left: 10px;
        }
      }
      .searchfilterInput {
        height: 100%;
        input {
          border: none;
          height: 100%;
          width: 100%;
          border-radius: 15px;
          font-family: $font-lato;
          font-size: $font-size-16;
          color: $manatee-color;
          //font-weight: 600;
          &::placeholder {
            color: $manatee-color;
          }
          &:focus {
            outline: none;
          }
        }
      }
    }
    .filter_btn {
      max-width: 130px;
      width: 100%;
      height: 100%;
      max-height: 44px;
      margin-left: 20px;
      z-index: 10;
      .btn {
        border: 1px solid #dde2e5;
        background-color: #fff;
        border-radius: 15px;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $shadow-grey;
        font-size: $font-size-16;
        font-weight: 600;
        font-family: $font-lato;
        cursor: pointer;
        .filterIcons {
          color: $shadow-grey;
          margin-left: 10px;
          font-weight: 700;
          font-size: $font-size-26;
        }
      }
    }
  }
  .tabcustom {
    padding: 0px 0px 10px 0px;
    font-size: $font-size-16;
    font-family: $font-lato;
    color: $spanish-gray;
    margin-right: 37px;
    font-weight: 700;
    text-transform: capitalize;
    &.Mui-selected {
      color: $cerulean-color;
    }
    .totalV {
      background-color: $giants-orange;
      width: 22px;
      height: 22px;
      border-radius: 100%;
      color: $white-color;
      font-family: $font-lato;
      font-size: $font-size-12;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 1;
    }
  }
  .MuiTabs-indicator {
    bottom: 24px;
    background-color: $cerulean-color;
  }
  &.noIn {
    .MuiTabs-indicator {
      bottom: 16px;
    }
  }

  .tabPanel {
    width: 100%;
    margin-top: 20px;
  }
  .MuiDataGrid-columnHeaderTitle {
    font-family: $font-lato;
    font-weight: 600;
    color: $text-black-color;
  }
}

.disableclss {
  pointer-events: none;
  opacity: 0.6;
  cursor: not-allowed;
}

.assignDivtitle {
  display: flex;

  .assigntitle {
    // color: #1b436e;
    font-weight: 600;
    text-transform: capitalize;
    margin-bottom: 20px;
    width: 100%;
    margin-bottom: 2rem;
    margin-top: 1rem;
    font-size: 14px;
    line-height: 17px;
    flex-direction: column;
    align-items: center;
    color: #333333;
    margin-top: 1px;
    .lessonnme {
      font-family: "Lato";
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      color: #333333;
      margin-bottom: 1rem;
    }
    .lessonnmeT {
      color: #333333;
      font-family: "Lato";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
    }
  }
}
.morelessdetail {
  color: #1b436e;
  font-weight: 600;
  margin-left: 1px;
  cursor: pointer;
  display: flex;
  justify-content: center;
}
.lesson-alert-model {
  .overideTextArea {
    width: 100%;
  }
}

.tableData {
  .MuiDataGrid-root {
    .MuiDataGrid-cell {
      .alertbtn {
        button {
          position: relative;
          right: auto;
        }
      }
    }
  }
}
.headerWrapper .headerBox .headerRight {
  @media only screen and (max-width: 375px) {
    .overideBtn .containedButton {
      padding: 0px 10px;
    }
  }

  @media only screen and (min-width: 376px) and (max-width: 468px) {
    .overideBtn .containedButton {
      padding: 0px 17px;
    }
  }
}
.alertSet {
  display: flex;
}
