@import "../../../scss/variables";

body {
  &.dark-mode {
    .successScreenBody {
      .heading {
        color: $dark-primary-text-color;
      }
      .report {
        color: $dark-primary-text-color;
      }
      .CNU {
        color: $dark-primary-text-color;
      }
      .generated {
        color: $dark-primary-text-color;
      }
      .redirect {
        color: $dark-primary-text-color;

        span {
          color: $dark-primary-text-color;
          font-size: $font-size-14;
        }
      }
    }
  }
}
.successScreenBody {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .succesImg {
    width: 90px;
    height: 90px;
  }
  &.passed {
    max-width: 460px;
    margin: auto;
    .succesImg {
      max-width: 300px;
      max-height: 300px;
      width: 100%;
      height: 100%;
    }
    .report {
      color: $green-color;
      font-size: $font-size-24;
      font-family: $font-lato;
      font-weight: 700;
      margin: 10px 0px;
    }
    .generated {
      color: $text-black-color;
      font-size: $font-size-24;
      font-family: $font-lato;
      font-weight: 600;
    }
  }
  &.failed {
    max-width: 460px;
    margin: auto;
    .succesImg {
      max-width: 300px;
      max-height: 300px;
      width: 100%;
      height: 100%;
    }
    .report {
      color: $nobg;
      font-size: $font-size-24;
      font-family: $font-lato;
      font-weight: 700;
      margin: 10px 0px;
    }
    .generated {
      color: $text-black-color;
      font-size: $font-size-24;
      font-family: $font-lato;
      font-weight: 600;
    }
  }
  .heading {
    color: $text-black-color;
    font-size: $font-size-24;
    font-family: $font-lato;
    font-weight: 700;
    line-height: 28px;
    margin: 30px 0px 45px 0px;
  }
  .report {
    color: $shadow-grey;
    font-size: $font-size-14;
    font-weight: 700;
  }
  .CNU {
    color: $black-color;
    font-size: $font-size-16;
    font-weight: 700;
  }
  .generated {
    color: $shadow-grey;
    font-size: $font-size-14;
    font-weight: 700;
  }
  .redirect {
    color: $spanish-gray;
    font-size: $font-size-14;
    font-weight: 400;
    margin-top: 26px;
    span {
      color: $text-black-color;
      font-size: $font-size-14;
    }
  }
}

body {
  &.dark-mode {
    @each $class in report, CNU, generated, redirect {
      .#{$class} {
        color: $white-color;
        @if $class == redirect {
          span {
            color: $border-grey;
          }
        }
      }
    }
  }
}
