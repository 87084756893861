@import "../../scss/variables";

.change-password {
  max-width: 100vw;
  min-height: 100vh;
  overflow-x: hidden;
  background-color: $white-color;
  .validationPopup {
    box-shadow: rgb(204 204 204) 2px 2px 3px 3px;
    border: 2px rgb(238, 238, 238);
    padding: 20px;
    margin-top: -50px;
    margin-right: -415px;
    position: absolute;
    margin-left: 450px;
  }
  &-container {
    margin: 20px;
    margin-top: 50px;
    @media screen and (min-width: 768px) {
      display: flex;
      padding-top: 5%;
      margin-top: 0px;
      justify-content: space-between;
    }
  }
  &-left {
    @media screen and (min-width: 768px) {
      max-width: 50%;
      margin-left: 4%;
      display: flex;
      flex-direction: column;
    }

    .logo {
      width: 170px;
    }
    .logoDark {
      display: none;
      text-align: left;
    }
    .logoLight {
      display: block;
      text-align: left;
    }
    .reset-password-title {
      font-weight: 600;
      font-size: $font-size-30;
      line-height: 36px;
      color: $text-black-color;
      margin-top: 20px;

      @media screen and (min-width: 768px) {
        display: flex;
      }
      .form-wrapper {
        .password-container {
          h1 {
            background-color: $white-color;
          }
          p {
            margin-bottom: 10px;
          }
        }
        .input-control {
          background-color: $white-color;
          border: 1px solid $border-grey;
          &.border-red {
            border: 1px solid $red-color;
          }
        }
        .showHidePassword {
          filter: initial;
          width: 20px;
          height: 20px;
        }
      }
    }
  }

  &-right {
    display: none;
    @media screen and (min-width: 768px) {
      display: flex;
      justify-content: flex-end;
      margin-left: auto;
      margin-right: 10%;
    }
  }

  &-button {
    margin-top: 20px;
  }
}

.forgot-password {
  font-style: normal;
  font-weight: 600;
  font-size: $font-size-14;
  line-height: 20px;
  text-align: right;
  color: $cerulean-color;
  margin-top: 20px;
  cursor: pointer;
}

.valid {
  color: $ocean-green;
  padding: 7px;
  display: flex;
  align-items: center;
  text-align: left;
}
.invalid {
  color: $shadow-grey;
  padding: 7px;
  display: flex;
  align-items: center;
  text-align: left;
}
.checkbox {
  font-size: 20px;
  margin-right: 5px;
}

.form-wrapper {
  .password-container {
    p {
      margin-bottom: 10px;
    }
  }
}
.showHidePassword {
  width: 20px;
  height: 20px;
}

// body {
//   &.dark-mode {
//     .forgot-password{
//       color: $dark-primary-color;
//     }
//   }
// }

body {
  &.dark-mode {
    .change-password {
      background-color: $dark-tertiary-background-color;
      .validationPopup {
        background-color: #1f1f1f;
      }
    }
    // .verify-mobile-container {
    //   .verify-mobile-left {
    //     .title {
    //       color: $light-primary-background-color;
    //     }
    //     p {
    //       color: $light-primary-background-color;
    //     }
    //   }
    // }
    // .form-wrapper {
    //   h1 {
    //     color: $light-primary-background-color;
    //   }
    // }
    // .form-wrapper {
    //   .input-control {
    //     background-color: $dark-form-all-input-color;
    //     border: 1px solid $dark-border;
    //     color: $dark-primary-text-color;
    //   }
    // }
    // .verify-mobile-button {
    //   .button {
    //     background-color: $dark-primary-color;
    //     color: $dark-primary-text-color;
    //     &:hover {
    //       background-color: $dark-secondary-background-color;
    //       color: $dark-primary-text-color;
    //     }
    //   }
    // }
  }
}
