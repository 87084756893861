@import "../../scss//variables";
body {
  &.dark-mode {
    .topHeader {
      .welcomeUser {
        color: $dark-primary-text-color;
      }
    }
  }
  .topHeader {
    padding: 10px 41px;
    .welcomeUser {
      font-family: $font-lato;
      font-style: normal;
      font-weight: 700;
      font-size: $font-size-24;
      line-height: 29px;
      color: $text-black-color;
      padding-left: 12px;
      text-align: left;
      @media (max-width: 1180px) {
        padding-left: 0px;
      }
      @media (max-width: 767px) {
        font-size: $font-size-18;
        margin-bottom: 0px;
      }
    }
  }
}
.header-over {
  .header-box {
    background: transparent;
    color: $black-color;
    box-shadow: none;
    padding: 10px 0px;
    margin-bottom: 20px;

    @media (max-width: 1180px) {
      margin-bottom: 0px;
    }
    @media (max-width: 767px) {
      padding: 0px;
      margin-bottom: 0px;
    }
    .header-icon {
      font-family: monospace;
      font-weight: 700;
      letter-spacing: 0.3rem;
      color: inherit;
      text-decoration: none;
    }
    .pages-title {
      color: $black-color;
      margin-top: 0px;
      margin-bottom: 0px;
    }
    .light-logo {
      display: block;
    }
    .dark-logo {
      display: none;
    }
  }
}

.ClientSearchBox {
  min-width: 280px;
}

.NotificationStyles {
  cursor: pointer;
}
.notification-badge {
  position: relative;
  display: inline-block;
}

.notification-badge img {
  width: 30px;
}

.badge {
  position: absolute;
  top: -1px;
  right: -10px;
  background-color: #1b436e;
  color: white;
  border-radius: 50%;
  padding: 5px;
  font-size: 9px;
  min-width: 24px;
  text-align: center;
}
.menuIconMobile {
  display: none;
  @media (max-width: 1180px) {
    display: block;
  }
}
.topHeaderBox {
  justify-content: space-between;
  @media (max-width: 1180px) {
    justify-content: space-between;
    padding: 0px 10px;
  }
  @media (max-width: 1023px) {
    padding: 0px 15px 0px 0px;
    justify-content: space-between;
  }
  @media (max-width: 767px) {
    padding: 0px 15px 0px 0px;
  }
  align-items: center;
  .rightBox {
    text-align: right;
    .iconRightBox {
      text-align: right;
    }
  }
}

.islanding-top-headerBox {
  padding: 0px 20px;
}

@media (max-width: 1024px) {
  .islanding-top-headerBox {
    display: flex;
    .menuIconMobile {
      display: none;
    }
    .leftBox {
      &.WelcomeUserText {
        margin-right: 230px;
      }
    }
    .ProfileImageIcon {
      display: flex;
      align-items: flex-end;
      margin-left: 861px;
      margin-top: -68px;
    }

    .light-logo {
      &.logoResponsive {
        width: 160px;
      }
    }
  }
}

.profileDropdown {
  li {
    @media (max-width: 767px) {
      min-height: 20px;
      padding: 2px 20px;
    }
  }
}

.searchAutoMobile {
  .overideSearchAuto {
    .formControlRoot {
      @media (max-width: 1180px) {
        position: absolute;
        right: 30px;
        width: 200px;
      }
      @media (max-width: 767px) {
        position: absolute;
        right: 20px;
        width: 50px;
        input {
          &::placeholder {
            color: $white-color;
          }
        }
      }
    }
    .Mui-focused {
      .formControlRoot {
        @media (max-width: 767px) {
          width: 200px;
          z-index: 99;
        }
      }
    }
  }
}
