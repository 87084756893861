@import "../../../scss/variables";
.newQuestionOver {
  .newQuestionContainer {
    border: 1px solid $gainsboro;
    border-radius: 15px;
    padding: 25px 50px 25px 25px;
    margin-bottom: 20px;
  }
  .cancelQuestion {
    text-align: right;
  }
  .questionDrop {
    .dropbox {
      text-align: center;
    }
  }
  .answerBox {
    ul {
      counter-reset: listStyle;
      li {
        position: relative;
        padding-left: 40px;
        counter-increment: listStyle;
        &::before {
          margin-right: 1em;
          content: counter(listStyle, lower-alpha);
          width: 46px;
          height: 46px;
          background-color: $light-silver;
          position: absolute;
          left: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: $font-size-16;
          font-family: $font-lato;
          font-weight: 700;
          color: $text-black-color;
          text-transform: uppercase;
        }
        .answerInput {
          width: 50%;
        
          .overideInputField {
            .formControlRoot {
              border-radius: 0px;
            }
          }
        }
        
        .answerList {
          display: flex;
          width: 100%;
          margin-bottom: 15px;
          .correctanswer {
            border: 1px solid $manatee-color;
            color: $manatee-color;
            height: 46px;
            padding: 0px 25px;
            border-left: none;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            font-family: $font-lato;
            &.selected {
              background-color: $green-color;
              border-color: $green-color;
              color: $white-color;
            }
          }
          .clear {
            background-color: $light-silver;
            width: 46px;
            height: 46px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: $font-size-18;
            font-family: $font-lato;
            font-weight: 400;
            color: $shadow-grey;
            text-transform: uppercase;
            cursor: pointer;
          }
        }
      }
    }
    .correctError {
      font-size: $font-size-14;
      color: $red-color;
      font-family: $font-lato;
    }
  }
}
