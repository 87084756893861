@import "../../../../scss/variables";

body {
  &.dark-mode {
    .overideToolbox {
      .title {
        color: $dark-primary-text-color;
      }
    }
  }
}

.overideToolbox {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  .formContainer {
    display: flex;
    width: 95%;
    flex-direction: column;
    font-family: Lato;
  }
  .title {
    text-align: left;
    font-weight: 700;
    font-size: 22px;
    color: #333333;
  }
  .addtoolBoxContainer {
    display: flex;
    width: 100%;
    justify-content: left;
    align-items: left;
  }
  .addtoolBox {
    padding: 20x;
    min-width: 700px;
    max-width: 700px;
  }
  .addtoolboxButton {
    position: absolute;
    margin-left: 520px;
    width: 176px;
    height: 40px;
    text-transform: lowercase;
  }
  .addcourseSaveDraft {
    position: absolute;
    margin-left: 400px;
    text-transform: lowercase;
  }
}
