@import "../../../scss//variables";

body {
  &.dark-mode {
    .cardOverride {
      .iconShow {
        .light {
          display: none;
        }
        .dark {
          margin: auto;
          display: block;
          width: 60px;
          height: 60px;
        }
      }
      .card {
        background-color: $dark-secondary-background-color;
      }
      .category {
        color: $dark-primary-text-color;
      }
      .details {
        color: $dark-primary-text-color;
      }
    }
    .overideSupportLandingPage {
      .icon {
        color: $dark-primary-color;
      }
    }
  }
}

.cardOverride {
  max-width: 265px;
  background: $white-color;
  box-shadow: 0px 4px 100px rgba(0, 0, 0, 0.07);
  border-radius: 8px;
  .iconShow {
    .light {
      display: block;
      margin: auto;
      width: 60px;
      height: 60px;
    }
    .dark {
      display: none;
    }
  }
  .card {
    margin: 0;
    overflow: hidden;
    height: 100%;
    width: fit-content;
    transition: none;
    box-shadow: none;
    border: none;
    min-width: 270px;
  }
  .category {
    font-family: $font-lato;
    font-size: $font-size-22;
    font-weight: 700;
    color: $text-black-color;
  }
  .details {
    font-family: $font-lato;
    font-size: $font-size-14;
    font-weight: 400;
    color: $spanish-gray;
  }
  .details2 {
    font-family: $font-lato;
    font-size: $font-size-14;
    font-weight: 400;
    color: $spanish-gray;
    margin-top: 0px;
  }
}
