.gridMainpermit {
  margin: 24px;
  @media (max-width: 480px) {
    margin-left: 10px;
    margin-right: 10px;
  }
  @media (max-width: 768px) {
    margin-left: 10px;
    margin-right: 10px;
  }
  .table_style_ui {
    height: 500px;
  }
}

.seqPermitDetail {
  padding: 27px;
}
