@import "../../../scss/variables";

.oshaRecordkeepingPdff{
    .oshaRecordkeepingPdff__header{
        .oshaRecordkeepingPdff__Coloumn{
            font-size: $font-size-16;
            font-family: $font-lato;
            margin-bottom: 10px;
            color: $text-black-color;
            strong{
                font-weight: 600;
            }
        }
    }
}
.oshapdfshow{
    display: none;
}