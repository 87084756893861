.overrideSupportContainer {
  .supportMainContainer {
    width: 100%;
    display: flex;
    .rightBarr {
      display: flex;
      flex-direction: column;
      width: 100%;
      .sideBar {
        height: 100vh;
      }
    }
  }
}
