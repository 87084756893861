@import "../variables";

body {
  &.dark-mode {
    .overideView {
      .viewBox {
        background-color: $dark-primary-background-color;
        .incedentTitle {
          color: $dark-primary-text-color;
        }
      }
    }
  }
}

.overideView {
  .viewBox {
    width: 100%;
    background: $cultured;
    box-shadow: 0px 4px 90px rgba(0, 0, 0, 0.1);
    .incedentTitle {
      display: flex;
      font-weight: 700;
      font-size: $font-size-24;
      color: $text-black-color;
      font-family: $font-lato;
    }
  }
  .downloadpdf {
    background-color: $green-color;
    text-align: left;
    border-radius: 20px;
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 36px;
    color: $white-color;
    font-size: $font-size-14;
    font-weight: 400;
    margin-top: 15px;
    margin-bottom: 0px;
  }
  .downloadIcon {
    color: $white-color;
  }
}

@media print {
  @page {
    margin-top: 20px;
  }
  .incedentTitle {
    font-weight: 700;
    font-size: $font-size-20;
  }
  .commonOveride {
    .commoninfoWrap {
      margin-top: 70px;
    }
  }
  .downloadpdf {
    display: none;
  }
  .ViewIncHeaderOver {
    .downloadpdf {
      display: none;
    }
    .viewIncBox {
      padding: 0px;
    }
  }
  .bodyPdf {
    padding: 0px 20px 20px 20px;
  }
  .commonOveride {
    .commoninfoWrap {
      .commoninfoBox {
        .commoninfoleft {
          .pdfImgBox {
            margin-top: 130px;
          }
        }
      }
    }
  }
}
