@import "../../../scss/variables";

body {
  &.dark-mode {
    .InspectionContainer {
      background-color: $dark-primary-background-color;
      .title {
        color: $dark-primary-text-color;
      }
    }
  }
}

.InspectionContainer {
  margin-top: 90px;
  background: #f5f5f5;
  box-shadow: 0px 4px 90px rgba(0, 0, 0, 0.1);
  .title {
    color: $text-black-color;
    font-size: $font-size-24;
    font-family: $font-lato;
    font-weight: 700;
    margin-bottom: 30px;
  }

  .subCateView {
    background: $white-color;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    padding: 12px 25px 16px 25px;
    text-align: left;
  }
  .posBox {
    .subCateView {
      margin-top: 20px;
      &:first-child {
        margin-top: 0px;
      }
    }
  }
}

.basicOveride {
  .basicinfoWrap {
    .basicinfoBox {
      .basicinfoleft {
        .title {
          &.report-id {
            display: none;
          }
        }
      }
    }
  }
}

@media print {
  .overRideSaftyPdf {
    &.InspectionContainer {
      margin-top: 0px;
      .title {
        &.repotPdfHeading {
          display: none;
        }
      }
      .clientPdfDetail {
        .clientName,
        .clientLogo {
          display: none;
        }
      }
      .overReportHeader {
        display: none;
      }
      .categoriesView {
        .categoriesTitle {
          width: 100%;
        }
        .subCategoriesTitle {
          width: 100%;
        }
        .viewImageBox {
          .viewList.small {
            .listItem {
              width: 230px;
              height: 230px;
              img {
                width: 200px;
                height: 200px;
              }
            }
          }
        }
      }
    }
  }
}
