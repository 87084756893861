@import "../../../../scss/variables";

body {
  &.dark-mode {
    .heading_style {
      color: $dark-primary-text-color;
    }
    .pocHeading {
      color: $dark-primary-text-color;
    }
    .topBlockBody {
      .contractorName {
        .name,
        .head {
          color: $white-color;
        }
      }
    }
  }
}
.addmultiuser_btn {
  display: flex;
  justify-content: flex-end;
}

.heading_style {
  color: #333333;
  font-weight: 700;
  font-size: 22px;
  margin-top: 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.adduser_btn {
  display: flex;
  justify-content: flex-end;
}

.cancle_heading_style {
  color: #1b436e;
  font-weight: 600;
  margin-right: 50px;
  margin-top: 12px;
  cursor: pointer;
}
.errorMsg {
  color: red;
}
.pocHeading {
  border-top: 1px solid $border-grey;
  margin-top: 25px;
  font-weight: 600;
  padding-top: 10px;
  font-size: $font-size-22;
}
.topBlockBody {
  display: flex;
  justify-content: space-between;
  margin-bottom: 31px;
}
.head {
  font-weight: 600px;
}
.name {
  font-weight: 600;
  font-size: 17px;
}
