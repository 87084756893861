@import "../../../scss/variables";

.noData {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: $font-size-24;
  font-weight: 600;
  color: $light-primary-color;
  height: 100%;
}

.chart-container canvas {
  width: 100% !important;
  height: 100% !important;
}

body {
  &.dark-mode {
    .noData {
      color: $dark-primary-color;
    }
  }
}
