.overideFileUploadInput {
  .inputContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f2f2f2;
    border-radius: 40px 40px 0px 40px;
    padding: 5px 0px 5px 20px;
    margin-bottom: 5px;
    font-family: Lato;
    .textInput {
      font-weight: 400;
      font-size: 16px;
      display: flex;
      align-items: center;
      color: #333333;
      background: none;
      outline: none;
      border: none;
    }
    .btnColor{
        color: #999999;
    }
  }
}



body {
  &.dark-mode {
    .overideFileUploadInput {
      .inputContainer {
        background: #34323a;
        .textInput {
          color: #fff;
        }
        .btnColor{
            color: #004ddd;
        }
      }
    }
    
  }
}
