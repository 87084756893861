@import "../../../scss//variables";

body {
  &.dark-mode {
    .searchcaseid {
      label {
        div {
          input {
            background-color: $dark-secondary-background-color;
            color: $dark-primary-text-color;
          }
          input:focus {
            color: $dark-primary-text-color;
          }
        }
      }
    }
  }
}

.searchcaseid {
  position: relative;
  width: 100%;
  font-family: $font-lato;

  label {
    span {
      position: absolute;
      top: 10px;
      left: 15px;
      font-family: $font-lato;
      svg {
        font-size: $font-size-30;
      }
    }
    div {
      input {
        width: 100%;
        max-height: 44px;
        padding: 15px 15px 15px 50px;
        font-size: $font-size-16;
        border: 1px solid $fossil-grey;
        border-radius: 15px;
        outline: none;
        font-family: $font-lato;
      }
      input::placeholder {
        font-weight: 400;
        color: $manatee-color;
        font-size: $font-size-16;
      }
      input:active,
      input:focus {
        color: #828996;
        font-family: $font-lato;
      }
    }
  }
}
