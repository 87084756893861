@import "../../../scss/variables";

body {
  &.dark-mode {
    .overrideAdminFaqPage {
      .faqcontainer {
        background-color: $dark-primary-background-color;
      }
      .faqIcon {
        color: $dark-primary-color;
      }
    }
  }
}

.overrideAdminFaqPage {
  background: $white-color;
  .faqMainContainer {
    display: flex;
    justify-content: center;
    width: 100%;
    // height: 100vh;
    .sideBar {
      height: 100vh;
      background: $white-color;
    }
    .rightDiv {
      width: 100%;
    }
  }
  .faqcontainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: auto;
  }
  .cardsContainer {
    display: flex;
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;
    margin: 10px 20px;
    gap: 50px;
    cursor: pointer;
  }
  .faqIcon {
    width: 60px;
    height: 60px;
    color: $cerulean-color;
  }
}
