@import "../../../../scss/variables";

body {
  &.dark-mode {
    .courseView {
      .lessonwithDetail {
        .lessonListBox {
          .lessonList {
            color: $dark-primary-text-color;
          }
        }
      }
      .title {
        color: $dark-primary-text-color;
      }
      .otherDescration {
        .subTitle {
          color: $dark-primary-text-color;
        }
        .desc {
          color: $dark-primary-text-color;
        }
      }
      .imageDetail {
        .imageDetailContent {
          color: $dark-primary-text-color;
          span {
            svg {
              width: 19px;
              height: 15px;
              path {
                fill: $dark-primary-text-color;
              }
            }
          }

          .count {
            color: $dark-primary-text-color;
          }
        }
      }
    }
  }
}

.courseView {
  border: 1px solid $gainsboro;
  border-radius: 8px;
  padding: 20px;
  .courseImage {
    img {
      border-radius: 10px;
      width: 100%;
      max-height: 500px;
      object-fit: cover;
    }
    .videoLink {
      border-radius: 10px;
      width: 100%;
      max-height: 500px;
      height: 500px;
    }
  }
  .imageDetail {
    margin-top: 25px;
    margin-bottom: 30px;
    display: flex;
    justify-content: flex-start;
    .imageDetailContent {
      margin-right: 40px;
      font-family: $font-lato;
      font-size: $font-size-14;
      color: $spanish-gray;
      font-weight: 600;
      @media screen and (max-width: 410px) {
        margin-right: 25px;
      }
      span {
        margin-right: 5px;
      }
      .count {
        font-family: $font-lato;
        font-size: $font-size-14;
        color: $text-black-color;
        font-weight: 600;
      }
    }
  }
  .title {
    font-size: $font-size-28;
    color: $text-black-color;
    font-weight: 700;
    font-family: $font-lato;
    text-align: left;
  }

  .otherDescration {
    margin-top: 30px;
    .subTitle {
      font-size: $font-size-20;
      color: $text-black-color;
      font-weight: 700;
      font-family: $font-lato;
      text-align: left;
      margin-bottom: 15px;
    }
    .desc {
      font-size: $font-size-16;
      color: $text-black-color;
      font-weight: 400;
      font-family: $font-lato;
      text-align: left;
      margin-bottom: 15px;
      line-height: 30px;
    }
    .pdfAttached {
      text-align: left;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .pdfAttachedName {
        font-size: $font-size-14;
        color: $text-black-color;
        font-weight: 600;
        font-family: $font-lato;
        padding-left: 5px;
      }
    }
  }
  .lessonwithDetail {
    .lessonListBox {
      .lessonList {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 40px;
        min-height: 40px;
        border: 1px solid $gainsboro;
        border-radius: 4px;
        padding: 0px 10px;
        font-size: $font-size-14;
        color: $text-black-color;
        font-weight: 600;
        font-family: $font-lato;
        margin-bottom: 10px;
      }
    }
  }
  .lessonwithDetailBox {
    padding-left: 10px;
    .lessonView {
      padding: 0px;
      border: none;
    }
    .takeQuiz {
      text-align: left;
      margin-top: 30px;
      .containedButton {
        max-width: 140px;
        padding: 0px 20px;
      }
    }
    .checkingBox {
      margin-top: 40px;
      text-align: left;
      .MuiFormControlLabel-root {
        align-items: flex-start;
        .MuiCheckbox-root {
          padding-top: 0px;
        }
        .MuiFormControlLabel-label {
          font-size: $font-size-14;
          color: $spanish-gray;
          font-weight: 400;
          font-family: $font-lato;
        }
      }
    }
    .Btncomplete {
      text-align: left;
      margin-top: 30px;
      .containedButton {
        max-width: 300px;
        padding: 0px 20px;
      }
    }
  }
}
.assignBox {
  //background-color: $cerulean-color;
  padding: 5px 20px;
  max-width: 110px;
  margin: 20px 0 0 auto;
  border-radius: 8px;
  margin-right: 1rem;
  // .assignBoxBtn { TODO
  //   color: $white-color;
  //   font-size: $font-size-14;
  //   font-weight: 600;
  //   font-family: $font-lato;
  //   cursor: pointer;
  //   .assignImg {
  //     padding-right: 10px;
  //     margin-top: 2px;
  //   }
  // }
}
.searchauto {
  z-index: 1000 !important;
  margin-top: 10px;
}
.innerauto .overideSearchAuto {
  margin-top: 10px;
}
.quizstatuspass {
  color: green;
  margin-top: 100px;
  margin-left: 70px;
}
.quizstatusfail {
  color: red;
  margin-top: 100px;
  margin-left: 70px;
}
.quizstartDiv {
  margin-top: 10px;
}
.individualbtn {
  display: flex;
  flex-direction: row;
  justify-content: end;
}
