@import "../../../../scss/variables";
.singleColumn {
  &.table_style_ui
    .MuiDataGrid-virtualScrollerRenderZone
    .MuiDataGrid-row
    .MuiDataGrid-cell:last-child {
    justify-content: flex-start;
  }
  &.table_style_ui
    .overrideDataGrid
    .MuiDataGrid-columnHeaders
    .MuiDataGrid-columnHeadersInner
    .MuiDataGrid-columnHeader:last-child
    .MuiDataGrid-columnHeaderDraggableContainer
    .MuiDataGrid-columnHeaderTitleContainer {
    justify-content: flex-start;
  }
}

body {
  &.dark-mode {
    .statementStyleContainer {
      .statementDescription {
        color: $white-color;
      }
    }
  }
}
