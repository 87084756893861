@import "../../scss/variables";

.button {
  width: 310px;
  border: 0;
  cursor: pointer;
  overflow: visible;
  font-weight: 600;
  font-size: $font-size-14;
  line-height: 44px;
  height: 44px;
  background-color: $cerulean-color;
  font-family: $font-lato;
  transition: 400ms;
  position: relative;
  border-radius: 30px;
  color: $white-color;
  &:hover {
    text-decoration: none;
    background-color: #1565c0;
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
      0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
  }

  &.no-shadow {
    border: 3px solid $black-color;
    box-shadow: 0px 0px 0px 0px transparent;
  }
}
