@import "../../../../scss/variables";

body {
  &.dark-mode {
    .overrideadmincategory {
      .createFaqh1 {
        color: $dark-primary-text-color;
      }
      .tableHead {
        .tableHeader {
          background-color: $black-color;
        }
        .tableHeadercell {
          color: $white-color;
        }
      }
    }
    .overideBtn {
      .containedButtonExport {
        color: $white-color;
      }
    }
  }
}

.overrideadmincategory {
  .createFaqh1 {
    text-align: left;
    font-family: $font-lato;
    font-style: normal;
    font-weight: 700;
    font-size: $font-size-24;
    color: $text-black-color;
  }
}

.Button_style1 {
  width: 112px;
}
.Catoption .MuiInputBase-inputSizeSmall {
  font-family: $font-lato;
}
