@import "../../../../scss/variables";

.overideNews {
  .addnewsContainer {
    max-width: 700px;
  }
  .addnews_btn {
    display: flex;
    justify-content: flex-end;
  }
  .newsbutton {
    justify-content: flex-end;
  }
}
