@import "../../../../scss/variables";
.viewActionOver {
  margin-top: 40px;

  .viewActionBox {
    background: $white-color;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    padding: 12px 25px 16px 25px;
    text-align: left;
    .actiontitle {
      font-size: $font-size-20;
      color: $cerulean-color;
      font-weight: 700;
      font-family: $font-lato;
      margin-bottom: 23px;
    }
    .actionListBox {
      .actionList {
        &::first-child {
          margin-top: 0px;
        }
      }

      .actionList {
        margin-top: 40px;
        &:nth-child(1) {
          margin-top: 0px;
        }
        .actionListTop {
          max-width: 660px;
          .title {
            font-size: $font-size-16;
            color: $cerulean-color;
            font-weight: 700;
            font-family: $font-lato;
            margin-bottom: 8px;
          }
          .desc {
            font-size: $font-size-16;
            color: $text-black-color;
            font-weight: 400;
            font-family: $font-lato;
            margin-bottom: 25px;
          }
        }
        .actionListBottom {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          .assignName {
            font-size: $font-size-16;
            color: $text-black-color;
            font-weight: 700;
            font-family: $font-lato;
            span {
              color: $cerulean-color;
              margin-left: 5px;
              font-size: $font-size-16;
              font-weight: 700;
              font-family: $font-lato;
            }
          }
          .assignMedia {
            .mediaTtitle {
              color: $text-black-color;
              font-size: $font-size-16;
              font-weight: 700;
              font-family: $font-lato;
              margin-bottom: 12px;
            }
          }
          .acList {
            width: 50%;
            @media (max-width: 767px) {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
body {
  &.dark-mode {
    .viewActionOver {
      .viewActionBox {
        background: $dark-secondary-background-color;
        .actiontitle {
          color: $dark-primary-color;
        }
        .actionListBox {
          .actionList {
            .actionListTop {
              .title {
                color: $dark-primary-color;
              }
              .desc {
                color: $dark-primary-text-color;
              }
            }
            .actionListBottom {
              .assignName {
                color: $dark-primary-text-color;
                span {
                  color: $dark-primary-color;
                }
              }
              .assignMedia {
                .mediaTtitle {
                  color: $dark-primary-text-color;
                }
              }
            }
          }
        }
      }
    }
  }
}
