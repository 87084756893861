@import "../../scss/variables";

body {
  &.dark-mode {
    .multipleIconContainer {
      .multipleIconImg {
        svg {
          path {
            fill: $dark-primary-text-color;
          }
        }
      }
    }
  }
}
.overideFormDropBox {
  .loaderDropBox {
    span {
      color: $cerulean-color;
    }
  }
  .dropBoxTitle {
    color: $shadow-grey;
    font-size: $font-size-16;
    font-weight: 600;
    text-align: left;
    margin-bottom: 12px;
  }
  .dropBoxError {
    color: red;
    font-size: $font-size-16;
    font-weight: 600;
    text-align: left;
  }
  .dropbox {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: $font-lato;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 10px;
    font-size: $font-size-16;
    color: $shadow-grey;
    border: 1.5px dashed $gainsboro;
    border-radius: 15px;
    height: 100px;
    text-align: center;
    cursor: pointer;
  }
  .dropText {
    color: $text-black-color;
    font-size: $font-size-16;
  }
  .browseText {
    color: $text-black-color;
    font-size: $font-size-16;
    span {
      color: $cerulean-color;
      text-decoration: none;
      font-size: $font-size-16;
      font-weight: 700;
    }
  }
  .browse-file-message {
    font-size: small;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .clousIcon {
    color: #999999;
    width: 50px;
    height: 34px;
  }
  .imageBody {
    display: flex;
    flex-wrap: wrap;
  }
}
