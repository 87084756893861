@import "../../../scss/variables";

.tabsWrapper{
    .tabList{
        max-width: 200px;
        max-height: 40px;
        height: 100%;
        width: 100%;
        background-color: $dawn-pink;
        border: 1px solid $spanish-gray;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: $font-size-18;
        font-weight: 700;
        font-family: $font-lato;
        color: $spanish-gray;
        margin-right: 20px;
        text-transform: capitalize;
        &.Mui-selected{
            background-color: $light-primary-color;
            color: $light-primary-background-color;
            border: 1px solid $light-primary-color;
        }
    }
  }

  .MuiTabs-indicator {
    background-color: $light-primary-color;
    border: none;
    background: $light-primary-color;
  }

body {
  &.dark-mode {
    .tabList {
      background-color: $dark-primary-background-color;
      border: 1px solid $dark-border;
      color: $spanish-gray;
      &.Mui-selected {
        background-color:  $dark-primary-color;
        color: $light-primary-background-color;
        border: 1px solid $dark-primary-color;
      }
    }
    .MuiTabs-indicator {
      background-color: $dark-primary-background-color;
      border: none;
      background: $dark-primary-color;
    }
  }
}