@import "../../../scss/variables";
body {
  &.dark-mode {
    .attendeesListTable {
      .heading {
        background-color: $dark-primary-background-color;
      }
      .col.attendee {
        color: $light-primary-background-color;
      }
    }
    .na {
      color: $light-primary-background-color;
    }
    .heading {
      //background-color: $dark-secondary-background-color;
    }
    .AttendeesContainer {
      .titleAttendee {
        color: $light-primary-background-color;
      }
    }
    .tableJHAReport {
      tr {
        .tablejhabody {
          .hazardNametd {
            color: $light-primary-background-color;
            border: 1px solid $light-primary-background-color;
          }
        }
      }
      td {
        color: $light-primary-background-color;
        border: 1px solid $light-primary-background-color;
        p {
          color: $light-primary-background-color;
        }
      }
      .tabletrHeader {
        background-color: $dark-secondary-background-color;
        th,
        td {
          border: 1px solid $light-primary-background-color;
          color: $light-primary-background-color;
          p {
            color: $light-primary-background-color;
          }
        }
      }
    }
  }
}
.jhaReportTable {
  .container {
    width: 100%;
    padding-left: 0;
    border: 1px solid #404040;
  }
  .heading {
    display: flex;
    background-color: #e4f0f5;
    text-align: center;
    line-height: 40px;
    font-size: 16px;
    font-family: "lato";
    color: #333333;
    font-weight: 700;
    width: 100%;
    margin-bottom: 0px;
  }
  .table-row {
    display: flex;
    width: 100%;
  }
  .col {
    &:nth-child(1) {
      width: 25%;
    }
    &:nth-child(2) {
      width: 25%;
    }
    &:nth-child(3) {
      width: 50%;
    }
    border: 1px solid #404040;
    padding-top: 15px;
    padding-bottom: 15px;
    text-align: left;
    padding-left: 15px;
    width: 33.33%;
    display: flex;
    // align-items: center;
    .controleStyle {
      padding-bottom: 10px;
      padding-top: 5px;
      width: 100%;
      border-bottom: 1px solid #404040;
      padding: 10px;
      br {
        display: none;
      }

      &::-webkit-scrollbar {
        width: 0em;
      }

      &::-webkit-scrollbar-track {
        box-shadow: none;
      }

      &::-webkit-scrollbar-thumb {
        background: none;
      }
      ul li strong em h1 h2 h3 h4 h5 blockquote pre {
        // word-wrap: break-word;
        white-space: balance;
        // text-overflow: ellipsis;
      }
      p {
        white-space: balance;
      }
      ul {
        text-align: left;
        li {
          margin-bottom: 25px;
          font-size: $font-size-18;
          color: $text-black-color;
          font-weight: 500;
          padding-left: 20px;
          position: relative;
          white-space: balance;

          &::after {
            position: absolute;
            content: "";
            top: 12px;
            left: 0;
            background-color: $text-black-color;
            width: 5px;
            height: 5px;
            border-radius: 10px;
            white-space: balance;
          }
        }
      }
      color: $text-black-color;
      font-weight: 400;
      font-family: $font-lato;
      text-align: left;
      white-space: balance;

      strong {
        font-weight: bold;
        color: #333333;
        white-space: balance;
      }
      em {
        font-style: italic;
        white-space: balance;
      }
      ul {
        li {
          margin-bottom: 1px;
          white-space: balance;

          // list-style: inside;
        }
      }
      h1 {
        font-size: 2em;
        font-weight: bold;
        white-space: balance;
      }
      h2 {
        display: block;
        font-size: 1.5em;
        margin-block-start: 0.83em;
        margin-block-end: 0.83em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-weight: bold;
        white-space: balance;
      }
      h3 {
        display: block;
        font-size: 1.17em;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-weight: bold;
        white-space: balance;
      }
      h4 {
        display: block;
        margin-block-start: 1.33em;
        margin-block-end: 1.33em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-weight: bold;
        white-space: balance;
      }
      blockquote {
        display: block;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 40px;
        margin-inline-end: 40px;
        white-space: balance;
      }
      ol {
        display: block;
        list-style-type: decimal;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        padding-inline-start: 40px;
        white-space: balance;
      }
      pre {
        display: block;
        font-family: monospace;
        white-space: pre;
        margin: 1em 0px;
        white-space: balance;
      }
    }
    &.activity {
      font-family: "Lato";
      font-size: 16px;
      font-weight: 700;
      color: #333333;
      text-align: left;
      padding-left: 15px;
      padding-top: 10px;
      padding-bottom: 20px;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
    }
    &.hazard {
      font-family: "Lato";
      font-size: 16px;
      font-weight: 700;
      color: #333333;
      text-align: left;
      padding-left: 0px;
      padding-top: 5px;
      padding-bottom: 0px;
      display: flex;
      flex-direction: column;
      // align-items: center;
      flex-wrap: wrap;
      .col1 {
        width: 100%;
        border-bottom: 1px solid #404040;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-left: 10px;
        min-height: 40px;
        &:last-child {
          border: none;
        }
      }
    }
    &.controle {
      font-family: "Lato";
      font-size: 16px;
      font-weight: 400;
      color: #333333;
      text-align: left;
      padding: 0px;
      align-items: center;
      display: block;
    }
  }
}
.jhadownload {
  .downloadpdf {
    background-color: $green-color;
    text-align: left;
    border-radius: 20px;
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 36px;
    color: $white-color;
    font-size: $font-size-14;
    font-weight: 400;
    margin-top: 60px;
    margin-bottom: 30px;
    font-family: $font-lato;
  }
  .downloadIcon {
    color: $white-color;
  }
}
.AttendeesContainer {
  .titleAttendee {
    color: #333;
    font-family: "Lato";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
  }
}

.attendeesListTable {
  .container {
    width: 100%;
    padding-left: 0;
    border: 1px solid #404040;
  }
  .heading {
    display: flex;
    background-color: #e4f0f5;
    text-align: center;
    font-weight: 600;
    font-size: 20px;
    font-family: "lato";
    text-align: center;
    width: 100%;
    margin-bottom: 0px;
  }
  .table-row {
    display: flex;
    width: 100%;
  }
  .col {
    &:nth-child(1) {
      width: 40%;
    }
    &:nth-child(2) {
      width: 60%;
    }
    border: 1px solid #404040;
    padding-top: 15px;
    padding-bottom: 15px;
    text-align: left;
    padding-left: 15px;
    width: 33.33%;
    display: flex;
    align-items: center;
    &.attendee {
      color: #333;
      font-family: "Lato";
      font-size: 16px;
      font-weight: 700;
      line-height: normal;
      text-transform: capitalize;
      padding-top: 20px;
      padding-bottom: 20px;
      text-align: left;
      padding-left: 15px;
    }
    &.proof {
      color: #333;
      font-family: "Lato";
      font-size: 16px;
      font-weight: 400;
      line-height: normal;
      text-transform: capitalize;
      padding-top: 20px;
      padding-bottom: 20px;
      text-align: left;
      padding-left: 15px;
      .attendeeProofImage {
        width: 150px;
        height: 85px;
      }
    }
  }
}

.multipleVerification {
  display: flex;
  justify-content: space-around;
  float: left;
  padding: 10px;
}

.attendeeListReport {
  .container {
    width: 100%;
    padding-left: 0;
    border: 1px solid #404040;
  }
  .heading {
    display: flex;
    background-color: #e4f0f5;
    text-align: center;
    font-weight: 600;
    font-size: 20px;
    font-family: "lato";
    text-align: center;
    width: 100%;
    margin-bottom: 0px;
  }
  .table-row {
    display: flex;
    width: 100%;
  }
  .col {
    &:nth-child(1) {
      width: 40%;
    }
    &:nth-child(2) {
      width: 60%;
    }
    border: 1px solid #404040;
    padding-top: 15px;
    padding-bottom: 15px;
    text-align: left;
    padding-left: 15px;
    width: 33.33%;
    display: flex;
    align-items: center;
    .Listofuser {
      width: 100%;
    }
    .ListOfImage {
      width: 100%;
    }
    &.attendee {
      color: #333;
      font-family: "Lato";
      font-size: 16px;
      font-weight: 700;
      line-height: normal;
      text-transform: capitalize;
      text-align: left;
      padding-left: 15px;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
    }
    &.proof {
      color: #333;
      font-family: "Lato";
      font-size: 16px;
      font-weight: 400;
      line-height: normal;
      text-transform: capitalize;
      padding-top: 20px;
      padding-bottom: 20px;
      text-align: left;
      padding-left: 15px;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      .attendeeProofImage {
        width: 150px;
        height: 85px;
      }
    }
  }
}

.getHeight:empty {
  min-height: 40px;
  border: 1px solid #404040;
}

.tableJHAReport {
  .tabletrHeader {
    background-color: #e4f0f5;
    text-align: center;
    line-height: 40px;
    font-size: 16px;
    font-family: "lato";
    color: #333333;
    font-weight: 700;
    width: 100%;
    margin-bottom: 0px;
  }
  .tableJhaHeaderS {
    table {
      width: 100%;
      th {
        &:first-child {
          border: none;
          width: 200px;
          min-width: 200px;
        }
        &:last-child {
          border: none;
          border-left: 1px solid;
        }
      }
    }
    padding: 0px;
  }
  &table,
  th,
  td {
    border: 1px solid $text-black-color;
    border-collapse: collapse;
    padding: 10px;
    font-family: "Lato";
    font-size: 16px;
    font-weight: 700;
    color: $text-black-color;
    text-align: left;

    &:first-child {
      width: 25%;
    }

    width: 75%;
  }
  tr {
    .tablejhabody {
      .hazardNametd {
        border: none;
        border-bottom: 1px solid $text-black-color;
        min-width: 200px;
        width: 200px;
      }
      .controlstd {
        padding: 0px;
        border-top: none;
        table {
          tr {
            td {
              border: none;
              border-bottom: 1px solid $text-black-color;
              color: $text-black-color;
              font-weight: 400;
              font-family: $font-lato;
              text-align: left;
            }
          }
        }
      }
      &:last-child {
        border: none;
        padding: 0px;
      }
    }
  }
}

.tableJHAReport {
  .tablejhabody {
    .controlstd {
      table {
        tr {
          td:last-child {
            border-bottom: none;
          }
        }
      }
    }
  }
}
@media print {
  .overRideJhaPdf {
    &.InspectionContainer {
      margin-top: 0px;
      .title {
        &.repotPdfHeading {
          display: none;
        }
      }
      .clientPdfDetail {
        .clientName,
        .clientLogo {
          display: none;
        }
      }
      .reportHeader {
        display: none;
      }
    }
  }
}
