@import "../../../scss/variables";
.questionMarkContainer {
  margin-bottom: 10px;
  .customLable {
    font-family: $font-lato;
    font-style: normal;
    font-weight: 600;
    font-size: $font-size-16;
    line-height: 19px;
    text-align: left;
    margin-bottom: 10px;
    color: $shadow-grey;
    span {
      color: $red-color;
      padding-left: 3px;
    }
  }
  .questionMark {
    cursor: pointer;
    svg {
      width: 16px;
      height: 16px;
      margin-left: 5px;
      color: $light-primary-color;
    }
  }
}
.questonMarkDes {
  padding: 10px;
  font-family: $font-lato;
  font-style: normal;
  font-weight: 600;
  font-size: $font-size-16;
  color: $shadow-grey;
  cursor: default;
  a {
    cursor: pointer;
  }
}

body {
  &.dark-mode {
    .questionMark {
      cursor: pointer;
      svg {
        width: 16px;
        height: 16px;
        margin-left: 5px;
        color: $dark-primary-color;
      }
    }
    .questonMarkDes {
      padding: 10px;
      font-family: $font-lato;
      font-style: normal;
      font-weight: 600;
      font-size: $font-size-16;
      color: $dark-primary-text-color;
      cursor: default;
      a {
        cursor: pointer;
        color: $dark-primary-color;
      }
    }
    .questionMarkContainer {
      .customLable {
        color: $dark-primary-text-color;
      }
    }
  }
}
