@import "../../../scss/variables";

body {
  &.dark-mode {
    .overideTimePicker {
      .formControlRoot {
        background-color: $dark-form-all-input-color;
      }
      .inputRoot {
        background-color: $dark-form-all-input-color;
      }
      input[type="time"]::-webkit-calendar-picker-indicator {
        background-image: url(../../../assets/dark_schedule.svg);
      }
    }
  }
}

.overideTimePicker {
  input[type="time"]::-webkit-calendar-picker-indicator {
    cursor: pointer;
    font-size: 21px;
    background-image: url(../../../assets/timepicker.svg);
  }
  .formControlRoot {
    background-color: $white-color;
    border-radius: 15px;
    font-family: $font-lato;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: $manatee-color;
    margin-bottom: 0px;

    &.Mui-disabled {
      background-color: $fog-grey;
      border-radius: 15px;
    }
    &.MuiOutlinedInput-notchedOutline {
      border-radius: 15px;
      padding: 3px 15px;
    }
    .inputRoot {
      border-radius: 16px;
      padding: 2px 0px;
    }
  }
}
