@import "../../scss/variables";

.login {
  max-width: 100vw;
  min-height: 100vh;
  overflow-x: hidden;
  background-color: $white-color;
  &-container {
    margin: 20px;
    margin-top: 50px;
    @media screen and (min-width: 768px) {
      display: flex;
      padding-top: 3%;
      margin-top: 0px;
      justify-content: space-between;
    }
  }
  &-left {
    @media screen and (min-width: 768px) {
      max-width: 340px;
      width: 100%;
      margin-left: 4%;
      text-align: left;
    }
    .logoDark {
      display: none;
    }
    .logoLight {
      display: block;
    }
    .logo {
      width: 170px;
      max-width: 100%;
    }

    .logo-title {
      font-weight: 700;
      font-size: $font-size-30;
      line-height: 36px;
      color: $text-black-color;
      margin-top: 30px;
      font-family: $font-lato;
    }
    .form-wrapper {
      .input-control {
        background-color: $white-color;
        border: 1px solid $Stroke-Fossil-Grey;
        &.border-red {
          border: 1px solid $red-color;
        }
      }
      .showHidePassword {
        filter: initial;
        width: 20px;
        height: 20px;
      }
      .initial {
        position: relative;
      }
      .label {
        font-weight: 600;
      }
    }
  }

  &-right {
    display: flex;
    justify-content: flex-end;
    margin-left: auto;
    margin-right: 4%;
    @media screen and (max-width: 1024px) {
      display: none;
    }
  }

  &-button {
    margin-top: 20px;
  }
  .themeToggle {
    display: flex;
    justify-content: flex-end;
    padding-right: 40px;
    padding-top: 15px;
  }
}

.forgot-password {
  font-weight: 600;
  font-size: $font-size-14;
  line-height: 20px;
  text-align: right;
  color: $cerulean-color;
  margin-top: 20px;
  cursor: pointer;
}

.login-button {
  text-align: left;
  button {
    max-width: 340px;
    width: 100%;
    height: 44px;
    line-height: 44px;
    padding: 0;
    font-weight: 500;
  }
}
.signup-txt {
  span {
    cursor: pointer;
    font-weight: 900;
  }
}
// span {
//   text-decoration: underline;
// }

.password-container {
  position: relative;
  .showHidePassword {
    position: absolute;
    right: 10px;
    top: 15px;
    cursor: pointer;
    width: 20px;
  }
}
.signup-txt {
  font-weight: 600;
  font-size: $font-size-14;
  color: $spanish-gray;
  text-align: left;
  margin-top: 14px;
  span {
    color: $cerulean-color;
    font-size: $font-size-14;
    font-weight: 900;
    text-decoration: none;
  }
}

body {
  &.dark-mode {
    .login {
      background-color: $dark-tertiary-background-color;
    }
    .login-left {
      .logo-title {
        color: $light-primary-background-color;
      }
      .form-wrapper {
        .input-control {
          background-color: $dark-form-all-input-color;
          border: 1px solid $dark-border;
          color: $dark-primary-text-color;
        }
      }
      .form-wrapper {
        input[type="text"]:-webkit-autofill {
          -webkit-text-fill-color: #333;
          background-color: #333;
          border: 2px solid #555;
        }
      }
    }
    .login-left {
      .form-wrapper {
        .label {
          color: $light-primary-background-color;
        }
      }
    }
    .signup-txt {
      color: $light-primary-background-color;
      span {
        color: $dark-primary-color;
      }
    }
    .login-button button {
      background-color: $dark-primary-color;
      color: $dark-primary-text-color;
      &:hover {
        background-color: $dark-secondary-background-color;
        color: $dark-primary-text-color;
      }
    }
    .initial {
      color: $dark-primary-text-color;
      position: relative;
    }
    input:-webkit-autofill {
      background-color: transparent !important;
      -webkit-box-shadow: 0 0 0px 1000px #333 inset !important; /* Change this to your desired color */
      -webkit-text-fill-color: #fff !important;
    }

    input:-webkit-autofill:focus {
      background-color: transparent !important;
      -webkit-box-shadow: 0 0 0px 1000px #333 inset !important; /* Your desired color */
      -webkit-text-fill-color: #fff !important;
    }

    input:-webkit-autofill:active {
      background-color: transparent !important;
      -webkit-box-shadow: 0 0 0px 1000px #333 inset !important;
      -webkit-text-fill-color: #fff !important;
    }
  }
}
