.viewcasetitile {
  margin-left: 58px;
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
}
.caseviewpopconner {
  margin-top: -16px;
}
#btnseacrh1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;

  position: absolute;
  width: 72px;
  height: 25px;
  border-radius: 100px;
  margin-left: 194px;
  margin-top: 5px;
  background: #1b436e;
  color: white;
}
