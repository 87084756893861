.SearchsafetymeetingFieldStyle {
  //   border: 1px solid;
  color: #666666;
  font-family: Lato;
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  text-align: center;
  margin-top: 3px;

  display: block;
  width: auto;
  padding-top: 11px;
  padding-left: 15px;
  padding-right: 7px;
  border-radius: 15px;
  justify-content: center;
  color: midnightblue;
}
.titlediv {
  display: flex;
  justify-content: flex-start;
  .SearchsafetymeetingFieldStyle {
    padding-top: 0px;
  }
}
