@import "../../../../scss/variables";

.overviewIns {
  .customLableIns {
    .overideLables {
      .formLabel {
        text-transform: capitalize;
      }
    }
  }
  .mt-2 {
    margin-top: 20px;
  }
  .mb-2 {
    margin-bottom: 20px;
  }
  h1 {
    color: $dark-secondary-background-color;
    font-size: $font-size-22;
    font-weight: 700;
    font-family: $font-lato;
  }
  .pagination-controls {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
  }
  .date-range {
    margin-bottom: 10px;
    font-size: $font-size-16;
    font-weight: 500;
    color: $light-primary-text-color;
    span {
      font-size: $font-size-16;
      font-weight: 500;
      color: $light-primary-text-color;
    }
  }
  .commonBoxShodow {
    padding: 10px;
    border-radius: 15px;
    background-color: $light-primary-background-color;
    box-shadow: 0px 4px 100px 0px rgba(0, 0, 0, 0.07);
    .titletop {
      font-size: $font-size-16;
      font-weight: 600;
      font-family: $font-lato;
      color: $dark-secondary-background-color;
      margin-bottom: 15px;
      &.small {
        font-size: $font-size-14;
        text-align: center;
        margin-bottom: 5px;
      }
    }
    .discription {
      font-size: $font-size-42;
      font-weight: 600;
      font-family: $font-lato;
      text-align: center;
      line-height: 1.4;
      &.total {
        color: $light-primary-color;
      }
      &.darkBlue {
        color: #1c446f;
      }
      &.pink {
        color: #f21dd0;
      }
      &.green {
        color: $green-color;
      }
      &.red {
        color: $red-color;
      }
      &.na {
        color: $shadow-grey;
      }
      &.purple {
        color: $ocean-green;
      }
      &.blue {
        color: #1d4cf2;
      }
      &.lightGreen {
        color: #a1f21d;
      }
    }
    .currentPrev {
      font-size: $font-size-16;
      font-weight: 600;
      font-family: $font-lato;
      color: $dark-secondary-background-color;
      margin: 15px 0px 0px 0px;
      text-align: center;
    }
  }
}
.topHeaderDrill {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  .topHeaderDrillLeft {
    .title {
      font-size: $font-size-18;
      font-family: $font-lato;
      font-weight: 700;
      color: $text-black-color;
    }
  }
  .topHeaderDrillRight {
    .downloadBox {
      background-color: $green-color;
      font-size: $font-size-14;
      font-weight: 500;
      font-family: $font-lato;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $white-color;
      padding: 10px 20px;
      border-radius: 100px;
      cursor: pointer;
      .downloadIcon {
        margin-right: 10px;
      }
    }
  }
}

.downloadListBox {
  padding: 10px;
  .downloadList {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0px;
    }
    .downloads {
      .downloadListIcon {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;
        img{
          width: 16px;
          height: 16px;
        }
        span {
          font-size: $font-size-14;
          font-weight: 400;
          font-family: $font-lato;
          color: $dark-secondary-background-color;
          margin-left: 15px;
        }
      }
    }
  }
}
.pdf-loader {
  display: none;
}

.overviewIns {
  &.pdfStyles {
    .pdfmrg {
      margin: 0px 10px;
      padding: 0px 10px;
    }
    .mb-2 {
      margin-bottom: 0px;
    }
    .topSection {
      min-height: 180px;
      max-height: 190px;
      .topRow {
        .topRowList {
          .heading {
            line-height: 1.6;
          }
        }
      }
    }
  }
}
.customTableWrapper {
  .tableContainer {
    table {
      .totalsRow {
        &.row {
          td {
            font-weight: 700;
            .persantage {
              font-weight: 700;
            }
          }
        }
      }
    }
  }
}
.overviewIns {
}

body {
  &.dark-mode {
    .overviewIns {
      h1 {
        color: $dark-primary-text-color;
      }
      
  .date-range {
    color: $dark-primary-text-color;
    span {
      color: $dark-primary-text-color;
    }
  }
  
  .commonBoxShodow {
    background-color: $dark-primary-background-color;
    // box-shadow: 0px 4px 100px 0px rgba(0, 0, 0, 0.07);
    
    .currentPrev {
      color: $dark-primary-text-color;
    }
    .titletop {
      color: $dark-primary-text-color;
    }
    .discription {
      &.total {
        color: $dark-primary-color;
      }
      &.darkBlue {
        color: $dark-secondary-color;
      }
      &.pink {
        color: #f21dd0;
      }
      &.green {
        color: $green-color;
      }
      &.red {
        color: $red-color;
      }
      &.na {
        color: $shadow-grey;
      }
      &.purple {
        color: $ocean-green;
      }
      &.blue {
        color: #1d4cf2;
      }
      &.lightGreen {
        color: #a1f21d;
      }
    }
    .currentPrev {
      color: $dark-primary-text-color;
    }
  }
    }
    
  .topHeaderDrill {
    .topHeaderDrillLeft {
      .title {
        color: $dark-primary-text-color;
      }
    }
    .topHeaderDrillRight {
      .downloadBox {
        color: $white-color;
        cursor: pointer;
        .downloadIcon {
          margin-right: 10px;
        }
      }
    }
  }
  }
}
