@import "../../../../scss/variables";
.otherStolenOver {
  padding: 25px;
  text-align: left;
  padding-bottom: 15px;
  .stolentitle {
    margin-bottom: 10px;
    color: $text-black-color;
    font-size: $font-size-16;
    font-family: $font-lato;
    font-weight: 700;
  }
  .stolenbtnlist {
    .button {
      max-width: 200px;
      margin-right: 15px;
      max-height: 40px;
      line-height: 40px;
      margin-bottom: 10px;
      background-color: $stolen-bg;
    }
  }
}
