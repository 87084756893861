@import "../../../../../src/scss/variables";
body {
  &.dark-mode {
    .overideReprimand {
      .title {
        color: $dark-primary-text-color;
      }
    }
  }
}

.overideReprimand {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  .formContainer {
    display: flex;
    width: 100%;
    flex-direction: column;
    font-family: Lato;
  }
  .title {
    text-align: left;
    font-weight: 700;
    font-size: 22px;
    color: #333333;
  }
  .reprimandBoxContainer {
    display: flex;
    width: 100%;
    justify-content: "center";
    align-items: center;
  }
  .reprimandBox {
    // padding: 20px;
    // background: #ffffff;
    // box-shadow: 0px 4px 100px rgba(0, 0, 0, 0.07);
    // border-radius: 8px;
    // margin: 20px 20px 20px 0;
    min-width: 820px;
  }
  // .RadioBtnStyle {
  //   margin-right: 180px;
  //   margin-top: -12px;
  // }
  .involvedPartyType {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 492px;
    height: 100px;
    align-items: center;
    // margin-left: -54px;
  }
  .close {
    .closeReprimand {
      margin-left: "20px";
    }
  }
  .RadioButtonStyle {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 270px;

    align-items: center;
    // margin-left: -47px;
  }

  .selectableRadioOption {
    // margin-left: -45px;
    margin-bottom: 5px;
  }

  .reprimandSaveDraft {
    position: absolute;
    margin-left: 83px;
    text-transform: lowercase;
  }
}

.addReprimandContainer {
  background: #ffffff;
  box-shadow: 0px 4px 90px rgba(0, 0, 0, 0.1);
  // margin: 20px;
  // padding: 20px;
  width: 1000px;
}
.reprimand-stitle {
  display: flex;
  text-align: left;
  font-weight: 700;
  font-size: 22px;
  color: #333333;
}
