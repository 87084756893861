.heading_style {
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  color: #333333;
}
.save_btn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  // margin-top: 50px;
}

.save_btn_with_back {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.credential_heading_style {
  font-weight: 400;
  font-size: 16px;
  line-height: 14px;
  color: #1b436e;
  text-align: left;
  position: absolute;
  margin-right: 970px;
  margin-top: 12px;
  cursor: pointer;
}
.cancle_heading_style {
  color: #1b436e;
  font-weight: 600;
  margin-right: 50px;
  margin-top: 12px;
  cursor: pointer;
}

.goBack_heading_style {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #1b436e;
  text-align: left;
  position: absolute;
  margin-right: 950px;
  margin-top: 12px;
  cursor: pointer;
}
.rightSideBtn {
  display: flex;
}
.userListBody {
  display: flex;
  margin-top: 29px;
}
.userList {
  // border: 1px solid;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 13px;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
  transition: 0.3s;
  padding: 8px 13px;
  border-radius: 5px;
}
.remove {
  cursor: pointer;
  color: green;
  margin-left: 11px;
}
.assignrightbtn .rightSideBtn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
