.common-hamburger {
  &-menu {
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    z-index: 20;
    display: flex;
    height: 100vh;
    display: block;
    margin: 0 auto;
    position: fixed;
    flex-wrap: wrap;
    overflow-x: hidden;
    overflow-y: hidden;
    pointer-events: none;
    justify-content: center;
    transition: opacity 250ms 400ms ease;
    background-color: rgba(31, 32, 41, 0.75);
    &.visible {
      opacity: 1;
      pointer-events: auto;
      transition: all 300ms ease-in-out;
      .common-hamburger-menu-wrap {
        opacity: 1;
        transform: scale(1);
        transition: opacity 250ms 400ms ease, transform 350ms 500ms ease;
      }
    }
    &-wrap {
      width: 30%;
      opacity: 1;
      height: 558px;
      display: block;
      margin: 0 auto;
      overflow-y: hidden;
      overflow-x: hidden;
      position: relative;
      top: 10%;
      align-self: center;
      border-radius: 15px;
      transform: scale(0.6);
      background-color: #fff;
      box-shadow: 0px 3px 90px rgba(0, 0, 0, 0.1);
      transition: opacity 250ms 250ms ease, transform 300ms 250ms ease;
      transform: scale(0);

      @media screen and (max-width: 767px) {
        width: 95%;
        overflow-y: scroll;
      }
    }
  }
  &-page {
    min-height: 100vh;
    padding: 40px 20px;
    background-size: 1247px 827px;
    &-wrap {
      display: flex;
      // overflow: hidden;
      position: relative;
      flex-direction: column;
      border-radius: 0 0 0 0;
      background-size: 30vh;
      background-size: cover;
      &:after {
        right: 0;
        content: "";
        width: 100%;
        height: 26px;
        bottom: -1px;
        display: block;
        position: absolute;
        object-fit: cover;
        object-position: 50% 50%;
        mask-position: right;
        mask-repeat: no-repeat;
        background-size: 1247px 827px;
      }
      &-header {
        padding: 14px 30px 60px;
        padding-bottom: 16px;

        h1 {
          font-style: normal;
          font-weight: 700;
          font-size: 24px;
          line-height: 29px;
          color: #333333;
          margin-top: 15px;
          text-align: left;
        }
        p {
          text-align: left;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          color: #666666;
        }
      }
    }
  }
}

