@import "../../../../scss/variables";

html,
body {
  &.dark-mode {
    .dailyReportView {
      .viewReportIns {
        table {
          background-color: $dark-form-all-input-color;
        }
        .tableHead {
          background-color: $dark-tertiary-background-color;

          th {
            color: $dark-primary-text-color;
          }
        }

        .checkUserInfoMessage {
          color: $dark-primary-text-color;
        }
      }
    }
  }
}

.dailyReport {
  &.addShadowBox,
  .dailyReportView {
    .heading_style {
      margin-top: 0px;
    }

    .viewReportIns {
      table {
        tr {
          td {
            img {
              filter: brightness(0);
              cursor: pointer;
            }
          }
        }
        .tableHead {
          th {
            &:nth-child(1) {
              width: auto;
            }
            &:nth-child(2) {
              width: auto;
            }
            &:nth-child(3) {
              width: auto;
            }
            &:nth-child(4) {
              width: auto;
            }
            &:nth-child(5) {
              width: auto;
            }
          }
        }
        .tableSubmit {
          display: flex;
          .submit {
            margin-right: 5px;
            border-radius: 10px;
            background-color: $cerulean-color;
            color: $white-color;
            font-size: $font-size-14;
            font-weight: 500;
            padding: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
          }
          .cancel {
            border-radius: 10px;
            background-color: $white-color;
            color: $cerulean-color;
            font-size: $font-size-14;
            font-weight: 500;
            padding: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
          }
        }
      }
    }
  }
}

.dailyReportView {
  .viewReportIns {
    table {
      tr {
        td {
          img {
            filter: brightness(0);
            cursor: pointer;
          }
        }
      }
      .tableHead {
        th {
          &:nth-child(1) {
            width: auto;
          }
          &:nth-child(2) {
            width: auto;
          }
          &:nth-child(3) {
            width: auto;
          }
          &:nth-child(4) {
            width: auto;
          }
          &:nth-child(5) {
            width: auto;
          }
        }
      }
    }
  }
}
