@import "../../../scss/variables";

.overideSupportLandingPage {
  .landingpageContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
    text-align: center;
  }
  .header {
    font-family: $font-lato;
    font-size: $font-size-34;
    color: $text-black-color;
    width: 55%;
  }
  .searchBar {
    display: flex;
    width: 42%;
    justify-content: center;
    align-items: center;
    margin: 15px;
    font-family: $font-lato;
  }
  .cardsContainer {
    display: flex;
    width: 100%;
    justify-content: space-around;
    margin: 20px;
  }
  .secondaryHeader {
    font-family: $font-lato;
    font-size: $font-size-26;
    color: $text-black-color;
    font-weight: 700;
    margin-top: 30px;
  }
  .tertiaryHeader {
    display: flex;
    flex-wrap: wrap;
    width: 50%;
    margin-top: 20px;
    font-size: $text-black-color;
    font-weight: 400;
  }
  .icon {
    width: 60px;
    height: 60px;
    color: $cerulean-color;
  }
  .accordionDiv {
    width: 100%;
    min-height: 50px;
  }
}
