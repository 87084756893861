.overideDivision {
  .divisionBtnBox {
    justify-content: flex-end;
    align-items: center;
    .overideBtn {
      .containedButton {
        max-width: 190px;
      }
    }
  }
}
