@import "../../../src/scss/variables";
html,
body {
  &.dark-mode {
    .filterlist_of_cases {
      .filterLabel {
        color: $dark-primary-text-color;
      }
    }

    .filter_btn button {
      color: $dark-primary-text-color;
      background: #333;
    }
    .filter_btn button svg#icn {
      color: $dark-primary-text-color;
    }
    .MuiFormControlLabel-label {
      color: $dark-primary-text-color;
    }
    .filterlist_of_cases {
      .MuiCardActions-root {
        .overideBtn button {
          background-color: $dark-primary-color;
        }
      }
    }
  }
}
.singleSelect{
  margin-bottom: 10px;
}
.filterListCheckbox {
  margin-right: 10px;
}

.permitFilter {
  .filterlist_of_cases {
    text-transform: capitalize;
  }
  .permitFilterDate {
    display: flex;
    justify-content: space-between;
    .approvalDate {
      width: 48%;
    }
  }
}

.filterlist_of_cases {
  .filterLabel {
    color: #666666;
    font-size: 16px;
    font-weight: 600;
  }
}
