html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
* {
  box-sizing: border-box;
}
select,
input,
button,
input[type="password"],
input[type="email"],
input[type="text"],
textarea {
}
body {
  font-family: "Lato", sans-serif;
  a {
    text-decoration: none;
  }
  .fl {
    float: left;
  }
  .fr {
    float: right;
  }
  .group:after {
    content: "";
    display: table;
    clear: both;
  }
}
.wrap {
  width: 100%;
  margin: 0 auto;
}
.flex-row {
  display: flex;
  flex-direction: row;
  flex-wrap: no-wrap;
  justify-content: space-between;
  align-content: flex-start;
  align-items: flex-start;
}
.flex-expand {
  flex-grow: 1;
}
.root-wrap {
  background-color: #111;
  > * {
    // max-width: 500px;
    margin: 0 auto;
  }
}
