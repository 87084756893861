@import "../../../scss/variables";

.card {
  border: bold;
  text-align: center;
  font-family: $font-lato;
  font-style: normal;
  font-weight: 700;
  font-size: $font-size-24;
  color: $text-black-color;
  padding: 16px;
}
.cardsContainer1 {
  display: flex;
  width: 100%;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 10px 20px;
}
.tableHead {
  .tableHeader {
    background-color: $sky-grey-color;
    .tableHeadercell {
      font-family: $font-lato;
      font-weight: 600;
      color: $text-black-color;
    }
  }
  .tableHeaderbody {
    .tableHeaderval {
      font-family: $font-lato;
    }
  }
}
