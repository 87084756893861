@import "../../../../scss/variables";

.overideViewSite {
  .topDetails {
    .title {
      font-size: $font-size-22;
      color: $text-black-color;
      font-weight: 700;
      font-family: $font-lato;
    }
    .detailWrapper {
      padding: 20px;
      border: 1px solid $light-silver;
      margin-top: 20px;
      line-height: 1.6;
      margin-bottom: 50px;
      .siteDetailTitle {
        font-size: $font-size-16;
        color: $cerulean-color;
        font-weight: 700;
        font-family: $font-lato;
      }
      .siteName {
        font-size: $font-size-20;
        color: $text-black-color;
        font-weight: 700;
        font-family: $font-lato;
      }
      .description {
        font-size: $font-size-14;
        color: $text-black-color;
        font-weight: 400;
        font-family: $font-lato;
        strong {
          font-weight: 700;
        }
      }
    }
  }
  .customerListWrapper {
    .customerList {
      border-bottom: 1px solid $light-silver;
      padding-bottom: 10px;
      height: 450px;
      padding-top: 10px;
      .title {
        font-size: $font-size-14;
        color: $cerulean-color;
        font-weight: 700;
        font-family: $font-lato;
        margin-bottom: 5px;
      }
      .customeNameList {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        .customeName {
          font-size: $font-size-20;
          color: $text-black-color;
          font-weight: 700;
          font-family: $font-lato;
          width: 250px;
        }
        .viewProject {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          font-size: $font-size-14;
          color: $cerulean-color;
          font-weight: 700;
          font-family: $font-lato;
          cursor: pointer;

          span {
            height: 20px;
          }
        }
      }
    }
  }
}
