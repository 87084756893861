@import "../../../scss/variables";

.overideWitness {
  .witnessBox {
    border: 1px solid $border-grey;
    padding: 20px;
    border-radius: 15px;
    margin-bottom: 20px;
    max-width: 720px;
    width: 100%;
  }
  .witnessCancel{
    max-width: 720px;
    width: 100%;
  }
  .title {
    text-align: left;
    color: $text-black-color;
    font-size: $font-size-18;
    font-weight: 700;
    font-family: $font-lato;
  }
  .signatureBtn {
    width: 200px;
    height: 50px;
    border-radius: 30px;
    border-color: $cerulean-color;
    color: $cerulean-color;
  }
  .selectBoxCutom {
    width: 300;
    margin: 0;
  }
  .inputwdads {
    border: 1px solid $red-color;
  }
  .signTop30 {
    margin-top: 25px;
  }
}
