@import "../../../../scss/variables";

.overideCourses {
  .addcourseBoxContainer {
    max-width: 700px;
  }
  .addcourseButton {
    justify-content: flex-end;
  }
}
