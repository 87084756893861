@import "../../../scss/variables";

.createQuizContainer {
  text-align: left;
  .createQuizContainerLeft {
    padding-right: 30px;
  }
  .pageTitle {
    font-size: $font-size-24;
    font-weight: 700;
    color: $text-black-color;
    font-family: $font-lato;
    margin-bottom: 35px;
  }
  .quizCreateContainer {
    background-color: $cultured;
  }
}
