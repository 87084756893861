@import "../../../scss/variables";

body {
  &.dark-mode {
    .overideTextArea {
      .textArea {
        background-color: $dark-form-all-input-color;
        color: $dark-primary-text-color;
      }
    }
  }
}

.overideTextArea {
  .textArea {
    width: 100%;
    height: 100px;
    max-height: 102px;
    padding: 12px 20px;
    box-sizing: border-box;
    border: 1px solid $fossil-grey;
    border-radius: 15px;
    background-color: $white-color;
    font-size: $font-size-16;
    resize: none;
    width: 100%;
    resize: none;
    font-family: $font-lato;
    overflow-y: auto !important;
    &:focus-visible {
      border: 1px solid $fossil-grey;
      outline: none;
    }
    &::placeholder {
      color: #9a9fa8;
    }
  }
  .errorText {
    color: $red-color;
    margin-left: 0px;
  }
}
