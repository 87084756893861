.sampleLogBox {
  display: flex;
  justify-content: end;
}
.sepratorversion {
  width: 500%;
  text-align: left;
  margin-left: 0;
}
.versionhistory {
  // display: flex;
  //justify-items: flex-start;
  overflow: auto;
  overflow-y: hidden;
  .versionhistory_Root {
    display: flex;
    text-align: left;
    gap: 31px;
    //gap: 22px;
    //justify-content: space-between;
    //padding: 5px;
    margin-top: 20px;
    .version p {
      color: #333;
      font-family: Lato;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    .version_icon {
      cursor: pointer;
    }
  }
}
.otherDetail2 {
  .addedbybold span {
    &.spanaddedname {
      color: #333;
      font-family: Lato;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    &.viewversion {
      color: #333;
      font-family: Lato;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
  .addedbybold span {
    &.spanname {
      color: #1b436e;
      font-family: Lato;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
}
.checkreport {
  display: flex;
  justify-content: center;
  gap: 12px;
  .checkreport1 {
    width: 189px;
    overflow: hidden;
  }
  .checkreport1sample {
    cursor: pointer;
  }
}
.addedby {
  display: flex;
  //justify-content: center;
  gap: 8px;
  width: 216px;
  //max-width: 216px;
}
.version {
  width: 84px;
}

.versionname {
  color: #1b436e;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: left;
  max-width: 108px;
}
.version_date {
  max-width: max-content;
}
.samplecheckbox {
  .closeItem {
    text-decoration: none;
  }
}
.custom-tooltip {
  padding: 5px 12px;
  background-color: grey;
  border-radius: 4px;
  color: #fff;
  font-family: "Lato";
}
