.overideinjuryRecordable {
  .injuryCard {
    border: 1px solid #dfdfdf;
    max-width: 750px;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 30px;
  }
  .injurylabel .overideLables .formLabel {
    
    white-space: normal;
}
}
