@import "../../../scss/variables";

html,
body {
  &.dark-mode {
    .overrideHeader {
      .header {
        .heading {
          color: $dark-primary-text-color;
        }
      }
    }
  }
}

.overrideHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  .header {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 55%;
    .heading {
      font-family: $font-lato;
      font-size: $font-size-34;
      color: $text-black-color;
      font-weight: 700;
    }
  }
  .searchBar {
    // display: flex;
    width: 38%;
    // justify-content: center;
    margin: 15px;
    font-family: $font-lato;
  }
}
