@import "../../../../scss/variables";
.overidePersonal {
  .swproject {
    .overideInputField {
      .inputRoot {
        //width: 293px;
      }
    }
  }
}
.diologswproject {
  .swprojectpopup {
    display: flex;
    flex-direction: column;
    max-width: 340px;
    width: 340px;
  }

  .rainfalltitle p {
    text-align: center;

    margin-top: 2px;
    font-size: $font-size-18;
    font-weight: 600;
    font-family: $font-lato;
    color: $text-black-color;
  }
  .swprojectclose {
    .swcancel {
      display: flex;
      flex-direction: row-reverse;
    }
  }
}
