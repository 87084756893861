@import "../../../../../scss/variables";

body {
  &.dark-mode {
    .overReportHeader {
      .reportHeader {
        background-color: $dark-secondary-background-color;
        .reportHeaderLeft {
          .reportTitle {
            color: $dark-primary-text-color;
          }
        }
        .reportHeaderRight {
          .reportDesc {
            color: $dark-primary-text-color;
            span {
              color: $dark-primary-text-color;
            }
          }
        }
      }
    }
  }
}

.overReportHeader {
  .reportHeader {
    background-color: $white-color;
    text-align: left;
    padding: 25px;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    .reportHeaderLeft {
      .reportTitle {
        font-size: $font-size-24;
        color: $text-black-color;
        font-weight: 700;
        font-family: $font-lato;
        &.right {
          text-align: right;
        }
      }
    }
    .reportHeaderRight {
      .reportDesc {
        text-align: right;
        font-size: $font-size-16;
        color: $text-black-color;
        font-weight: 400;
        font-family: $font-lato;
        span {
          text-align: right;
          font-size: $font-size-16;
          color: $text-black-color;
          font-weight: 400;
          font-family: $font-lato;
          padding-left: 5px;
        }
      }
    }
  }
  .downloadpdf {
    background-color: $green-color;
    text-align: left;
    border-radius: 20px;
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 36px;
    color: $white-color;
    font-size: $font-size-14;
    font-weight: 400;
    margin: 10px 0 0 auto;
    margin-bottom: 0px;
    font-family: $font-lato;
  }
  .downloadIcon {
    color: $white-color;
  }
}
