@import "../../../scss/variables";
body {
  &.dark-mode {
    .newCards {
      .borderGreen {
        border: 3px solid $dark-primary-color;
      }
      .staticDataCard {
        color: $dark-primary-text-color;
      }
    }
    .blueCardBody {
      .defaultCard {
        background-color: $dark-secondary-background-color;
        color: $dark-primary-text-color;
      }
      .selectedCard {
        background: $dark-primary-color;
      }
    }
    .borderGreen {
      background-color: $dark-primary-color;
      color: $dark-primary-text-color;
    }
    .staticDataCard {
      background-color: $dark-primary-color;
      color: $dark-primary-text-color;
    }
  }
}

.blueCardBody {
  font-family: $font-lato;
  font-style: normal;
  font-weight: 600;
  font-size: $font-size-14;
  color: $text-black-color;
  display: flex;
  cursor: pointer;
  flex-wrap: wrap;

  .defaultCard {
    background: $white-color;
    border: 1px solid $border-grey;
    border-radius: 100px;
    padding: 11px 14px;
    margin-right: 10px;
    margin-bottom: 10px;
  }

  .selectedCard {
    background: $cerulean-color;
    border: 1px solid $border-grey;
    border-radius: 100px;
    padding: 11px 14px;
    margin-right: 10px;
    margin-bottom: 10px;
    color: $white-color;
  }
}

.staticDataCard {
  margin-bottom: 11px;
  background: $cerulean-color;
  color: $white-color;
  padding: 8px 20px;
  border-radius: 40px;
  display: flex;
  margin-right: 12px;
  align-items: center;
  justify-content: center;
  border: 4px solid transparent;
  .staticCardIcon {
    margin-right: 9px;
  }
}

.borderGreen {
  border: 4px solid $green-color;
  .staticCardIcon {
    margin-right: 9px;
  }
}
