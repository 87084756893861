.addAssignInspector {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  text-underline-offset: 4px;
  cursor: pointer;
  background-color: #1b436e;
  width: fit-content;
  height: 44px;
  padding: 15px 22px;
  border-radius: 71px;
  font-size: 16px;
  color: white;
}

.manageInspector {
  gap: 15px;
  .secodndryAssignInspector {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-decoration: none;
    text-underline-offset: 4px;
    cursor: pointer;
    background-color: #1b436e;
    width: fit-content;
    height: 44px;
    padding: 15px 22px;
    border-radius: 71px;
    font-size: 16px;
    color: white;
  }
  .secodndryInspector {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-decoration: none;
    text-underline-offset: 4px;
    cursor: pointer;
    background-color: #1b436e;
    width: fit-content;
    height: 44px;
    padding: 15px 22px;
    border-radius: 71px;
    font-size: 16px;
    color: white;
  }
}

.deleteButtonContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;

  .deleteImagestyle {
    .deleteImg {
      color: #ed1b2f;
      cursor: pointer;
      width: 20px;
      height: 20px;
    }
  }
  .deleteButtonText {
    color: #ed1b2f;
    font-family: "Lato";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    cursor: pointer;
  }
}
