@import "../../../../scss/variables";

body {
  &.dark-mode {
    .overrideTicket {
      .ticketContainer {
        background-color: $dark-secondary-background-color;
        .id {
          color: $dark-primary-text-color;
        }
        .idCatDiv {
          .catTimeDiv {
            .cat {
              color: $dark-primary-text-color;
            }
          }
        }
        .question {
          color: $dark-primary-text-color;
        }
        .answer {
          color: $dark-primary-text-color;
        }
        .msg {
          color: $dark-primary-color;
        }
      }
    }
  }
}

.overrideTicket {
  width: 100%;
  .ticketContainer {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    text-align: left;
    background: $white-color;
    box-shadow: 0px 4px 100px rgba(0, 0, 0, 0.07);
    border-radius: 8px;
    padding: 20px;
    font-family: $font-lato;
    .idCatDiv {
      display: flex;
      justify-content: space-between;
      .catTimeDiv {
        display: flex;
        align-items: center;
        .cat {
          display: flex;
          align-items: center;
          margin: 5px;
          font-size: $font-size-14;
          font-weight: 600;
          color: $spanish-gray;
          .catIcon {
            font-size: 1rem;
            font-weight: 900;
            color: $spanish-gray;
          }
        }
      }
    }
    .id {
      display: flex;
      align-items: center;
      font-weight: 700;
      font-size: $font-size-14;
      color: $text-black-color;
      font-family: $font-lato;
      span {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        height: 30px;
        width: 30px;
        font-family: $font-lato;
      }
      svg {
        height: 16px;
        width: 16px;
      }
      .iconLow {
        color: $white-color;
        background: $malachite;
        margin-right: 10px;
      }
      .iconMedium {
        color: $white-color;
        background: #f99746;
        margin-right: 10px;
      }
      .iconHigh {
        color: $white-color;
        background: #ff5122;
        margin-right: 10px;
      }
      .iconClosed {
        color: $white-color;
        background: $spanish-gray;
        margin-right: 10px;
      }
    }
    .question {
      font-weight: 700;
      font-size: $font-size-20;
      color: $text-black-color;
      font-family: $font-lato;
    }
    .answer {
      display: flex;
      overflow-x: wrap;
      font-weight: 400;
      font-size: $font-size-16;
      color: $spanish-gray;
      font-family: $font-lato;
      overflow-wrap: break-word;
    }
    .priority-msg {
      display: flex;
      justify-content: space-between;
    }
    .priorityLow {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      height: 22px;
      background: rgba(21, 207, 95, 0.25);
      border-radius: 20px;
      font-family: $font-lato;
      font-weight: 900;
      font-size: $font-size-12;
      color: $malachite;
      padding: 5px;
    }
    .priorityMedium {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      height: 22px;
      background: #f9974640;
      border-radius: 20px;
      font-family: $font-lato;
      font-weight: 900;
      font-size: $font-size-12;
      color: #f99746;
      padding: 5px;
    }
    .priorityHigh {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      height: 22px;
      background: rgba(255, 81, 34, 0.25);
      border-radius: 20px;
      font-family: $font-lato;
      font-weight: 900;
      font-size: $font-size-12;
      color: #ff5122;
      padding: 5px;
    }
    .priorityClosed {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      height: 22px;
      background: $spanish-gray;
      border-radius: 20px;
      font-family: $font-lato;
      font-weight: 900;
      font-size: $font-size-12;
      color: $white-color;
      padding: 5px;
    }
    .msg {
      display: flex;
      align-items: center;
    }
  }
}
