@import "../../../scss/variables";

body {
  &.dark-mode {
    .overideCarousel {
      .itemBox {
        background-color: $dark-secondary-background-color;
        color: $dark-primary-text-color;
      }

      .sliderTitle {
        color: $dark-primary-text-color;
      }
    }
  }
}

.swiper {
  .swiper-button-prev,
  .swiper-button-next {
    background-color: $cerulean-color;
    width: 35px;
    height: 35px;
    background: $white-color;
    border: 2px solid $cerulean-color;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15), 0px 3px 90px rgba(0, 0, 0, 0.1);
    border-radius: 100%;
    &::after {
      font-size: $font-size-18;
      font-weight: 600;
      color: $cerulean-color;
    }
  }
  .swiper-button-next {
    right: 0;
  }
  .swiper-button-prev {
    left: 0;
  }
}

.overideCarousel {
  .carouselContainer {
    padding: 10px;
    height: calc(100% + 100px);
    margin: -50px -10px;
    padding: 50px 10px;
    width: calc(100% + 20px);
    margin-top: -20px;
  }
  .sliderTitle {
    font-size: $font-size-16;
    color: $shadow-grey;
    font-weight: 700;
  }
  .itemBox {
    text-align: center;
    background: $white-color;
    box-shadow: 0px 3px 90px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    opacity: 0.4;
    border: 6px solid transparent;
    padding: 10px 5px;
    height: 210px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &.disbled {
      cursor: auto;
    }
    img {
      width: 100%;
      height: 110px;
      object-fit: contain;
      margin-bottom: 5px;
    }
  }
  .active {
    border: 6px solid $malachite;
    opacity: 1;
    position: relative;
    &:before {
      content: "";
      width: 37px;
      height: 37px;
      background: $malachite url(../../../assets/sliderCheck.svg) center center
        no-repeat;
      position: absolute;
      border-radius: 100%;
      align-items: center;
      display: flex;
      justify-content: center;
      color: $white-color;
      z-index: 9;
      right: -20px;
      top: -30px;
    }
  }
}
