@import "../../../../src/scss//variables";

.overrideDataGrid {
  .MuiDataGrid-columnHeaders {
    font-family: $font-lato;
  }
  .MuiDataGrid-columnHeaderTitle {
    font-weight: 600;
    color: $text-black-color;
  }
}
.normalTableBox {
  height: 100%;
}
.table_style_ui {
  .MuiDataGrid-main {
    .MuiDataGrid-row:hover {
      background-color: $gainsboro;
    }
  }
  .MuiCheckbox-root {
    svg {
      color: $gainsboro;
      width: 18px;
      height: 18px;
      border-radius: 15px;
    }
    &.Mui-checked {
      svg {
        color: $cerulean-color;
      }
    }
  }
}
.normalTableBox{
   .css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar {
    visibility: hidden;
  }
}
