@import "../../scss/variables";
.overideErrorPage {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: $font-lato;
  .header{
    position: relative;
    top: 60px;
    font-size: $font-size-24;
    font-weight: 700;
  }
  .imgDiv{
    display: flex;
    flex-direction: column;
    align-items: center;
      img{
          height: 350px;
          width: 500;
        }
  }
  .factsGrid {
    border: 3px solid #1b436e;
    border-radius: 15px;
    padding: 10px;
    // text-align: center;
    h1 {
      margin-top: 20px;
      width: 100%;
      text-align: center;
      font-size: $font-size-20;
      font-weight: 700;
      color: #1b436e;
    }
    h2 {
      margin-top: 20px;
      text-align: center;
      font-size: $font-size-12;
      font-weight: 500;
      color: $text-black-color;
    }
  }
  .iconGrid {
    position: relative;
    top: 25px;
    text-align: center;
    padding: 5px;
    color: #1b436e;
    border: 3px solid #1b436e;
    border-radius: 50%;
    background: $smoke-color;
  }
}
