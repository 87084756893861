@import "../../../../scss/variables";

.overviewInc {
  h1 {
    color: $dark-secondary-background-color;
    font-size: $font-size-22;
    font-weight: 700;
    font-family: $font-lato;
  }
  .pagination-controls {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
  }
  .totalhour {
    display: flex;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;
  }
  .trir p {
    font-size: 11px;
  }
  .trir h1 {
    color: $dark-secondary-background-color;
    font-size: $font-size-24;
    font-weight: 700;
    font-family: $font-lato;
  }

  .commonBoxShodow {
    padding: 15px;
    border-radius: 15px;
    background-color: $light-primary-background-color;
    box-shadow: 0px 4px 100px 0px rgba(0, 0, 0, 0.07);
    &.commonbox50 {
      width: 55%;
      margin-bottom: 20px;
      margin-right: 10px;
    }
    .titletop {
      font-size: $font-size-16;
      font-weight: 600;
      font-family: $font-lato;
      color: $dark-secondary-background-color;
    }
    &.vehiclesec {
      min-height: 230px;
    }
  }
  .totalincbox {
    display: flex;
    justify-content: center;
  }

  .boxes {
    display: flex;
    justify-content: space-between;
  }

  .incidentreportboxs {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 150px;
    height: 150px;

    .number {
      color: red;
    }
    .pernumber {
      font-family: "Lato";
      font-size: 42px;
      font-weight: 600;
      &.total {
        color: red;
      }
      &.per {
        color: #1c446f;
      }
      &.veh {
        color: #f21dd0;
      }
      &.stolen {
        color: #1d4cf2;
      }
      &.utility {
        color: #a1f21d;
      }
      &.nearmiss {
        color: #1df2e5;
      }
    }
    .lessoreportCardBody {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 5px;
      .number {
        font-family: "Lato";
        font-size: 42px;
        font-weight: 600;
      }
    }
  }
  .PersonalenjAnalysis {
    display: flex;
    justify-content: space-around;
    align-items: center;

    .incidentreportBodypart svg {
      height: 260px;
    }
    .incidentreportBodypart {
      .bodyInjured {
        padding-top: 0px;
      }
    }
  }
  .injType {
    font-family: "Lato";
    font-size: 48px;
    color: #1b436e;
  }
  .angtimetoclose {
    display: flex;
    flex-direction: column;
    min-height: 144px;
    justify-content: center;
    .avgtime p {
      font-size: 32px;
      color: #f21d1d;
      line-height: 1.2;
      text-align: center;
    }
  }
  .vehiclePieChart {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 2px;
  }
  .lessoreportCardBodyTotal {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .totalAccidentsMain {
    display: flex;
    justify-content: center;
    height: 155px;
    max-height: 155px;
  }

  .inj {
    font-family: "Lato";
    font-size: 2rem;
    color: #1b436e;
    line-height: 1.2;
  }

  .personalinjurysection {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .number {
      font-family: "Lato";
      font-size: 2rem;
    }
  }

  .topHeaderDrill {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    .topHeaderDrillLeft {
      .title {
        font-size: $font-size-18;
        font-family: $font-lato;
        font-weight: 700;
        color: $text-black-color;
      }
    }
    .topHeaderDrillRight {
      .downloadBox {
        background-color: $green-color;
        font-size: $font-size-14;
        font-weight: 500;
        font-family: $font-lato;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $white-color;
        padding: 10px 20px;
        border-radius: 100px;
        cursor: pointer;
        .downloadIcon {
          margin-right: 10px;
        }
      }
    }
  }

  .downloadListBox {
    padding: 10px;
    .downloadList {
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0px;
      }
      .downloads {
        .downloadListIcon {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          cursor: pointer;
          img {
            width: 16px;
            height: 16px;
          }
          span {
            font-size: $font-size-14;
            font-weight: 400;
            font-family: $font-lato;
            color: $dark-secondary-background-color;
            margin-left: 15px;
          }
        }
      }
    }
  }
  .pdf-loader {
    display: none;
  }

  .overviewInc {
    @media print {
      .pdfStyles {
        background-color: #000;

        .mb-2 {
          margin-bottom: 0px;
          background-color: #000;
        }
      }
    }
  }

  .overviewInc {
    .pdfStyles {
      .mb-2 {
        margin-bottom: 0px;
      }
      .topSection {
        min-height: 180px;
        max-height: 190px;
        .topRow {
          .topRowList {
            .heading {
              line-height: 1.6;
            }
          }
        }
      }
    }
  }
  .avgcld {
    background-color: greenyellow;
  }
}

.overviewInc {
  &.pdfStyles {
    .angtimetoclose {
      min-height: 105px;
    }
    .commonBoxShodow {
      &.commonbox50 {
        margin-bottom: 0px;
      }
    }
    .PersonalenjAnalysis {
      .incidentreportBodypart {
        svg {
          height: 170px;
        }
      }
      .number {
        font-family: "Lato";
        font-size: 1.7rem;
      }
    }
    .inj {
      font-size: 1.7rem;
    }
  }
  .trir p {
    font-size: 10px;
  }
  .trir h1 {
    color: $dark-secondary-background-color;
    font-size: $font-size-20;
    font-weight: 700;
    font-family: $font-lato;
  }
}

body {
  &.dark-mode {
    .overviewInc {
      h1 {
        color: $dark-primary-text-color;
      }
      .trir h1 {
        color: $dark-primary-text-color;
      }
      .commonBoxShodow {
        background-color: $dark-primary-background-color;
        .titletop {
          color: $dark-primary-text-color;
        }
        span {
          color: $dark-primary-text-color;
        }
      }
      .injType {
        color: $dark-secondary-color;
      }

      .inj {
        color: $dark-secondary-color;
      }

      .personalinjurysection {
        .number {
          color: $dark-secondary-color;
        }
      }
      .topHeaderDrill {
        .topHeaderDrillLeft {
          .title {
            color: $dark-primary-text-color;
          }
        }
        .topHeaderDrillRight {
          .downloadBox {
            color: $white-color;
          }
        }
      }
      .downloadListBox {
        .downloadList {
          .downloads {
            .downloadListIcon {
              span {
                color: $dark-primary-text-color;
              }
            }
          }
        }
      }
    }
  }
}
