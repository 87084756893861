@import "../../../src/scss/variables";

.overideInputField {
  position: relative;
  .formControlRoot {
    background: $white-color;
    border: 1px solid $Stroke-Fossil-Grey;
    border-radius: 15px;
    font-family: $font-lato;
    font-style: normal;
    font-weight: 400;
    font-size: $font-size-14;
    color: $manatee-color;
    margin-bottom: 0px;
  }

  .inputRoot {
    border-radius: 16px;
    margin-bottom: 0px;
    padding: 7.5px 15px;
    font-family: $font-lato;

    &.Mui-disabled {
      background-color: $fog-grey;
    }
  }
  .MuiFormHelperText-root {
    position: absolute;
    bottom: -25px;
    left: 0;
  }
  .location-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    color: #9a9fa8;
    cursor: pointer;
    font-size: 30px;
  }
}
.CloseMapstyle {
  position: absolute;
  right: -3px;
  top: -20px;
  z-index: 9;
  background-color: #fff;
  width: 30px;
  border-radius: 86px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
