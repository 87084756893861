@import "../../../scss/variables";

body {
  &.dark-mode {
    .MuiPaper-root.MuiPaper-elevation.MuiAccordion-root.supportAccordion {
      background-color: $dark-secondary-background-color;

      .MuiButtonBase-root.MuiAccordionSummary-root.MuiAccordionSummary-gutters {
        p {
          color: $dark-primary-text-color;
        }
        .MuiAccordionSummary-expandIconWrapper {
          color: $dark-primary-color;
        }
      }
      .MuiAccordionDetails-root {
        color: red;
      }
    }
    .MuiPaper-root.MuiPaper-elevation.MuiAccordion-root.accordion_body {
      .MuiCollapse-root.MuiCollapse-vertical {
        background-color: $dark-primary-background-color;
        .MuiCollapse-wrapper.MuiCollapse-vertical
          .MuiCollapse-wrapperInner.MuiCollapse-vertical {
          background-color: $dark-secondary-background-color;
        }
      }
    }
    .accordion_body {
      .childAccordion {
        .MuiAccordionSummary-gutters {
          background-color: $dark-primary-color;
        }
      }
    }
  }
}

.MuiPaper-root.MuiPaper-elevation.MuiAccordion-root.accordion_body {
  margin-bottom: 10px;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: none;
  .MuiButtonBase-root.MuiAccordionSummary-root.MuiAccordionSummary-gutters {
    color: $white-color;
    .MuiAccordionSummary-content.MuiAccordionSummary-contentGutters {
      color: $white-color;
      .accordian_header_content {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 0px 15px;
        p {
          color: $white-color;
          font-size: 1.2em;
          font-family: $font-lato;
        }
        .accordian_header_content_child {
          display: flex;
          flex-wrap: wrap;
          button {
            margin-right: 30px;
            padding: 3px 15px;
            font-size: 1em;
            background-color: transparent;
            background-color: rgba(255, 255, 255, 0.1);
            border: 1px solid;
            outline: none;
            border-radius: 10px;
            color: rgba(255, 255, 255, 0.7);
            cursor: pointer;
            font-family: $font-lato;
          }
          button:hover {
            color: rgba(255, 255, 255, 1);
          }
        }
      }
    }
    .MuiAccordionSummary-expandIconWrapper {
      color: $white-color;
    }
  }
  .MuiCollapse-root.MuiCollapse-vertical {
    background-color: $gainsboro;
    .MuiCollapse-wrapper.MuiCollapse-vertical {
      padding: 10px;
      .MuiCollapse-wrapperInner.MuiCollapse-vertical {
        background-color: $white-color;
        border-radius: 5px;
        box-shadow: 0px 0px 1px 1px rgba(112, 112, 112, 0.1);
        padding: 20px;
        .MuiAccordionDetails-root {
          padding: 0px;
          .MuiFormLabel-root.MuiFormLabel-colorPrimary {
            color: $shadow-grey;
          }
        }
      }
    }
  }
  .form_label_error {
    padding: 0px;
    margin: 0px;
    font-size: 14px;
  }
  //   .css-1pnmrwp-MuiTypography-root,p{

  //     color: #fff;
  //   }
  //   .css-yw020d-MuiAccordionSummary-expandIconWrapper,.css-yw020d-MuiAccordionSummary-expandIconWrapper{
  // color: #fff;
  //   }
  //   .css-pwcg7p-MuiCollapse-root{
  //     margin-bottom:20px ;
  //     background: #fff;
  //     color: #000;
  //   }
}

.MuiPaper-root.MuiPaper-elevation.MuiAccordion-root.supportAccordion {
  margin-bottom: 10px;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: none;
  background: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 4px;
  // p{
  //   color: $text-black-color;
  //     font-family: $font-lato;
  //     font-weight: 700;
  // }

  .MuiButtonBase-root.MuiAccordionSummary-root.MuiAccordionSummary-gutters {
    p {
      font-family: $font-lato;
      font-weight: 700;
      font-size: $font-size-22;
      color: $text-black-color;
    }
    .MuiAccordionSummary-expandIconWrapper {
      color: #1c1b1f;
    }
  }
}
.accordion_body {
  .parentAccordion {
    background-color: $text-black-color;
  }
  .childAccordion {
    .MuiAccordionSummary-gutters {
      background-color: $cerulean-color;
    }
  }
}
.quesLable {
  display: flex;
  .spaceLable {
    margin-right: 5px;
  }
}

.accordionDetail {
  display: flex;
  flex-wrap: wrap;
  text-align: left;
  font-family: lato;
  font-weight: 400;
  font-size: 16px;
  color: #999999;
}
