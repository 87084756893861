@import "../../../scss/variables";
.attendeeList {
  .container {
    width: 100%;
    padding-left: 0;
  }
  .heading {
    display: flex;
    background-color: #e4f0f5;
    text-align: center;
    font-weight: 600;
    font-size: 20px;
    font-family: "lato";
    text-align: center;
    width: 100%;
    margin-bottom: 0px;
  }
  .table-row {
    display: flex;
    width: 100%;
  }
  .col {
    &:nth-child(1) {
      width: 25%;
    }
    &:nth-child(2) {
      width: 25%;
    }
    &:nth-child(3) {
      width: 40%;
    } 
    &:nth-child(4) {
      width: 10%;
    } 
    border: 1px solid #ccc;
    padding-top: 15px;
    padding-bottom: 15px;
    text-align: left;
    padding-left: 15px;
    width: 33.33%;
    display: flex;
    align-items: center;
    font-size: 16px;
    &.attendee {
      color: #333;
      font-family: "Lato";
      font-size: 16px;
      font-weight: 400;
      line-height: normal;
      text-transform: capitalize;
      padding-top: 20px;
      padding-bottom: 20px;
      text-align: left;
      padding-left: 15px;
    }&.group {
      color: #333;
      font-family: "Lato";
      font-size: 16px;
      font-weight: 400;
      line-height: normal;
      text-transform: capitalize;
      padding-top: 20px;
      padding-bottom: 20px;
      text-align: left;
      padding-left: 15px;
    }&.proof {
      color: #333;
      font-family: "Lato";
      font-size: 16px;
      font-weight: 400;
      line-height: normal;
      text-transform: capitalize;
      padding-top: 20px;
      padding-bottom: 20px;
      text-align: left;
      padding-left: 15px;
    }
    &.cancel {
      color: #333;
      font-family: "Lato";
      font-size: 16px;
      font-weight: 400;
      line-height: normal;
      text-transform: capitalize;
      padding-top: 20px;
      padding-bottom: 20px;
      text-align: left;
      padding-left: 15px;
      cursor: pointer;
      justify-content: flex-end;
      padding-right: 20px;
    }
  }

  .attendeesignatureImg {
    .attendeesignContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      .attendeeSignImg {
        img {
          max-width: 100px;
          max-height: 100px;
          height: auto;
          position: relative;
        }

        .attendeeborderSignatureImg {
          border-radius: 0%;
          border: 4px solid #ffffff;
          width: 100%;
          height: 100%;
        }
      }
      .signatureCnacel {
        cursor: pointer;
      }
    }
  }
}
