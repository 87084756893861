@import "../../../scss/variables";

body {
  &.dark-mode {
    .overideSection {
      .title {
        color: $dark-primary-text-color;
      }
    }
  }
}

.overideSection {
  .sectionBox {
    border: 1px solid $border-grey;
    padding: 20px;
    border-radius: 15px;
    margin-bottom: 20px;
    max-width: 720px;
    width: 100%;
    margin-top: 10px;
  }
  .sectionCancel {
    max-width: 720px;
    width: 100%;
  }
  .title {
    text-align: left;
    color: $text-black-color;
    font-size: $font-size-18;
    font-weight: 700;
    font-family: $font-lato;
  }
}
