@import "../../../src/scss/variables";

body {
  &.dark-mode {
    .myOpenItem {
      .myOpenItemTitle {
        color: $dark-primary-text-color;
      }
    }
    .openItemoverride {
      .insi-stitle p {
        color: $dark-primary-text-color;
      }
    }
  }
}

.myOpenItem {
  margin-left: 20px;
  .myOpenItemTitle {
    font-family: "Lato";
    font-weight: 700;
    font-size: 24px;
    color: #333333;
  }
}
