@import "../../../scss/variables";

.osha-pdf-container {
  max-width: 100%;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 20px;
  background: #fff;
}

.osha-pdf-header {
  display: flex;
  justify-content: space-between;
  padding-bottom: 5px;
  margin-bottom: 4px;
  border-bottom: 8px solid #d9d9d9;
  align-items: center;
}

.left-section h1 {
  font-size: 20px;
  font-weight: 700;
  font-family: "lato";
  span {
    font-family: "Lato";
    font-size: 12px;
    font-weight: 700;
    color: #333333;
  }
}

.left-section p {
  font-family: "Lato";
  font-size: 22px;
  font-weight: 700;
  color: #333333;
  margin-top: 12px;
}

.right-section h1 {
  font-family: "Lato";
  font-size: 20px;
  font-weight: 800;
  line-height: 24px;
  color: #333333;
  text-align: end;
}

.right-section p {
  font-family: "Lato";
  font-size: 12px;
  font-weight: 800;
  color: #333333;
  text-align: end;
}

.oshaIconContainer {
  display: flex;
  justify-content: end;
  align-items: baseline;
  gap: 10px;
}

.right-section .year {
  font-family: "Lato";
  font-size: 20px;
  font-weight: 400;
}
.approvedDateStyle {
  font-family: "Lato";
  font-size: 10px;
  font-weight: 400;
  color: #333333;
  text-align: end;
}

.section {
  margin-bottom: 50px;
  .totalNumber {
    font-family: "Lato";
    font-size: 14px;
    font-weight: 400;
    color: #000000;
    width: 14%;
    margin-bottom: 30px;
  }
}

.section-title {
  background-color: #1c1b1f;
  color: #fff;
  padding: 8px;
  font-weight: bold;
  margin-bottom: 15px;
}

.note {
  font-family: "Lato";
  font-size: 12px;
  font-weight: 500;
  line-height: 14.4px;
  color: #000000;
  line-height: 14.4px;
  p {
    padding: 15px 0;
  }
}

.grid {
  display: grid;
  gap: 70px;
}

.grid-2 {
  grid-template-columns: repeat(4, 1fr);
}

.grid-3 {
  grid-template-columns: repeat(2, 1fr);
}

.grid-4 {
  grid-template-columns: repeat(4, 1fr);
}

.field {
  display: flex;
  flex-direction: column;
}

.label {
  font-size: 12px;
  margin-bottom: 15px;
}

.line {
  position: relative;
  width: 100px;
  border-bottom: 1px solid black;
  text-align: center;
  margin-top: 10px;
}

.line-value1 {
  position: relative;
  display: inline-block;
  margin-top: 25px;
  font-family: "Lato";
  font-size: 12px;
  font-weight: 400;
  color: #000000;
}

.line-label1 {
  position: absolute;
  top: 52px;
  left: 50%;
  transform: translateX(-50%);
  font-family: "Lato";
  font-size: 12px;
  font-weight: 400;
  color: #000000;
}

.line-value {
  position: relative;
  display: inline-block;
  margin-top: 8px;
  font-family: "Lato";
  font-size: 12px;
  font-weight: 400;
  color: #000000;
}

.line-label {
  position: absolute;
  top: 35px;
  left: 50%;
  transform: translateX(-50%);
  font-family: "Lato";
  font-size: 12px;
  font-weight: 400;
  color: #000000;
}

.signature-section {
  margin-top: 20px;
}

.signature-line {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.signature-line .label {
  flex: 1;
}

.signature-line .line {
  flex: 3;
  border-bottom: 1px solid #000;
  height: 20px;
  margin-left: 10px;
}

.injury-section {
  margin-bottom: 20px;
}

.injury-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
}

.injury-field {
  display: flex;
  align-items: flex-end;
}

.injury-label {
  width: 45%;
  font-family: "Lato";
  font-size: 14px;
  font-weight: 400;
  color: #000000;
}

.injury-line {
  flex-grow: 1;
  border-bottom: 1px solid #000000;
  margin-left: 10px;
  margin-right: 50px;
}

.injury-line-value {
  font-family: "Lato";
  font-size: 12px;
  font-weight: 400;
  color: #000000;
  margin-left: 54px;
}

.summary-section {
  margin-top: 20px;
}

.summary-title {
  font-family: "Lato";
  font-size: 16px;
  font-weight: 700;
  line-height: 19.2px;
  margin-bottom: 15px;
  color: #333333;
}

.summary-text {
  font-family: "Lato";
  font-size: 12px;
  font-weight: 400;
  color: #000000;
  line-height: 14.4px;
}

.osha-form-container {
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #000;
  box-shadow: 8px 8px 0px 0px rgba(0, 0, 0, 0.8);
  font-family: Arial, sans-serif;
  color: #333;
  margin-top: 35px;
  h2 {
    font-family: "Lato";
    font-size: 16px;
    font-weight: 700;
    color: #333333;
    margin-bottom: 30px;
  }
  h3 {
    font-family: "Lato";
    font-size: 16px;
    font-weight: 700;
    color: #333333;
    margin-bottom: 7px;
  }
  .osha-info-text {
    font-family: "Lato";
    font-size: 12px;
    font-weight: 400;
    color: #000000;
    margin-bottom: 20px;
  }
  .osha-info-text1 {
    font-family: "Lato";
    font-size: 16px;
    font-weight: 400;
    color: #333333;
    margin-bottom: 30px;
  }
  .signHere {
    font-family: "Lato";
    font-size: 16px;
    font-weight: 700;
    color: #333333;
    margin-bottom: 7px;
    margin-top: 25px;
  }
}

.osha-form-group {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  font-family: "Lato";
  font-size: 16px;
  font-weight: 400;
  color: #333333;
}

.osha-full-line-group {
  flex-direction: column;
  align-items: flex-start;
  margin-top: 40px;
}

.osha-full-line-group label {
  margin-bottom: 4px;
}

.osha-line-full {
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  color: #000000;
}

label {
  margin-right: 10px;
  white-space: nowrap;
  font-size: 0.9em;
}

.osha-line {
  flex-grow: 1;
  border-bottom: 1px solid #000000;
  margin: 10px 20px;
  font-family: "Lato";
  font-size: 14px;
  font-weight: 500;
  color: #000000;
}

.osha-line-full {
  flex-grow: 10;
  border-bottom: 1px solid #000000;
  width: 97%;
  margin-top: 25px;
  margin-bottom: 15px;
}

.osha-line-short {
  width: 40px;
}

.osha-line-group {
  display: flex;
  gap: 8px;
}

.osha-or-text {
  margin: 10px 0;
  font-family: "Lato";
  font-size: 16px;
  font-weight: 400;
}

.osha-certify-text {
  font-family: "Lato";
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  margin-bottom: 50px;
}

.osha-signature-group {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.osha-signature-item {
  flex: 1;
  display: flex;
  align-items: center;
}

.osha-signature-item label {
  margin-right: 8px;
  font-size: 0.9em;
}

.classification-group {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.line-container {
  display: flex;
  gap: 10px;
  margin-top: 15px;
}

.line {
  display: inline-block;
  border-bottom: 1px solid #000000;
}

.segment {
  width: 50px;
  font-family: "Lato";
  font-size: 14px;
  font-weight: 500;
  color: #000000;
}

.or-text {
  margin: 15px 0;
  font-family: "Lato";
  font-size: 16px;
  font-weight: 400;
  color: #333333;
}

.signature-section {
  display: flex;
  flex-direction: column;
  gap: 35px;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
}

.signature-row {
  display: flex;
  justify-content: space-between;
}

.signature-field {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 45%;
}

.signature-line {
  display: block;
  width: 100%;
  border-bottom: 1px solid #000000;
  margin-bottom: 4px;
}

.signature-field label {
  font-family: "Lato";
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  margin-top: 4px;
}
