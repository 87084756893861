@import "../../../scss/variables";

.oshaOtherDetailContainer {
  .oshaOtherList {
    margin-bottom: 20px;
    .oshaOtherListTitle {
      background-color: $light-primary-color;
      padding: 10px 20px;
      color: $light-primary-background-color;
      font-size: $font-size-18;
      font-weight: 700;
      font-family: $font-lato;
      border-radius: 5px;
    }
    .oshaOtherListBottom {
      background-color: $fog-grey;
      padding: 15px;
      border-radius: 5px;
      .oshaOtherListBottomBox {
        background-color: $light-primary-background-color;
        border: 1px solid $gainsboro;
        padding: 10px;
        border-radius: 5px;
        .oshaOtherListBottomPara {
          margin-bottom: 10px;
          color: $light-primary-text-color;
          font-size: $font-size-16;
          font-weight: 700;
          font-family: $font-lato;
          &.highlight {
            color: $light-primary-color;
            font-size: $font-size-20;
            span {
              color: $light-primary-color;
              font-weight: 700;
            }
          }
          span {
            color: $light-primary-text-color;
            font-size: $font-size-16;
            font-weight: 400;
            font-family: $font-lato;
            padding-left: 5px;
          }
        }
      }
    }
  }
}
.noDataMessage {
  text-align: center;
  width: 100%;
  margin-top: 50px;
  .noDataMessageTitle {
    font-size: $font-size-20;
    color: $light-primary-text-color;
    max-width: 500px;
    margin: auto;
    margin-bottom: 20px;
    font-weight: 500;
  }
  .backbtn {
    color: $light-primary-color;
    font-size: $font-size-20;
    font-weight: 600;
    cursor: pointer;
  }
}

body {
  &.dark-mode {
    .oshaOtherDetailContainer {
      .oshaOtherList {
        .oshaOtherListBottom {
          .oshaOtherListBottomBox {
            .oshaOtherListBottomPara {
              color: $dark-primary-text-color;
              span {
                color: $dark-primary-color;
              }
            }
          }
        }
      }
    }
    .noDataMessage {
      .noDataMessageTitle {
        color: $dark-primary-text-color;
      }
      .backbtn {
        color: $dark-primary-color;
      }
    }
  }
}
