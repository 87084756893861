@import "../../../scss/variables";

body {
  &.dark-mode {
    .overideInputField {
      .formControlRoot {
        background-color: $dark-form-all-input-color;
        .Mui-disabled {
          background-color: $dark-tertiary-background-color;
        }
      }

      .inputRoot {
        background-color: $dark-form-all-input-color;
        .Mui-disabled {
          background-color: $dark-tertiary-background-color;
        }
      }
    }
  }
}

.overideInputField {
  .icon {
    color: #999999;
  }
  .formControlRoot {
    background: $white-color;
    border: 1px solid $border-grey;
    border-radius: 18px;
    font-family: $font-lato;
    font-style: normal;
    font-weight: 400;
    font-size: $font-size-14;
    color: $manatee-color;
    margin-bottom: 0px;
  }
  .inputRoot {
    border-radius: 16px;
    margin-bottom: 0px;
    padding: 7.5px 15px;
    &.Mui-disabled {
      background-color: $fog-grey;
    }
  }
  .MuiFormHelperText-root {
    position: absolute;
    bottom: -25px;
    left: 0;
  }
}
